import { useMemo } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useGetCompany } from 'shared/utils/selectors';
import { IconAt, IconCalendarClock, IconFileAlert, IconFolders, IconListCheck, IconTimeline, IconWallet, } from '@tabler/icons-react';
import { useBuildKanbanMenu } from './use-build-kanban-menu';
export function useBuildFollowupMenu() {
    var t = useTranslation().t;
    var company = useGetCompany();
    var kanbanMenu = useBuildKanbanMenu();
    return useMemo(function () {
        var _a;
        var items = [
            kanbanMenu,
            {
                path: '/invoices/to-handle',
                label: t(i18nKeys.INVOICE_TO_PROCESS),
                Icon: IconFileAlert,
            },
            {
                path: '/invoices/actions',
                label: t(i18nKeys.NAV.IMINENT_REMINDERS),
                Icon: IconCalendarClock,
                unreadCount: (_a = company === null || company === void 0 ? void 0 : company.reminders_to_send_count) === null || _a === void 0 ? void 0 : _a.next_five_days,
            },
            {
                path: '/thirdparty_cases',
                label: t(i18nKeys.NAV.THIRDPARTY),
                Icon: IconFolders,
            },
            {
                path: '/payment-plans/listing',
                pathSuffix: '?status=in_progress',
                twat: 'test',
                label: t(i18nKeys.NAV.PAYMENT_PLAN),
                Icon: IconWallet,
            },
            {
                path: '/tasks',
                label: t(i18nKeys.NAV.TASKS),
                Icon: IconListCheck,
                isHidden: !(company === null || company === void 0 ? void 0 : company.package.can_use_tasks),
            },
            {
                path: '/mails',
                label: t(i18nKeys.MAILS.NAV),
                Icon: IconAt,
            },
        ];
        return {
            parent: null,
            label: t(i18nKeys.NAV.MY_FOLLOW_UP),
            Icon: IconTimeline,
            items: items,
        };
    }, [company, kanbanMenu, t]);
}
