import { __assign, __values } from "tslib";
import { removeAttributes } from 'shared/utils/view';
import { baseReducerInfinitePage, baseReducerListPage, flattenItem, handlePageRequest, handlePageResponse, handleResponse, } from 'store/reducers';
import { transactionConstants as events } from 'store/transaction/transaction.actions';
var initialState = {
    paymentHistory: baseReducerListPage,
    reconciliation: {
        invoices: baseReducerInfinitePage,
        payments: baseReducerInfinitePage,
        selectedInvoices: [],
        selectedPayments: [],
        loaded: false,
        loading: false,
    },
};
var handleMetadata = function (type, reconciliation, state, deleteCount) {
    var newTotalObject = state.reconciliation[type].data.length - deleteCount;
    return {
        total: reconciliation.payments.metadata.total,
        pagination: {
            current_page: Math.ceil((state.reconciliation.payments.data.length - deleteCount) / 20),
            last_page: Math.ceil(newTotalObject / 20),
            total_objects: newTotalObject,
            page_limit: 20,
        },
    };
};
var handleSelectReconciliation = function (state, action) {
    var _a;
    var name = action.payload.type === 'invoice' ? 'invoices' : 'payments';
    var selectedName = action.payload.type === 'invoice' ? 'selectedInvoices' : 'selectedPayments';
    var selectedList = state.reconciliation[selectedName] || [];
    var list = state.reconciliation && state.reconciliation[name].data.slice();
    if (!list)
        return state;
    var listIndex = selectedList.findIndex(function (item) { return item.id === action.payload.id; });
    if (listIndex !== -1) {
        list.find(function (item) { return item.id === action.payload.id; }).checked = false;
        selectedList.splice(listIndex, 1);
    }
    else {
        list.find(function (item) { return item.id === action.payload.id; }).checked = true;
        selectedList.push(state.reconciliation[name].data.find(function (item) { return item.id === action.payload.id; }));
    }
    return __assign(__assign({}, state), { reconciliation: __assign(__assign({}, state.reconciliation), (_a = {}, _a[name] = __assign(__assign({}, state.reconciliation[name]), { data: list }), _a[selectedName] = selectedList, _a)) });
};
var handleManualResponseItem = function (type, state, payload) {
    var items = state.reconciliation[type].data.slice();
    var deletedItemsCount = 0;
    var elDeleted = 0;
    var itemsLength = items.length;
    for (var i = 0; i < itemsLength; i++) {
        if (items[i - elDeleted].inactive) {
            items.splice(i - elDeleted, 1);
            elDeleted++;
        }
    }
    payload[type].forEach(function (payloadItem) {
        if (type === 'invoices'
            ? +payloadItem.attributes.remaining_late_fees +
                +payloadItem.attributes.remaining_balance ===
                0
            : +payloadItem.attributes.remaining_balance === 0) {
            var removedItemIndex = items.findIndex(function (item) { return payloadItem.id === item.id; });
            items[removedItemIndex].inactive = true;
            deletedItemsCount++;
        }
        else {
            var index = items.findIndex(function (unpaid_invoice) { return payloadItem.id === unpaid_invoice.id; });
            items[index] = removeAttributes(payloadItem);
        }
    });
    return {
        items: items,
        deletedItemsCount: deletedItemsCount,
    };
};
// In case of success
var handleManualResponse = function (state, _a) {
    var payload = _a.payload, status = _a.status;
    if (status !== 'fulfilled')
        return state;
    var payments = handleManualResponseItem('payments', state, payload);
    var invoices = handleManualResponseItem('invoices', state, payload);
    var res = __assign(__assign({}, state), { reconciliation: __assign(__assign({}, state.reconciliation), { payments: __assign(__assign({}, state.reconciliation.payments), { data: !payments.items.some(function (payment) { return !payment.inactive; }) ? [] : payments.items, metadata: handleMetadata('payments', state.reconciliation, state, payments.deletedItemsCount) }), invoices: __assign(__assign({}, state.reconciliation.invoices), { data: !invoices.items.some(function (invoice) { return !invoice.inactive; }) ? [] : invoices.items, metadata: handleMetadata('invoices', state.reconciliation, state, invoices.deletedItemsCount) }), selectedInvoices: [], selectedPayments: [] }) });
    return res;
};
var handleIgnorePayment = function (state, _a) {
    var requestData = _a.requestData;
    var reconciliation = state.reconciliation;
    var payments = reconciliation.payments.data.slice();
    var ids = requestData.payment_ids;
    for (var index = payments.length - 1; index >= 0; index--) {
        if (payments[index].inactive) {
            payments.splice(index, 1);
        }
        else if (ids.indexOf(payments[index].id) !== -1) {
            payments[index] = __assign(__assign({}, payments[index]), { inactive: true });
        }
    }
    if (!payments.some(function (payment) { return !payment.inactive; })) {
        payments = [];
    }
    return __assign(__assign({}, state), { reconciliation: __assign(__assign({}, reconciliation), { selectedPayments: [], payments: __assign(__assign({}, state.reconciliation.payments), { data: payments, metadata: handleMetadata('payments', reconciliation, state, ids.length) }) }) });
};
var handleUnignorePayment = function (state, _a) {
    var e_1, _b;
    var requestData = _a.requestData;
    var paymentHistory = state.paymentHistory;
    var payments = paymentHistory.pages;
    var ids = requestData.payment_ids;
    var _loop_1 = function (paymentId) {
        payments.find(function (payment) { return payment.id === paymentId; }).ignored = false;
    };
    try {
        for (var ids_1 = __values(ids), ids_1_1 = ids_1.next(); !ids_1_1.done; ids_1_1 = ids_1.next()) {
            var paymentId = ids_1_1.value;
            _loop_1(paymentId);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (ids_1_1 && !ids_1_1.done && (_b = ids_1.return)) _b.call(ids_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return __assign(__assign({}, state), { paymentHistory: __assign({}, paymentHistory) });
};
/*
================================
HANDLE SELECTED ITEMS
================================
*/
var handleSelectedItems = function (state, action, name) {
    var e_2, _a;
    var selectedName = name === 'invoices' ? 'selectedInvoices' : 'selectedPayments';
    var newReconciliationState = handlePageResponse(state.reconciliation, action, name, removeAttributes, { infiniteScroll: true });
    var _loop_2 = function (selectedItem) {
        var itemIndex = newReconciliationState[name].data.findIndex(function (item) { return selectedItem.id === item.id; });
        if (itemIndex === -1) {
            newReconciliationState[name].data.push(selectedItem);
        }
        else {
            newReconciliationState[name].data[itemIndex].checked = true;
        }
    };
    try {
        for (var _b = __values(newReconciliationState[selectedName]), _c = _b.next(); !_c.done; _c = _b.next()) {
            var selectedItem = _c.value;
            _loop_2(selectedItem);
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return newReconciliationState;
};
var resetReconciliationSelection = function (state) {
    var _a, _b;
    var reconciliation = __assign({}, state.reconciliation);
    reconciliation.selectedInvoices = [];
    reconciliation.selectedPayments = [];
    ((_a = reconciliation.invoices.data) !== null && _a !== void 0 ? _a : []).forEach(function (invoice) {
        invoice.checked = false;
    });
    ((_b = reconciliation.payments.data) !== null && _b !== void 0 ? _b : []).forEach(function (payment) {
        payment.checked = false;
    });
    return __assign(__assign({}, state), { reconciliation: reconciliation });
};
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        // Payment history
        case events.paymentHistory.result:
            return handleResponse(state, action, 'paymentHistory', flattenItem);
        case events.paymentHistoryPage.request:
            return handlePageRequest(state, action, 'paymentHistory');
        case events.paymentHistoryPage.result:
            return handlePageResponse(state, action, 'paymentHistory', flattenItem);
        // reconciliation
        case events.getReconciliation.request:
            return __assign(__assign({}, state), { reconciliation: handlePageRequest(state.reconciliation, action, 'invoices') });
        case events.getReconciliation.result:
            return __assign(__assign({}, state), { reconciliation: handleSelectedItems(state, action, 'invoices') });
        case events.getReconciliationPayments.request:
            return __assign(__assign({}, state), { reconciliation: handlePageRequest(state.reconciliation, action, 'payments') });
        case events.getReconciliationPayments.result:
            return __assign(__assign({}, state), { reconciliation: handleSelectedItems(state, action, 'payments') });
        case events.selectReconciliation:
            return handleSelectReconciliation(state, action);
        case events.manualReconciliation.request:
            var res = handleManualResponse(state, action);
            if (res === false)
                return res;
            return {
                paymentHistory: res.paymentHistory,
                reconciliation: __assign(__assign({}, res.reconciliation), { loading: true }),
            };
        case events.manualReconciliation.result:
            var res2 = handleManualResponse(state, action);
            if (res2 === false)
                return res2;
            return {
                paymentHistory: res2.paymentHistory,
                reconciliation: __assign(__assign({}, res2.reconciliation), { loading: false }),
            };
        case events.ignorePayments.result:
            return handleIgnorePayment(state, action);
        case events.unignorePayments.result:
            return handleUnignorePayment(state, action);
        case events.resetReconciliationSelection.request:
            return resetReconciliationSelection(state);
        default:
            return state;
    }
};
export default reducer;
