import { __assign, __rest } from "tslib";
import React from 'react';
import { Link, RichTextEditor } from '@mantine/tiptap';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
export var RichTextArea = function (_a) {
    var content = _a.content, onChange = _a.onChange, props = __rest(_a, ["content", "onChange"]);
    var editorInstance = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Highlight,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ],
        content: content,
        onUpdate: function (_a) {
            var editor = _a.editor;
            onChange(editor.getHTML());
        },
    });
    return (React.createElement(RichTextEditor, __assign({ editor: editorInstance }, props),
        React.createElement(RichTextEditor.Toolbar, { sticky: true, stickyOffset: 60 },
            React.createElement(RichTextEditor.ControlsGroup, null,
                React.createElement(RichTextEditor.Bold, null),
                React.createElement(RichTextEditor.Italic, null),
                React.createElement(RichTextEditor.Underline, null),
                React.createElement(RichTextEditor.Strikethrough, null),
                React.createElement(RichTextEditor.ClearFormatting, null),
                React.createElement(RichTextEditor.Highlight, null)),
            React.createElement(RichTextEditor.ControlsGroup, null,
                React.createElement(RichTextEditor.H1, null),
                React.createElement(RichTextEditor.H2, null),
                React.createElement(RichTextEditor.H3, null),
                React.createElement(RichTextEditor.H4, null)),
            React.createElement(RichTextEditor.ControlsGroup, null,
                React.createElement(RichTextEditor.Blockquote, null),
                React.createElement(RichTextEditor.Hr, null),
                React.createElement(RichTextEditor.BulletList, null),
                React.createElement(RichTextEditor.OrderedList, null)),
            React.createElement(RichTextEditor.ControlsGroup, null,
                React.createElement(RichTextEditor.Link, null),
                React.createElement(RichTextEditor.Unlink, null)),
            React.createElement(RichTextEditor.ControlsGroup, null,
                React.createElement(RichTextEditor.AlignLeft, null),
                React.createElement(RichTextEditor.AlignCenter, null),
                React.createElement(RichTextEditor.AlignJustify, null),
                React.createElement(RichTextEditor.AlignRight, null)),
            React.createElement(RichTextEditor.ControlsGroup, null,
                React.createElement(RichTextEditor.Undo, null),
                React.createElement(RichTextEditor.Redo, null))),
        React.createElement(RichTextEditor.Content, null)));
};
