import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import { isEmpty } from 'lodash-es';
import { isDefined, isNonNullish, omit, pick, pickBy } from 'remeda';
import { addResourceNameToQueryResult, useAxiosInstance } from 'shared/hooks/utils';
import { numberOrNull } from 'shared/utils';
import { CurrencySchema } from 'types/currency';
import { z } from 'zod';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
export var KANBAN_INVOICE_COLUMNS = [
    'late',
    'first_reminder',
    'last_reminder',
    'formal_notice',
    'third_party_case',
    'disputed',
];
// #region Schemas
var KanbanInvoiceSchema = z.object({
    id: z.number(),
    reference: z.string(),
    remainingBalance: z.coerce.number(),
    daysLate: z.number(),
    debtor: z.object({
        id: z.number(),
        fullName: z.string(),
    }),
    thirdPartyCase: z
        .object({
        type: z.enum(['callcenter', 'lawyer', 'bailiff', 'debt_collector']),
        date: z.string(),
        description: z.string(),
    })
        .optional(),
});
var InvoiceColumnSchema = z.object({
    invoices: z.array(KanbanInvoiceSchema),
    total: z.object({
        totalInEur: z.coerce.number(),
        detail: z.array(z.object({
            amount: z.coerce.number(),
            currency: CurrencySchema,
        })),
    }),
});
export var LoadInvoiceColumnVariablesSchema = z.object({
    accountManagerId: z.coerce.number().optional().nullable(), // See (1)
    column: z.enum(__spreadArray([], __read(KANBAN_INVOICE_COLUMNS), false)),
    debtorName: z.string().optional(),
    startDate: z
        .date()
        .transform(function (date) { return date === null || date === void 0 ? void 0 : date.toISOString(); })
        .nullable() // See (1)
        .optional(),
    endDate: z
        .date()
        .transform(function (date) { return date === null || date === void 0 ? void 0 : date.toISOString(); })
        .nullable() // See (1)
        .optional(),
    reference: z.string().optional(),
    minDaysLate: z.union([z.number(), z.string()]).optional(),
    maxDaysLate: z.union([z.number(), z.string()]).optional(),
    remainingBalance: z.object({
        amount: z.union([z.number(), z.string()]).optional(),
        operator: z.enum(['>=', '<=']),
    }),
});
export var LoadInvoiceColumnVariablesTransformerSchema = LoadInvoiceColumnVariablesSchema.transform(function (data) { return (__assign(__assign({}, omit(data, ['remainingBalance'])), (isDefined(data.remainingBalance.amount)
    ? {
        remainingBalance: numberOrNull(data.remainingBalance.amount),
        remainingBalanceOperator: data.remainingBalance.operator,
    }
    : {}))); });
// #endregion
// #region Hook
export function loadInvoiceColumnQueryFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [4 /*yield*/, instance.get('kanban/invoices', { params: queryKey[1] })];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, InvoiceColumnSchema.parse(data)];
            }
        });
    });
}
export var useLoadInvoiceColumn = function (_variables) {
    var _a;
    var axiosInstance = useAxiosInstance();
    var variablesCompacted = pickBy(_variables, function (val) { return isNonNullish(val) && !isEmpty(val); });
    var variables;
    try {
        variables = LoadInvoiceColumnVariablesTransformerSchema.parse(variablesCompacted);
    }
    catch (error) {
        variables = pick(_variables, ['column']);
        console.error((_a = error === null || error === void 0 ? void 0 : error.toString()) !== null && _a !== void 0 ? _a : error);
        captureException(error);
    }
    var queryResult = useQuery({
        queryKey: ['invoice-kanban', variables],
        queryFn: function (context) { return loadInvoiceColumnQueryFn(axiosInstance, context); },
        placeholderData: function (previousData) { return previousData; },
    });
    return addResourceNameToQueryResult('invoiceColumn', queryResult);
};
// #endregion
// MARK: Comments
// (1): Nullability is required t oproperly reset the date pickers when the filters are cleared
// If only marked optional, the component becomes uncontrolled and the old date stays visible.
