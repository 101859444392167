import { __awaiter, __generator } from "tslib";
import { string, z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { ExternalMailAttributesSchema } from './use-load-external-mail';
import { addResourceNameToQueryResult, useLegacyAxiosInstance } from './utils';
var ExternalMailsSearchParamsSchema = z.object({
    page: z.coerce.number().optional(),
    page_limit: z.coerce.number().optional(),
    sort_by: string().optional(),
    sort_order: string().optional(),
    date_before: string().optional(),
    date_after: string().optional(),
    from_mail: string().optional(),
    to_mail: string().optional(),
    subject: string().optional(),
    status: string().optional(),
    body: string().optional(),
    debtor_id: string().optional(),
    external_mailbox_id: string().optional(),
    account_manager_id: string().optional(),
});
var ExternalMailMetadata = z.object({
    pagination: z.object({
        current_page: z.number(),
        last_page: z.number(),
        page_limit: z.number(),
        total_objects: z.number(),
    }),
    total: z.number(),
});
var LightExternalMailSchema = z.object({
    id: z.string(),
    attributes: ExternalMailAttributesSchema.omit({
        body: true,
        reply_mails: true,
        attachments: true,
    }),
});
var ExternalMailsSchema = z.object({
    data: z.array(LightExternalMailSchema),
    metadata: ExternalMailMetadata,
});
export function loadExternalMailsFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [4 /*yield*/, instance.get('/external_mails', { params: queryKey[1] })];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, ExternalMailsSchema.parse(data)];
            }
        });
    });
}
export function useLoadExternalMails(params) {
    var axiosInstance = useLegacyAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['externalMails', params],
        queryFn: function (context) { return loadExternalMailsFn(axiosInstance, context); },
    });
    return addResourceNameToQueryResult('externalMails', queryResult);
}
