import { __assign } from "tslib";
import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales/';
import { FormProvider, useForm } from 'react-hook-form';
import InvoiceFooter from 'shared/components/InvoiceFooter';
import { getCgv, useGetCompany, useGetConstants } from 'shared/utils/selectors';
import { BillingLogField, CommentField, CompanyDetails, DebtorDetails, DebtorField, DueDateField, IssueDateField, TotalsDetails, } from './components';
import { getCurrency, getDefaultDueDate, getDefaultIssueDate, isNoTva } from './InvoiceForm.utils';
import { InvoiceFormContainer } from './InvoiceFormContainer';
import InvoiceLines from './InvoiceLines';
import styleIdentifiers from './InvoiceForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var InvoiceForm = function (props) {
    var _a;
    var t = useTranslation().t;
    var constants = useGetConstants();
    var company = (_a = useGetCompany()) !== null && _a !== void 0 ? _a : undefined;
    var initialValues = props.initialValues, _b = props.isCreditNote, isCreditNote = _b === void 0 ? false : _b, creditNote = props.creditNote, invoice = props.invoice;
    var defaultValues = __assign({ issue_date: getDefaultIssueDate().toISOString(), due_date: getDefaultDueDate(company).toISOString() }, initialValues);
    var form = useForm({
        defaultValues: defaultValues,
    });
    var setValue = form.setValue, watch = form.watch, reset = form.reset;
    var debtor = watch('debtor_attributes');
    var issueDate = watch('issue_date');
    var noTva = isNoTva(debtor);
    var currency = getCurrency(company, debtor, invoice || creditNote);
    useLayoutEffect(function () {
        reset(__assign({ issue_date: getDefaultIssueDate().toISOString(), due_date: getDefaultDueDate(company).toISOString() }, initialValues));
    }, [initialValues]);
    var setDueDate = useCallback(function (date, paymentDelay) {
        var _a;
        var delay = paymentDelay || ((_a = getCgv(company, debtor)) === null || _a === void 0 ? void 0 : _a.minimum_payment_terms_delay) || 0;
        if (delay) {
            setValue('due_date', dayjs(date).add(delay, 'day').toISOString());
        }
    }, [company, debtor, setValue]);
    useEffect(function () {
        if (issueDate) {
            if (initialValues.issue_date !== issueDate)
                setDueDate(issueDate);
        }
    }, [issueDate, debtor, setDueDate]);
    var sharedProps = {
        constants: constants,
        company: company,
        isCreditNote: isCreditNote,
        currency: currency,
        noTva: noTva,
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement(InvoiceFormContainer, __assign({}, props, sharedProps),
            React.createElement("header", null,
                React.createElement("div", { className: styles('head') },
                    company && (React.createElement("img", { className: styles('logo'), src: company.invoice_customization.logo_url, alt: "" })),
                    React.createElement("div", { className: styles('title') })),
                React.createElement("div", { className: styles('infos') },
                    company && React.createElement(CompanyDetails, __assign({}, sharedProps)),
                    React.createElement("div", { className: styles('main-detail', 'right') },
                        React.createElement("div", { className: styles('input-button') },
                            React.createElement(DebtorField, __assign({}, sharedProps))),
                        debtor && React.createElement(DebtorDetails, __assign({}, sharedProps, { debtor: debtor }))))),
            React.createElement("section", { className: styles('invoice-infos') },
                React.createElement("div", null,
                    React.createElement("div", { className: styles('invoice-number') },
                        React.createElement("div", { className: styles('billing_book') }, t(i18nKeys.INVOICING.CREATE.BILLING_BOOK)),
                        company && React.createElement(BillingLogField, __assign({}, sharedProps)))),
                React.createElement("div", { className: styles('right') },
                    React.createElement("div", { className: styles('info') },
                        React.createElement("span", { className: styles('label') },
                            t(i18nKeys.DATE),
                            ":"),
                        React.createElement(IssueDateField, __assign({}, sharedProps))),
                    React.createElement("div", { className: styles('info') },
                        React.createElement("span", { className: styles('label') },
                            t(i18nKeys.DUE_DATE),
                            ":"),
                        React.createElement(DueDateField, __assign({}, sharedProps))))),
            React.createElement(InvoiceLines, __assign({}, sharedProps, { initialValues: initialValues })),
            React.createElement("section", { className: styles('summary') },
                React.createElement("div", null,
                    React.createElement(CommentField, __assign({}, sharedProps))),
                React.createElement(TotalsDetails, __assign({}, sharedProps))),
            company && React.createElement(InvoiceFooter, null))));
};
