import React from 'react';
import { i18nKeys, useTranslation } from 'locales/';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import CustomTable from 'shared/components/CustomTable';
import { useLoadNotificationsCount } from 'shared/hooks';
import { Button } from 'shared/io';
import { accountActions } from 'store/account/account.actions';
import { useQueryClient } from '@tanstack/react-query';
import ActivitiesItem from './ActivitiesItem';
import ActivityFiltersForm from './ActivityFiltersForm';
var EnhancedTable = CustomTable(ActivitiesItem, ActivityFiltersForm);
export default function Activities() {
    var _a;
    var t = useTranslation().t;
    var activity = useSelector(function (state) { return state.account.activity || {}; });
    var notificationsCount = useLoadNotificationsCount().notificationsCount;
    var queryClient = useQueryClient();
    var TABLE_HEADERS = [
        {
            title: t(i18nKeys.ACTIVITIES.AUTHOR),
            key: 'author',
            width: 120,
        },
        {
            title: t(i18nKeys.DATE),
            key: 'date',
            width: 150,
        },
        {
            title: t(i18nKeys.ACTIVITIES.DETAIL),
            key: 'description',
        },
        {
            title: t(i18nKeys.FORM.ACTIONS),
            width: 180,
        },
    ];
    var reload = function () {
        accountActions.activityPage(qs.parse(location.search));
        queryClient.invalidateQueries({ queryKey: ['notifications', 'count'] });
    };
    var seenAll = function () {
        accountActions.activitySeenAll({
            callback: reload,
        });
    };
    return (React.createElement(EnhancedTable, { title: t(i18nKeys.NAV.ACTIVITY_BOOK), loadFunc: accountActions.activityPage, loading: activity.loading, loaded: activity.loaded, pagination: (_a = activity.metadata) === null || _a === void 0 ? void 0 : _a.pagination, headers: TABLE_HEADERS, items: activity.pages, noCheckbox: true, actions: notificationsCount !== 0 ? (React.createElement(Button, { label: t(i18nKeys.ACTIVITIES.MARK_ALL_AS_READ), noMargin: true, onClick: seenAll })) : null }));
}
