import { __awaiter, __generator } from "tslib";
import dayjs from 'dayjs';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';
var ReportSchema = z.object({
    id: z.string(),
    name: z.string().min(1),
    periodicity: z.object({
        type: z.enum(['weekly', 'monthly']),
        days: z.array(z.number()),
    }),
    lastExecutedAt: z
        .string()
        .nullable()
        .transform(function (data) { return (data == null ? data : dayjs(data)); }),
});
// The bare report is not exported because its definition conflicts with the full Report type defined in use-load-report.ts
var ReportsSchema = z
    .object({
    exportTasks: z.array(ReportSchema),
})
    .transform(function (data) { return data.exportTasks; });
export function loadReportsQueryFn(axiosInstance) {
    return __awaiter(this, void 0, void 0, function () {
        var instance, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [4 /*yield*/, instance.get('/reports/export_tasks')];
                case 2:
                    data = (_a.sent()).data;
                    return [2 /*return*/, ReportsSchema.parse(data)];
            }
        });
    });
}
export function useLoadReports() {
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['reports'],
        queryFn: function () { return loadReportsQueryFn(axiosInstance); },
    });
    return addResourceNameToQueryResult('reports', queryResult);
}
