import { __read } from "tslib";
import React from 'react';
import { nice, ticks } from 'd3-array';
import { i18nKeys, useTranslation } from 'locales';
import { upperFirst } from 'lodash-es';
import { firstBy, identity, pipe } from 'remeda';
import { BarChartTooltip } from 'shared/charts';
import { useProfile } from 'shared/hooks';
import { roundToNearestMultipleOfN } from 'shared/utils';
import { Text, useMantineTheme } from '@mantine/core';
import { ResponsiveLine } from '@nivo/line';
var Y_AXIS_PADDING = 10;
var DESIRED_Y_AXIS_TICKS = 8;
export var DsoChart = function (_a) {
    var dso = _a.dso;
    var t = useTranslation().t;
    var profile = useProfile();
    var theme = useMantineTheme();
    var paddedMaxValue = pipe(dso, Object.values, firstBy([identity(), 'desc']), function (max) { return max + Y_AXIS_PADDING; }, function (maxPadded) { return roundToNearestMultipleOfN(maxPadded, 10); });
    var _b = __read(nice(0, paddedMaxValue, DESIRED_Y_AXIS_TICKS), 2), yAxisUpperBound = _b[1];
    var yValues = ticks(0, yAxisUpperBound, DESIRED_Y_AXIS_TICKS);
    return (React.createElement(ResponsiveLine, { isInteractive: true, enableSlices: "x", enableArea: true, defs: [
            {
                colors: [
                    {
                        color: 'inherit',
                        offset: 0,
                    },
                    {
                        color: 'inherit',
                        offset: 100,
                        opacity: 0,
                    },
                ],
                id: 'gradientA',
                type: 'linearGradient',
            },
        ], fill: [
            {
                id: 'gradientA',
                match: '*',
            },
        ], useMesh: true, curve: "monotoneX", enableCrosshair: true, margin: { top: 10, right: 15, bottom: 35, left: 55 }, axisBottom: {
            tickPadding: 15,
            tickSize: 0,
            format: function (date) { return date.toLocaleDateString(profile.locale, { month: 'short' }); },
        }, axisLeft: {
            tickPadding: 15,
            tickSize: 0,
            tickValues: yValues,
        }, gridYValues: yValues, sliceTooltip: function (d) {
            setTimeout(function () {
                var tooltip = document.getElementById('tooltip-fix');
                if (tooltip)
                    tooltip.style.opacity = '1';
            }, 50);
            return (React.createElement(BarChartTooltip, { style: { opacity: 0, transition: 'all 0.1s ease-in-out' }, id: "tooltip-fix" },
                React.createElement(Text, { fw: 700, mr: 7 }, "".concat(upperFirst(d.slice.points[0].data.x.toLocaleDateString(profile.locale, {
                    month: 'long',
                })), ":")),
                React.createElement(Text, null, t(i18nKeys.DAYS, { count: d.slice.points[0].data.y }))));
        }, yScale: { type: 'linear', min: 0, max: yAxisUpperBound }, xScale: { type: 'time' }, enableGridX: false, pointSize: 8, pointBorderWidth: 2, pointBorderColor: { from: 'serieColor' }, colors: [theme.colors.blue[7]], pointColor: "white", theme: {
            grid: {
                line: {
                    stroke: theme.colors.gray[3],
                    strokeDasharray: 7,
                    strokeDashoffset: 15,
                },
            },
            labels: {
                text: {
                    fontSize: 14,
                    fontFamily: 'Work Sans',
                    fill: theme.colors.gray[6],
                },
            },
            text: {
                fontSize: 12,
                fontFamily: 'Work Sans',
                fill: theme.colors.gray[6],
            },
        }, data: [
            {
                id: 'dso',
                color: theme.colors.cyan[4],
                data: Object.entries(dso).map(function (_a) {
                    var _b = __read(_a, 2), key = _b[0], value = _b[1];
                    return ({ x: new Date(key), y: value });
                }),
            },
        ] }));
};
