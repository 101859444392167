import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult, useLegacyAxiosInstance, } from './utils';
export var UpdateTaskFormSchema = z.object({ autoclose_tasks: z.boolean() });
export function updateTaskFn(axiosInstance, data) {
    return __awaiter(this, void 0, void 0, function () {
        var instance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [2 /*return*/, instance.put('/company_configurations', UpdateTaskFormSchema.parse(data))];
            }
        });
    });
}
export function useUpdateTaskSettings() {
    var client = useQueryClient();
    var axiosInstance = useLegacyAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (data) { return updateTaskFn(axiosInstance, data); },
        onSuccess: function (_) {
            notifications.show({
                message: 'Task updated',
                color: 'green',
            });
            client.invalidateQueries({ queryKey: ['taskSettings'] });
        },
        onError: function (error) {
            var _a;
            (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error_message.forEach(function (message) {
                notifications.show({
                    title: 'Error',
                    color: 'red',
                    message: message,
                });
            });
        },
    });
    return addActionNameToMutationResult('updateTaskSetting', mutation);
}
