import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';
var PaymentSchema = z.discriminatedUnion('isLate', [
    z.object({ isLate: z.literal(true), clientPortalLink: z.string().min(1) }),
    z.object({ isLate: z.literal(false), clientPortalLink: z.null() }),
]);
var CompanySchema = z.object({
    id: z.number(),
    name: z.string().min(1),
    subdomain: z.string().min(1),
    payment: PaymentSchema,
});
export function loadCompanyQueryFn(axiosInstance) {
    return __awaiter(this, void 0, void 0, function () {
        var instance, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [4 /*yield*/, instance.get('/company')];
                case 2:
                    data = (_a.sent()).data;
                    return [2 /*return*/, CompanySchema.parse(data)];
            }
        });
    });
}
export function useLoadCompany() {
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['company'],
        queryFn: function () { return loadCompanyQueryFn(axiosInstance); },
    });
    return addResourceNameToQueryResult('company', queryResult);
}
