import { treatProduct } from 'shared/serializer';
import { productConstants as events } from 'store/product/product.actions';
import { baseReducerListPage, handleDeletePaginationResponse, handlePageRequest, handlePageResponse, handleRequest, handleResponse, handleUpdatePaginationResponse, } from 'store/reducers';
export var initialState = {
    list: baseReducerListPage,
    search: {
        loaded: true,
        loading: false,
        metadata: {
            total: 0,
            pagination: { total_objects: 0, page_limit: 20, current_page: 1, last_page: 1 },
        },
        RequestData: {},
        data: [],
    },
    current: null,
};
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case events.listPage.request:
            return handlePageRequest(state, action, 'list');
        case events.listPage.result:
            return handlePageResponse(state, action, 'list', treatProduct);
        case events.search.result:
            return handleResponse(state, action, 'search', treatProduct);
        case events.detail.request:
            return handleRequest(state, action, 'current', true);
        case events.detail.result:
            return handleResponse(state, action, 'current', treatProduct);
        case events.add.result:
            return state;
        case events.update.result:
            return handleUpdatePaginationResponse(state, action, 'list', treatProduct);
        case events.delete.result:
            action.payload = action.id;
            return handleDeletePaginationResponse(state, action, 'list');
        default:
            return state;
    }
};
export default reducer;
