var _a;
import { __assign } from "tslib";
import { DialogShowId, viewActionTypes as events } from 'store/view/view.actions';
export var initialState = {
    dialogs: (_a = {
            count: 0
        },
        _a[DialogShowId.CONFIRM] = {
            active: false,
        },
        _a[DialogShowId.CUSTOM] = {
            active: false,
        },
        _a[DialogShowId.INVOICE] = {
            active: false,
        },
        _a),
    sideMenu: {
        active: false,
    },
};
var reducer = function (state, action) {
    var _a, _b;
    if (state === void 0) { state = initialState; }
    var item;
    switch (action.type) {
        case events.dialog.show:
            item = action.payload;
            item.active = true;
            return __assign(__assign({}, state), { dialogs: __assign(__assign({}, state.dialogs), (_a = { count: state.dialogs.count + 1 }, _a[item.id] = item, _a)) });
        case events.dialog.hide:
            return __assign(__assign({}, state), { dialogs: __assign(__assign({}, state.dialogs), (_b = { count: state.dialogs.count - 1 }, _b[action.payload] = __assign(__assign({}, state.dialogs[action.payload]), { active: false }), _b)) });
        case events.sideMenu.show:
            return __assign(__assign({}, state), { sideMenu: __assign(__assign({}, action.payload), { active: true }) });
        case events.sideMenu.setAskBeforeClose:
            return __assign(__assign({}, state), { sideMenu: __assign(__assign({}, state.sideMenu), { askBeforeClose: action.payload }) });
        case events.sideMenu.hide:
            return __assign(__assign({}, state), { sideMenu: __assign(__assign({}, state.sideMenu), { active: false }) });
        default:
            return state;
    }
};
export default reducer;
