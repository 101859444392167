import { useMemo } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { isConnectorActive } from 'shared/utils/connector';
import { useGetCompany } from 'shared/utils/selectors';
import { IconFileDollar, IconInvoice, IconShoppingCart } from '@tabler/icons-react';
export function useBuildInvoiceMenu() {
    var t = useTranslation().t;
    var company = useGetCompany();
    var hasActiveConnector = isConnectorActive();
    return useMemo(function () {
        var items = [
            {
                path: '/invoices/listing',
                label: t(i18nKeys.NAV.ALL_INVOICE),
                Icon: IconFileDollar,
            },
            {
                path: '/credit-notes/listing',
                label: t(i18nKeys.NAV.CREDIT_NOTES),
                Icon: IconInvoice,
            },
            {
                path: '/products',
                label: t(i18nKeys.NAV.PRODUCTS),
                Icon: IconShoppingCart,
                isHidden: hasActiveConnector,
            },
        ];
        return {
            parent: null,
            label: t(i18nKeys.COMMON.INVOICING),
            Icon: IconFileDollar,
            items: items,
        };
    }, [company, hasActiveConnector, t]);
}
