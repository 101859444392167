import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useLegacyAxiosInstance } from './utils';
var ExternalMailboxDataSchema = z.object({
    id: z.string(),
    attributes: z.object({
        host: z.string(),
        port: z.number(),
        ssl: z.boolean(),
        login: z.string(),
        is_microsoft: z.boolean().nullable(),
        signature: z.string().nullable(),
    }),
});
var ExternalMailboxMetadataSchema = z.object({
    pagination: z.object({
        current_page: z.number(),
        last_page: z.number(),
        page_limit: z.number(),
        total_objects: z.number(),
    }),
});
var ExternalMailboxConfigurationSchema = z.object({
    data: z.array(ExternalMailboxDataSchema),
    metadata: ExternalMailboxMetadataSchema,
});
export function loadExternalMailboxesFn(axiosInstance) {
    return __awaiter(this, void 0, void 0, function () {
        var instance, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [4 /*yield*/, instance.get('/external_mailbox_configurations')];
                case 2:
                    data = (_a.sent()).data;
                    return [2 /*return*/, ExternalMailboxConfigurationSchema.parse(data)];
            }
        });
    });
}
export var useLoadExternalMailboxes = function () {
    var axiosInstance = useLegacyAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['externalMailboxes'],
        queryFn: function () { return loadExternalMailboxesFn(axiosInstance); },
    });
    return addResourceNameToQueryResult('externalMailboxes', queryResult);
};
