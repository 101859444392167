import { __assign, __read } from "tslib";
import React, { useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { ExportCsv } from 'shared/components/ExportCsv';
import { appActions } from 'store/app/app.actions';
export var ExportTasksCsv = function () {
    var t = useTranslation().t;
    var constants = useSelector(function (state) { return state.app.constants; });
    var _a = __read(useState({
        exportFields: constants.task_export_columns.task_fields,
        wrappers: [
            {
                name: 'selectTaskInfo',
                fields: constants.task_export_columns.task_fields,
                text: t(i18nKeys.COMMON.CLIENT),
            },
        ],
    }), 1), _b = _a[0], exportFields = _b.exportFields, wrappers = _b.wrappers;
    var downloadCsv = function (data) {
        appActions.export({
            data: __assign(__assign(__assign({}, data), (data.export_with_filters === true ? qs.parse(location.search) : {})), { view_id: location.hash.slice(1), resource_type: 'task', type: 'csv' }),
            noLoading: true,
        });
    };
    return (React.createElement(ExportCsv, { downloadCsv: downloadCsv, allFields: exportFields, wrappers: wrappers, selectAllLabel: t(i18nKeys.INVOICING.EXPORT_CSV.EXPORT_ALL_TASKS), selectFilteredLabel: t('INVOICING.EXPORT_CSV.EXPORT_FILTERED_TASKS') }));
};
