import { __awaiter, __generator } from "tslib";
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { useAxiosInstance } from './utils';
export function subscribeToMonitoring(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance;
        var debtorId = _b.debtorId;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [2 /*return*/, instance.put("debtors/".concat(debtorId, "/monitoring"))];
            }
        });
    });
}
export function useSubscribeToMonitoring() {
    var client = useQueryClient();
    var axiosInstance = useAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (variables) { return subscribeToMonitoring(axiosInstance, variables); },
        onSuccess: function (response, _a) {
            var debtorId = _a.debtorId;
            notifications.show({
                message: response.data.message,
                color: 'green',
            });
            client.invalidateQueries({
                queryKey: ['monitoring', debtorId],
            });
        },
        onError: function (error) {
            var _a, _b, _c;
            notifications.show({
                message: (_c = (_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error_message) !== null && _c !== void 0 ? _c : error.message,
                color: 'red',
            });
        },
    });
    return addActionNameToMutationResult('subscribeToMonitoring', mutation);
}
