import { __makeTemplateObject } from "tslib";
import React from 'react';
import { matchPath, useHistory, useLocation } from 'react-router';
import { isDefined, isNonNullish } from 'remeda';
import { css } from '@emotion/css';
import { NavLink, Text } from '@mantine/core';
import { MenuItemBadges } from './MenuItemBadges';
export var NavMenuItem = function (_a) {
    var label = _a.label, Icon = _a.Icon, path = _a.path, pathSuffix = _a.pathSuffix, unreadCount = _a.unreadCount, tagLabel = _a.tagLabel, isHidden = _a.isHidden, onClick = _a.onClick, onNavigate = _a.onNavigate;
    var history = useHistory();
    var location = useLocation();
    if (isHidden)
        return null;
    if (isDefined(path)) {
        var doesMatchCurrentPath = isNonNullish(matchPath(location.pathname, { path: path, exact: false }));
        return (React.createElement(NavLink, { onClick: function () {
                history.push(path.concat(pathSuffix !== null && pathSuffix !== void 0 ? pathSuffix : ''));
                onNavigate === null || onNavigate === void 0 ? void 0 : onNavigate();
            }, className: styles.navLink, classNames: {
                root: styles.navLinkRoot(doesMatchCurrentPath),
            }, label: React.createElement(Text, { c: "#4D4D4D", fw: doesMatchCurrentPath ? 500 : undefined }, label), leftSection: React.createElement(Icon, { color: "#67869B", stroke: "1.7", size: 20 }), rightSection: React.createElement(MenuItemBadges, { unreadCount: unreadCount, tagLabel: tagLabel }) }));
    }
    return (React.createElement(NavLink, { onClick: onClick, className: styles.navLink, label: React.createElement(Text, { c: "#4D4D4D" }, label), leftSection: React.createElement(Icon, { color: "#67869B", stroke: "1.7", size: 20 }) }));
};
export var styles = {
    navLinkRoot: function (isActive) {
        if (isActive === void 0) { isActive = false; }
        return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    box-sizing: border-box;\n    background-color: ", " !important;\n    border: ", " !important;\n    border-radius: 8px;\n  "], ["\n    box-sizing: border-box;\n    background-color: ", " !important;\n    border: ", " !important;\n    border-radius: 8px;\n  "])), isActive ? '#F2F5F7' : 'unset', isActive ? '1px solid #CCD7DE' : '1px solid transparent');
    },
    navLink: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-weight: 400;\n    margin-bottom: 4px;\n\n    &:hover {\n      background-color: #f2f5f7 !important;\n    }\n  "], ["\n    font-weight: 400;\n    margin-bottom: 4px;\n\n    &:hover {\n      background-color: #f2f5f7 !important;\n    }\n  "]))),
};
var templateObject_1, templateObject_2;
