import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { isDefined, isNullish } from 'remeda';
import { useLoadAgedBalance, useLoadAgedBalanceCompact, useLoadAgedBalanceOverTime, useLoadViewsV2, } from 'shared/hooks';
import { PageTitle } from 'shared/layout';
import { ActionIcon, Card, Group, LoadingOverlay, Select, Space, Stack } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { IconArrowLeft } from '@tabler/icons-react';
import { UpdatedAt } from '../components';
import { AgedBalanceCompactChart, AgedBalanceOverTimeChart, AgedBalanceTable, } from '../components/charts';
export var AgedBalance = function () {
    var _a;
    var history = useHistory();
    var t = useTranslation().t;
    var _b = __read(useLocalStorage({
        key: 'aged-balance-view',
        defaultValue: null,
    }), 2), selectedViewId = _b[0], setSelectedViewId = _b[1];
    var debtorViews = useLoadViewsV2({
        resourceType: 'debtors',
        onSuccess: function (views) {
            if (isNullish(selectedViewId))
                setSelectedViewId(views[0].id);
        },
    }).views;
    useEffect(function () {
        if (isDefined(debtorViews) && isNullish(selectedViewId))
            setSelectedViewId(debtorViews[0].id);
    }, [debtorViews]);
    var _c = useLoadAgedBalanceCompact({
        viewId: selectedViewId,
    }), agedBalanceCompact = _c.agedBalanceCompact, isAgedBalanceCompactLoading = _c.isAgedBalanceCompactLoading;
    var _d = useLoadAgedBalance({
        viewId: selectedViewId,
    }), agedBalance = _d.agedBalance, isAgedBalanceLoading = _d.isAgedBalanceLoading;
    var _e = useLoadAgedBalanceOverTime({
        viewId: selectedViewId,
    }), agedBalanceOverTime = _e.agedBalanceOverTime, isAgedBalanceOverTimeLoading = _e.isAgedBalanceOverTimeLoading;
    var _f = __read(useState('overTime'), 2), agedBalanceVersion = _f[0], setAgedBalanceVersion = _f[1];
    // If there are too few dates for the balance over time to display nicely, fallback to compact balance
    useEffect(function () {
        if (!isAgedBalanceOverTimeLoading && agedBalanceOverTime != null) {
            dispatchChartComponent(agedBalanceOverTime);
        }
    }, [isAgedBalanceOverTimeLoading, agedBalanceOverTime]);
    var dispatchChartComponent = function (_agedBalanceOverTime) {
        var amountOfDatesPresent = Object.keys(_agedBalanceOverTime.data).length;
        var shouldFallbackToCompactBalance = amountOfDatesPresent < 2;
        if (shouldFallbackToCompactBalance)
            setAgedBalanceVersion('compact');
    };
    var _g = __read(agedBalanceVersion === 'compact'
        ? [
            isAgedBalanceCompactLoading,
            React.createElement(AgedBalanceCompactChart, { agedBalanceCompact: agedBalanceCompact }),
        ]
        : [
            isAgedBalanceOverTimeLoading,
            React.createElement(AgedBalanceOverTimeChart, { agedBalanceOverTime: agedBalanceOverTime }),
        ], 2), isLoading = _g[0], chartComponent = _g[1];
    return (React.createElement("div", null,
        React.createElement(PageTitle, null,
            React.createElement(Group, { align: "center", justify: "center" },
                React.createElement(ActionIcon, { onClick: function () { return history.push('/analytics'); }, size: "xl", variant: "light" },
                    React.createElement(IconArrowLeft, null)),
                React.createElement(Stack, { gap: 0 },
                    t(i18nKeys.ANALYTICS.AGED_BALANCE.TITLE),
                    React.createElement(UpdatedAt, null))),
            React.createElement(PageTitle.Actions, null,
                React.createElement(Select, { label: t(i18nKeys.FILTER), onChange: setSelectedViewId, allowDeselect: false, value: selectedViewId, data: (_a = debtorViews === null || debtorViews === void 0 ? void 0 : debtorViews.map(function (_a) {
                        var id = _a.id, name = _a.name;
                        return ({ label: name, value: id });
                    })) !== null && _a !== void 0 ? _a : [] }))),
        React.createElement(Space, { h: "md" }),
        React.createElement(Card, { radius: "md", shadow: "sm", style: { overflow: 'visible' } },
            React.createElement(LoadingOverlay, { visible: isLoading }),
            chartComponent),
        React.createElement(AgedBalanceTable, { agedBalance: agedBalance, isLoading: isAgedBalanceLoading })));
};
