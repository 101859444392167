import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Button } from 'shared/io';
import { useGetCompany } from 'shared/utils/selectors';
import { accountActions } from 'store/account/account.actions';
import { Integration, INTEGRATIONS_ROUTE } from '../../Integrations.constants';
import { INTEGRATION_UPDATE_CALLBACK, IntegrationFormFieldsName } from '../Integration.constants';
import { getIntegrationRights } from '../Integration.utils';
import { IntegrationHeader } from '../IntegrationHeader';
import { YukiForm, YukiFormFieldsName } from './YukiForm';
import styleIdentifiers from '../Integration.scss';
var styles = classNames.bind(styleIdentifiers);
export var Yuki = function (_a) {
    var _b;
    var _c = _a.history, goBack = _c.goBack, replace = _c.replace;
    var t = useTranslation().t;
    var company = useGetCompany();
    var _d = __read(useState(false), 2), isEditting = _d[0], setIsEditting = _d[1];
    var _e = getIntegrationRights(Integration.YUKI, company), isActive = _e.isActive, canActivate = _e.canActivate, isConnected = _e.isConnected;
    useEffect(function () {
        if (!canActivate && !isActive) {
            replace(INTEGRATIONS_ROUTE);
        }
    }, [canActivate, isActive, replace]);
    var handleSubmit = function (data) {
        accountActions.updateYukiSettings({
            data: data,
            callback: function () {
                INTEGRATION_UPDATE_CALLBACK();
                setIsEditting(false);
            },
        });
    };
    if (!company.tp_accounting) {
        return (React.createElement("div", { className: styles('loading') },
            React.createElement("div", null, t(i18nKeys.LOADING))));
    }
    var _f = company.tp_accounting, additional_parameters = _f.additional_parameters, automatic_sync = _f.automatic_sync, issue_date_limit = _f.issue_date_limit, use_export = _f.use_export, use_import = _f.use_import, debtor_update = _f.debtor_update, use_payment_export = _f.use_payment_export, use_payment_matching = _f.use_payment_matching;
    return (React.createElement("article", { className: styles('yuki') },
        React.createElement(IntegrationHeader, { name: Integration.YUKI, goBack: goBack, isActive: isActive, isConnected: isConnected }),
        !isEditting && isActive ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles('key-text') },
                React.createElement("div", { className: styles('attribute') },
                    t(i18nKeys.FORM.YUKI.API_KEY),
                    ":"),
                React.createElement("div", { className: styles('value') },
                    "*****", additional_parameters === null || additional_parameters === void 0 ? void 0 :
                    additional_parameters.api_key)),
            React.createElement("div", { className: styles('key-text') },
                React.createElement("div", { className: styles('attribute') },
                    t(i18nKeys.FORM.YUKI.ADMIN_KEY),
                    ":"),
                React.createElement("div", { className: styles('value') },
                    "*****", additional_parameters === null || additional_parameters === void 0 ? void 0 :
                    additional_parameters.admin_key)),
            React.createElement("div", { className: styles('key-text') },
                React.createElement("div", { className: styles('attribute') },
                    t(i18nKeys.FORM.YUKI.IMPORT_ON_RECOVR),
                    ":"),
                React.createElement("div", { className: styles('value') }, t(use_import ? i18nKeys.YES : i18nKeys.NO))),
            React.createElement("div", { className: styles('key-text') },
                React.createElement("div", { className: styles('attribute') },
                    t(i18nKeys.FORM.YUKI.EXPORT_ON_YUKI),
                    ":"),
                React.createElement("div", { className: styles('value') }, t(use_export ? i18nKeys.YES : i18nKeys.NO))),
            React.createElement("div", { className: styles('key-text') },
                React.createElement("div", { className: styles('attribute') },
                    t(i18nKeys.FORM.USE_PAYMENT_MATCHING, {
                        text: Integration.YUKI,
                    }),
                    ":"),
                React.createElement("div", { className: styles('value') }, t(use_payment_matching ? i18nKeys.YES : i18nKeys.NO))),
            React.createElement("div", { className: styles('key-text') },
                React.createElement("div", { className: styles('attribute') },
                    t(i18nKeys.FORM.EXPORT_RECOVR_PAYMENTS, {
                        text: Integration.YUKI,
                    }),
                    ":"),
                React.createElement("div", { className: styles('value') }, t(use_payment_export ? i18nKeys.YES : i18nKeys.NO))),
            use_payment_export && (React.createElement("div", { className: styles('key-text') },
                React.createElement("div", { className: styles('attribute') },
                    t(i18nKeys.FORM.YUKI.CASH_ACCOUNT),
                    ":"),
                React.createElement("div", { className: styles('value') }, additional_parameters === null || additional_parameters === void 0 ? void 0 : additional_parameters.cash_account))),
            React.createElement("div", { className: styles('key-text') },
                React.createElement("div", { className: styles('attribute') },
                    t(i18nKeys.FORM.INTEGRATION.AUTOMATIC_SYNC),
                    ":"),
                React.createElement("div", { className: styles('value') }, t(automatic_sync ? i18nKeys.YES : i18nKeys.NO))),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { label: t(i18nKeys.EDIT), onClick: function () { return setIsEditting(function (current) { return !current; }); } })))) : (React.createElement(YukiForm, { onSubmit: handleSubmit, initialValues: (_b = {},
                _b[YukiFormFieldsName.EXPORT_INTEGRATION] = use_export,
                _b[YukiFormFieldsName.IMPORT_INTEGRATION] = use_import,
                _b[YukiFormFieldsName.PAYMENT_EXPORT] = use_payment_export,
                _b[YukiFormFieldsName.PAYMENT_MATCHING] = use_payment_matching,
                _b[YukiFormFieldsName.CASH_ACCOUNT] = additional_parameters === null || additional_parameters === void 0 ? void 0 : additional_parameters.cash_account,
                _b[IntegrationFormFieldsName.DEBTOR_UPDATE] = debtor_update,
                _b[IntegrationFormFieldsName.AUTOMATIC_SYNC] = automatic_sync,
                _b[IntegrationFormFieldsName.ISSUE_DATE_LIMIT] = issue_date_limit,
                _b) }))));
};
