import { __assign, __generator, __rest } from "tslib";
import { jotaiStore } from 'init';
import { i18nKeys } from 'locales';
import { delay } from 'redux-saga';
import { all, call, takeLatest } from 'redux-saga/effects';
import { ublProgressAtom } from 'shared/forms/InvoiceImportForm/InvoiceImportForm';
import { downloadFile, extractFilename } from 'shared/utils/view';
import { accountActions, accountConstants as events } from 'store/account/account.actions';
import { api } from 'store/apis';
import { error, success } from 'store/view/view.actions';
import { apiCall, sendApi } from '../sagas';
/**
 * Other
 */
export function userInit() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, apiCall({
                    api: api.company.detail,
                    noFeedback: true,
                    noLoading: true,
                    actionRes: accountActions.getCompanyRes,
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
/**
 * Company
 */
function updateCompany(_a) {
    var callback, noFeedback, noLoading, data;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                callback = payload.callback, noFeedback = payload.noFeedback, noLoading = payload.noLoading, data = __rest(payload, ["callback", "noFeedback", "noLoading"]);
                if (!data.logo) return [3 /*break*/, 2];
                return [4 /*yield*/, apiCall({
                        api: api.company.update,
                        fileUpload: true,
                        data: {
                            logo: '', // data.logo,
                        },
                    })];
            case 1:
                _b.sent();
                data.logo = undefined;
                _b.label = 2;
            case 2: return [4 /*yield*/, apiCall({
                    api: api.company.update,
                    data: data,
                    callback: callback,
                    noLoading: noLoading,
                    success: !noFeedback && i18nKeys.SETTINGS.COMPANY.SUCCESSFULLY_UPDATE,
                    actionRes: accountActions.updateCompanyRes,
                })];
            case 3:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
/**
 * Upload terms
 */
function uploadTerms(_a) {
    var callback, id, pdf;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                callback = payload.callback, id = payload.id, pdf = payload.pdf;
                return [4 /*yield*/, apiCall({
                        api: api.company.uploadTerms,
                        id: id,
                        fileUpload: true,
                        callback: callback,
                        data: {
                            pdf: pdf,
                        },
                    })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
/**
 * Download terms
 */
function downloadTerms(_a) {
    var request, filename;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(apiCall, {
                    api: api.company.getTerms,
                    id: payload.id,
                    data: payload,
                })];
            case 1:
                request = _b.sent();
                if (!(request && request.status === 200)) return [3 /*break*/, 2];
                filename = extractFilename(request.headers);
                downloadFile(request.data, filename || 'recovr.company-terms.pdf');
                return [3 /*break*/, 4];
            case 2: return [4 /*yield*/, error({
                    text: i18nKeys.ERROR.DOC_NOT_AVAILABLE,
                })];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}
/**
 * Import
 */
function importInvoice(_a) {
    var ublFiles, planId, errors, _loop_1, i;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                ublFiles = payload.ublFiles, planId = payload.planId;
                jotaiStore.set(ublProgressAtom, { active: true, value: 0 });
                errors = [];
                _loop_1 = function (i) {
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0: return [4 /*yield*/, call(apiCall, {
                                    api: api.invoice.import.ubl,
                                    fileUpload: true,
                                    noFeedback: true,
                                    noLoading: true,
                                    progress: function () { },
                                    data: {
                                        invoice_ubl_file: ublFiles[i],
                                        recovery_plan_id: planId,
                                    },
                                    actionFailure: function (response) {
                                        var _a, _b;
                                        errors.push((_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.error_message) !== null && _b !== void 0 ? _b : '');
                                        jotaiStore.set(ublProgressAtom, { active: true, value: (100 * (i + 1)) / ublFiles.length });
                                    },
                                    callback: function () {
                                        return jotaiStore.set(ublProgressAtom, {
                                            active: true,
                                            value: Math.round((100 * (i + 1)) / ublFiles.length),
                                        });
                                    },
                                })];
                            case 1:
                                _c.sent();
                                return [2 /*return*/];
                        }
                    });
                };
                i = 0;
                _b.label = 1;
            case 1:
                if (!(i < ublFiles.length)) return [3 /*break*/, 4];
                return [5 /*yield**/, _loop_1(i)];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3:
                i++;
                return [3 /*break*/, 1];
            case 4:
                if (!(errors.length > 0)) return [3 /*break*/, 5];
                jotaiStore.set(ublProgressAtom, { active: true, errors: errors });
                return [3 /*break*/, 9];
            case 5: return [4 /*yield*/, success({ text: i18nKeys.INVOICE.SUCCESSFUL_IMPORT })];
            case 6:
                _b.sent();
                return [4 /*yield*/, call(delay, 500)];
            case 7:
                _b.sent();
                if (!payload.callback) return [3 /*break*/, 9];
                return [4 /*yield*/, call(payload.callback)];
            case 8:
                _b.sent();
                _b.label = 9;
            case 9: return [2 /*return*/];
        }
    });
}
function UserWatchers() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeLatest(events.editBankingInfo.request, sendApi(api.settings.editBankingInfo))];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeLatest(events.resetSecretId.request, sendApi(api.settings.resetSecretId))];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.askUpgradePackage.request, sendApi(api.settings.askUpgradePackage))];
            case 3:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.syncIntegration.request, sendApi(api.settings.syncIntegration, accountActions.getCompanyRes))];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.importInvoice.request, importInvoice)];
            case 5:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.updateBillitSettings.request, sendApi(api.settings.updateBillitSettings))];
            case 6:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.updateYukiSettings.request, sendApi(api.settings.updateYukiSettings))];
            case 7:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.updateHorusSettings.request, sendApi(api.settings.updateHorusSettings))];
            case 8:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.updateDbasicsSettings.request, sendApi(api.settings.updateDbasicsSettings))];
            case 9:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.setDigitealConfiguration.request, sendApi(api.settings.setDigitealConfiguration))];
            case 10:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.updateTeamleaderSettings.request, sendApi(api.settings.updateTeamleaderSettings))];
            case 11:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.updateExactSettings.request, sendApi(api.settings.updateExactSettings))];
            case 12:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.horusFetchCompanies.request, sendApi(api.settings.horusFetchCompanies))];
            case 13:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.exactFetchCompanies.request, sendApi(api.settings.exactFetchCompanies))];
            case 14:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.activityPage.request, sendApi(api.activity.list, accountActions.activityPageRes, function (payload) { return ({
                        data: payload,
                        noLoading: true,
                    }); }))];
            case 15:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.activityDetail.request, sendApi(api.activity.detail, accountActions.activityDetailRes))];
            case 16:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.activitySeen.request, sendApi(api.activity.markAsRead, accountActions.activitySeenRes))];
            case 17:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.activitySeenAll.request, sendApi(api.activity.markAllAsRead))];
            case 18:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getCompany.request, sendApi(api.company.detail, accountActions.getCompanyRes, function (payload) { return (__assign(__assign({}, payload), { noFeedback: true })); }))];
            case 19:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.updateInvoiceConfigurations.request, sendApi(api.settings.updateInvoiceConfigurations, accountActions.updateCompanyRes, function (_a) {
                        var _b;
                        var data = _a.data;
                        var payload = [];
                        for (var _i = 1; _i < arguments.length; _i++) {
                            payload[_i - 1] = arguments[_i];
                        }
                        return (__assign(__assign({}, payload), { data: __assign(__assign({}, data), { color: ((_b = data.color) === null || _b === void 0 ? void 0 : _b.hex) ? data.color.hex : data.color }) }));
                    }))];
            case 20:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.updateCompany.request, updateCompany)];
            case 21:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.updateCompanyInfo.request, sendApi(api.settings.updateCompany, accountActions.updateCompanyRes))];
            case 22:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.updateCompanyFiles.request, sendApi(api.company.updateCompanyFiles, accountActions.updateCompanyRes))];
            case 23:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getDigitealCGV.request, sendApi(api.settings.getDigitealCGV))];
            case 24:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getTerms.request, downloadTerms)];
            case 25:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.uploadTerms.request, uploadTerms)];
            case 26: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 27:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export default function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(UserWatchers)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
