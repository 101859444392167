import { __awaiter, __generator, __read, __spreadArray } from "tslib";
import { isNonNullish, pick, pickBy } from 'remeda';
import { addResourceNameToQueryResult, useAxiosInstance } from 'shared/hooks/utils';
import { z } from 'zod';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
// TODO: repeated code, extract
export var KANBAN_DEBTOR_COLUMNS = [
    'late',
    'first_reminder',
    'last_reminder',
    'formal_notice',
    'third_party_case',
    'disputed',
];
// #region Schemas
var KanbanDebtorSchema = z.object({
    id: z.number(),
    name: z.string(),
    remainingBalance: z.coerce.number(),
    unpaidInvoicesCount: z.number(),
});
var DebtorColumnSchema = z.object({
    debtors: z.array(KanbanDebtorSchema),
    total: z.coerce.number(),
});
export var LoadDebtorColumnVariablesSchema = z.object({
    accountManagerId: z.coerce.number().optional().nullable(),
    column: z.enum(__spreadArray([], __read(KANBAN_DEBTOR_COLUMNS), false)),
    fullName: z.string().optional(),
    minSaldo: z.union([z.number(), z.string()]).optional(),
    maxSaldo: z.union([z.number(), z.string()]).optional(),
});
// #endregion
// #region Hook
export function loadDebtorColumnQueryFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [4 /*yield*/, instance.get('kanban/debtors', { params: queryKey[1] })];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, DebtorColumnSchema.parse(data)];
            }
        });
    });
}
export var useLoadDebtorColumn = function (_variables) {
    var _a;
    var axiosInstance = useAxiosInstance();
    var variablesCompacted = pickBy(_variables, function (val) { return isNonNullish(val) && !isEmptyArrayOrString(val); });
    var variables;
    try {
        variables = LoadDebtorColumnVariablesSchema.parse(variablesCompacted);
    }
    catch (error) {
        variables = pick(_variables, ['column']);
        console.error((_a = error === null || error === void 0 ? void 0 : error.toString()) !== null && _a !== void 0 ? _a : error);
        captureException(error);
    }
    var queryResult = useQuery({
        queryKey: ['debtor-kanban', variables],
        queryFn: function (context) { return loadDebtorColumnQueryFn(axiosInstance, context); },
        placeholderData: function (previousData) { return previousData; },
    });
    return addResourceNameToQueryResult('debtorColumn', queryResult);
};
// #endregion
function isEmptyArrayOrString(arr) {
    if (Array.isArray(arr))
        return arr.length === 0;
    if (typeof arr === 'string')
        return arr === '';
    return false;
}
