import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import { mapToObj, omit, sortBy } from 'remeda';
import { store } from 'store/setupClientStore';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';
var UserSchema = z
    .object({
    email: z.string().email(),
    firstName: z.string().nonempty(),
    id: z.number(),
    isActive: z.boolean(),
    lastName: z.string().nonempty(),
    profilePicture: z.string().url().nullable(),
    roles: z.array(z.string()),
    hasAcceptedGc: z.boolean(),
    preferences: z.object({
        itemsPerPage: z.number(),
        emails: z.object({
            daily: z.boolean(),
            weekly: z.boolean(),
            notifications: z.boolean(),
        }),
    }),
})
    .transform(function (data) {
    // https://github.com/colinhacks/zod/issues/2203
    var roles = data.roles.includes('admin') ? ['admin'] : __spreadArray([], __read(data.roles), false);
    return __assign(__assign({}, omit(data, ['firstName', 'lastName', 'roles'])), { roles: roles, isAdmin: data.roles.includes('admin'), permissions: mapToObj(store.getState().app.constants.user_roles, function (role) { return [
            role.value,
            roles.includes(role.value),
        ]; }), name: {
            full: "".concat(data.firstName, " ").concat(data.lastName),
            first: data.firstName,
            last: data.lastName,
            initials: "".concat(data.firstName[0]).concat(data.lastName[0]),
        } });
});
var UsersSchema = z.array(UserSchema).transform(function (users) { return sortBy(users, function (user) { return user.id; }); });
export function loadUsersQueryFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [4 /*yield*/, instance.get("/users?".concat(new URLSearchParams({
                            showInactive: queryKey[1].loadInactiveUsers.toString(),
                            fullResponse: 'true',
                        }).toString()))];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, UsersSchema.parse(data)];
            }
        });
    });
}
export var useLoadUsers = function (loadInactiveUsers) {
    if (loadInactiveUsers === void 0) { loadInactiveUsers = false; }
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['users', { loadInactiveUsers: loadInactiveUsers }],
        queryFn: function (context) { return loadUsersQueryFn(axiosInstance, context); },
    });
    return addResourceNameToQueryResult('users', queryResult);
};
