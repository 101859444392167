import React from 'react';
import classNames from 'classnames/bind';
import { Button, ButtonColor } from 'shared/io';
import styleIdentifiers from './languagePicker.scss';
var styles = classNames.bind(styleIdentifiers);
// Only used for debtor reaction
export default function LanguagePicker(_a) {
    var changeLang = _a.changeLang, currentLang = _a.currentLang, availableLang = _a.availableLang;
    var onChangeLang = function (lang) {
        changeLang(lang);
    };
    return (React.createElement("div", { className: styles('container') }, availableLang === null || availableLang === void 0 ? void 0 : availableLang.map(function (lang) { return (React.createElement(Button, { key: lang.value, label: lang.value.toUpperCase(), className: styles('lang-button', currentLang === lang.value && 'active'), color: ButtonColor.MAIN, noMargin: true, noShadow: true, type: "button", onClick: function () { return onChangeLang(lang.value); } })); })));
}
