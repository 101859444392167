import { __assign, __read, __spreadArray } from "tslib";
import React, { useEffect } from 'react';
import { Notification } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { get } from 'lodash-es';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import FormSection from 'shared/forms/FormSection';
import { Button, Checkbox, CustomSelect, Input } from 'shared/io';
import { sideMenuSetAskBeforeClose } from 'store/view/view.actions';
import { AVAILABLE_LANGUAGES } from 'types';
import { getInitialValues } from './ContactPersonForm.utils';
import { ContactPersonFormAddress } from './ContactPersonFormAddress';
import { ContactPersonFormEmails } from './ContactPersonFormEmails';
import { ContactPersonFormPhones } from './ContactPersonFormPhones';
import styleIdentifiers from './ContactPersonForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var ContactPersonForm = function (_a) {
    var onSubmit = _a.onSubmit, contactPerson = _a.contactPerson, close = _a.close, inModal = _a.inModal;
    var t = useTranslation().t;
    var defaultValues = __assign({}, getInitialValues(contactPerson));
    var form = useForm({
        defaultValues: defaultValues,
    });
    var watch = form.watch, register = form.register, setValue = form.setValue, _b = form.formState, isDirty = _b.isDirty, errors = _b.errors, handleSubmit = form.handleSubmit;
    useEffect(function () {
        sideMenuSetAskBeforeClose(isDirty);
    }, [isDirty]);
    var notificationsItems = Object.values(Notification)
        .filter(function (value) { return value !== Notification.Debtor; })
        .map(function (value) { return ({
        description: t(i18nKeys.CONTACT_PERSON.NOTIFICATIONS[value.toUpperCase()]),
        value: value,
    }); });
    register('notifications');
    var notifications = watch('notifications') || [];
    var onCheck = function (value) {
        setValue('notifications', notifications.includes(value)
            ? notifications.filter(function (e) { return e !== value; })
            : __spreadArray(__spreadArray([], __read(notifications), false), [value], false), { shouldTouch: true, shouldDirty: true, shouldValidate: true });
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('contact-person-form', inModal && 'in-modal'), onSubmit: handleSubmit(onSubmit) },
            !inModal && (React.createElement("div", { className: styles('head') },
                contactPerson ? t(i18nKeys.CONTACT_PERSON.EDIT) : t(i18nKeys.CONTACT_PERSON.ADD),
                close && (React.createElement("div", { className: styles('close'), onClick: close },
                    React.createElement(Icon, { name: IconName.SIMPLE_REMOVE, size: "20px" }))))),
            React.createElement("div", { className: styles('content') },
                React.createElement("div", { className: styles('grid-row') },
                    React.createElement("div", { className: styles('col-6') },
                        React.createElement(Input, { label: t(i18nKeys.FORM.FIRSTNAME), errorMessage: get(errors, 'first_name'), register: register('first_name', {
                                required: true,
                            }), withBorder: true, noMargin: true })),
                    React.createElement("div", { className: styles('col-6') },
                        React.createElement(Input, { label: t(i18nKeys.FORM.LASTNAME), errorMessage: get(errors, 'last_name'), register: register('last_name', {
                                required: true,
                            }), withBorder: true, noMargin: true })),
                    React.createElement("div", { className: "col-6" },
                        React.createElement(Checkbox, { label: t(i18nKeys.CONTACT_PERSON.MAIN_CONTACT), errorMessage: get(errors, 'main_contact'), register: register('main_contact'), watch: watch })),
                    React.createElement("div", { className: "col-6" },
                        React.createElement(Controller, { name: "locale", render: function () { return (React.createElement(CustomSelect, { label: t(i18nKeys.FORM.CLIENT.CONTACT_LANG), name: "locale", keyText: "description", keyValue: "value", selectClassName: styles('input'), items: AVAILABLE_LANGUAGES, withBorder: true })); } })),
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(Input, { label: t(i18nKeys.FORM.JOB_TITLE), errorMessage: get(errors, 'job_title'), register: register('job_title'), withBorder: true })),
                    React.createElement(ContactPersonFormEmails, null),
                    React.createElement(ContactPersonFormPhones, { contactPerson: contactPerson }),
                    React.createElement(ContactPersonFormAddress, { contactPerson: contactPerson }),
                    React.createElement(FormSection, { title: t(i18nKeys.FORM.NOTIFICATIONS) }, notificationsItems.map(function (_a) {
                        var value = _a.value, description = _a.description;
                        return (React.createElement(Checkbox, { key: value, label: description, checked: notifications.includes(value), onChange: function () {
                                onCheck(value);
                            } }));
                    })))),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { type: "submit", label: t(i18nKeys.SAVE), disabled: !isDirty, noMargin: true })))));
};
