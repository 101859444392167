import { jotaiStore } from 'init';
import { i18nKeys, translationStateAtom } from 'locales/';
import { formattedDate, removeAttributes } from 'shared/utils/view';
export var treatTableViews = function (items) {
    var tableTypes = items.map(function (item) {
        var tableType = removeAttributes(item);
        tableType.table_columns = tableType.table_columns
            .map(function (column) { return removeAttributes(column); })
            .sort(function (i1, i2) { return (i1.order > i2.order ? 1 : -1); });
        tableType.filter_fields = tableType.filter_fields.map(function (filter_field) {
            return removeAttributes(filter_field);
        });
        return tableType;
    });
    return tableTypes;
};
export var filterToText = function (filterType, value, handler, operator, min_value, max_value) {
    var _a = jotaiStore.get(translationStateAtom), t = _a.t, currentLang = _a.currentLang;
    if (handler || operator === 'null' || operator === 'not_null') {
        return t((handler || operator) === 'not_null'
            ? i18nKeys.VIEWS.FILTERS.FILL_DATA
            : i18nKeys.VIEWS.FILTERS.EMPTY_DATA);
    }
    switch (filterType) {
        case 'select':
            return Array.isArray(value) ? value.join(', ') : value;
        case 'boolean':
            return value && t(value === 'false' ? i18nKeys.NO : i18nKeys.YES);
        case 'date':
            var cleanedValue = Array.isArray(value) ? value : value === null || value === void 0 ? void 0 : value.split(',');
            return value
                ? "".concat(formattedDate(cleanedValue[0], currentLang) || '∞', " - ").concat(formattedDate(cleanedValue[1], currentLang) || '∞')
                : '';
        case 'number':
            return ((min_value || max_value) &&
                "".concat(min_value && min_value !== 'null' ? min_value : '∞', " - ").concat(max_value && max_value !== 'null' ? max_value : '∞'));
        default:
            return value;
    }
};
