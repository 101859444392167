import { __read, __spreadArray, __values } from "tslib";
import { filter, isTruthy, partition, prop } from 'remeda';
export var isMenu = function (item) {
    return 'parent' in item;
};
/**
 * Finds the relevant menu or submenu based on the given location path.
 * Used to display the correct menu when the app loads on a specific page other than the dashboard
 */
export var findMenuForLocation = function (menu, locationPath) {
    var e_1, _a;
    var _b = __read(partition(menu.items, isMenu), 2), submenus = _b[0], navItems = _b[1];
    var pathsOfItemsInMenu = filter(navItems.map(prop('path')), isTruthy);
    if (pathsOfItemsInMenu.some(function (path) { return locationPath.includes(path); }))
        return menu;
    try {
        for (var submenus_1 = __values(submenus), submenus_1_1 = submenus_1.next(); !submenus_1_1.done; submenus_1_1 = submenus_1.next()) {
            var submenu = submenus_1_1.value;
            var parentMenu = findMenuForLocation(submenu, locationPath);
            if (parentMenu)
                return parentMenu;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (submenus_1_1 && !submenus_1_1.done && (_a = submenus_1.return)) _a.call(submenus_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return null;
};
export function menuToSpotlightActions(menu, navigate) {
    return menu.items.reduce(function (acc, item) {
        if (isMenu(item)) {
            return __spreadArray(__spreadArray([], __read(acc), false), __read(menuToSpotlightActions(item, navigate)), false);
        }
        return __spreadArray(__spreadArray([], __read(acc), false), [
            {
                id: item.label,
                label: item.label,
                onClick: function () { var _a; return navigate((_a = item.path) !== null && _a !== void 0 ? _a : ''); },
            },
        ], false);
    }, []);
}
