/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */
// eslint-disable-next-line @typescript-eslint/no-redeclare
export var TaskActionType = {
    skip: 'skip',
    complete: 'complete',
    postpone: 'postpone',
    assign: 'assign',
    revert: 'revert',
    delete: 'delete',
};
