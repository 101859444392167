import { __assign, __read, __rest } from "tslib";
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { ExternalMailboxSettingsForm } from 'app/Private/Settings';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { pick } from 'lodash-es';
import { isEmpty } from 'remeda';
import CustomTable from 'shared/components/CustomTable';
import ListResModal from 'shared/components/DeleteModal';
import { useBatchUpdateExternalMails, useCreateExternalMailbox, useLoadExternalMails, useManuallySyncExternalMails, useProfile, } from 'shared/hooks';
import { Button, ButtonColor } from 'shared/io';
import { useGetCompany } from 'shared/utils/selectors';
import { DialogShowId, DialogShowSize, showDialog, sideMenuHide, sideMenuShow, } from 'store/view/view.actions';
import { IconRefresh } from '@tabler/icons-react';
import { EXTERNAL_MAIL_STATUSES, TABLE_HEADERS, } from '../ExternalMail.constants';
import { ExternalMailBatchActions } from '../ExternalMailBatchActions/ExternalMailBatchActions';
import { ExternalMailListFilters } from './ExternalMailListFilters';
import { ExternalMailListItem } from './ExternalMailListItem';
import styleIdentifiers from './ExternalMailList.scss';
var styles = classNames.bind(styleIdentifiers);
var EnhancedTable = CustomTable(ExternalMailListItem, ExternalMailListFilters);
export var ExternalMailList = function () {
    var t = useTranslation().t;
    var profile = useProfile();
    var company = useGetCompany();
    var _a = __read(useState({
        page_limit: profile.preferences.itemsPerPage,
        page: 1,
    }), 2), params = _a[0], setParams = _a[1];
    var _b = __read(useState({
        isSelectAllActive: false,
        selectedItems: [],
    }), 2), _c = _b[0], isSelectAllActive = _c.isSelectAllActive, selectedItems = _c.selectedItems, setSelection = _b[1];
    var _d = useLoadExternalMails(params), externalMails = _d.externalMails, isExternalMailsLoading = _d.isExternalMailsLoading, isExternalMailsFetching = _d.isExternalMailsFetching;
    useEffect(function () {
        if (selectedItems.length !== (externalMails === null || externalMails === void 0 ? void 0 : externalMails.data.length)) {
            setSelection(function (currentState) { return (__assign(__assign({}, currentState), { isSelectAllActive: false })); });
        }
    }, [selectedItems.length, externalMails === null || externalMails === void 0 ? void 0 : externalMails.data.length]);
    var emailIds = isSelectAllActive ? [] : selectedItems.map(function (_a) {
        var id = _a.id;
        return id;
    });
    useLayoutEffect(function () {
        setParams(function (currentParams) { return (__assign(__assign({}, currentParams), pick(Object.fromEntries(new URLSearchParams(location.search)), [
            'page',
            'page_limit',
            'sort_by',
            'sort_order',
            'date_before',
            'date_after',
            'from_mail',
            'to_mail',
            'subject',
            'status',
            'debtor_id',
            'external_mailbox_id',
            'account_manager_id',
            'body',
        ]))); });
    }, []);
    var _e = useManuallySyncExternalMails(), manuallySyncExternalEmails = _e.manuallySyncExternalEmails, isManuallySyncExternalEmailsLoading = _e.isManuallySyncExternalEmailsLoading;
    var createExternalMailbox = useCreateExternalMailbox().createExternalMailbox;
    var createExternalMailboxConfiguration = function () {
        return sideMenuShow({
            unmount: true,
            content: (React.createElement(ExternalMailboxSettingsForm, { onSubmit: function (data) {
                    createExternalMailbox(data, {
                        onSuccess: function () {
                            sideMenuHide();
                        },
                    });
                } })),
        });
    };
    var onCheckBoxChange = function (newSelectedItems) {
        setSelection(function (currentState) { return (__assign(__assign({}, currentState), { selectedItems: newSelectedItems })); });
    };
    var onSelectAll = function (isSelected) {
        setSelection(function (state) { return (__assign(__assign({}, state), { isSelectAllActive: isSelected })); });
    };
    var onSortChange = function (newParams) {
        setParams(function (currentParams) { return (__assign(__assign({}, currentParams), pick(newParams, ['sort_by', 'sort_order', 'page_limit', 'page']))); });
    };
    var batchUpdateExternalMails = useBatchUpdateExternalMails().batchUpdateExternalMails;
    var batchActions = function (data) {
        batchUpdateExternalMails(data, {
            onSuccess: function (response) {
                showDialog({
                    id: DialogShowId.CUSTOM,
                    size: DialogShowSize.MEDIUM,
                    title: t(i18nKeys.RESULT),
                    children: React.createElement(ListResModal, { data: response.data.logs }),
                });
                setSelection(function (currentState) { return (__assign(__assign({}, currentState), { selectedItems: [], isSelectAllActive: false })); });
            },
        });
    };
    var handleFilters = function (data) {
        var filters = __assign(__assign({}, pick(params, ['page_limit', 'page'])), data);
        setParams(filters);
        return filters;
    };
    var handleManuallySyncExtEmails = function () {
        manuallySyncExternalEmails();
    };
    var externalMailStatuses = EXTERNAL_MAIL_STATUSES.map(function (_a) {
        var description = _a.description, rest = __rest(_a, ["description"]);
        return (__assign({ description: t(description) }, rest));
    });
    var filtersNames = {
        date_before: {
            description: t(i18nKeys.DATE),
        },
        date_after: {
            description: t(i18nKeys.DATE),
        },
        status: {
            description: t(i18nKeys.TASK.ATTRIBUTES.STATUS),
            value: externalMailStatuses,
        },
        from_mail: {
            description: t(i18nKeys.FROM),
        },
        to_mail: {
            description: t(i18nKeys.TASK.ATTRIBUTES.DEBTOR),
        },
        subject: {
            description: t(i18nKeys.SUBJECT),
        },
        body: {
            description: t(i18nKeys.BODY),
        },
        debtor_id: {
            description: t(i18nKeys.TASK.ATTRIBUTES.DEBTOR),
        },
        external_mailbox_id: {
            description: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.TITLE),
        },
        account_manager_id: {
            description: t(i18nKeys.ACCOUNT_MANAGER),
        },
    };
    var headers = TABLE_HEADERS.map(function (_a) {
        var title = _a.title, rest = __rest(_a, ["title"]);
        return (__assign({ title: t(title) }, rest));
    });
    var _f = company.package, canAddMailbox = _f.can_add_mailbox, canUseExternalMails = _f.can_use_external_mails;
    var tooltipMessage = canUseExternalMails
        ? i18nKeys.SETTINGS.EXTERNAL_MAILBOX.LIMIT_EXCEEDED
        : i18nKeys.NOT_INCLUDED;
    return (React.createElement(EnhancedTable, { title: t(i18nKeys.MAILS.NAV), className: styles('mails-list'), headers: headers, items: (externalMails === null || externalMails === void 0 ? void 0 : externalMails.data) || [], pagination: externalMails === null || externalMails === void 0 ? void 0 : externalMails.metadata.pagination, itemProps: { externalMails: externalMails }, onSortChange: onSortChange, onCheckBoxChange: onCheckBoxChange, setSelectAll: onSelectAll, loading: isExternalMailsLoading || isManuallySyncExternalEmailsLoading, loaded: !(isExternalMailsLoading || isManuallySyncExternalEmailsLoading) && !isExternalMailsFetching, showShadow: true, handleFilters: handleFilters, filtersNames: filtersNames, actions: isSelectAllActive || !isEmpty(selectedItems) ? (React.createElement("div", { className: styles('actions') },
            React.createElement(ExternalMailBatchActions, { emailIds: emailIds, onClick: batchActions }))) : (React.createElement(React.Fragment, null,
            React.createElement(Button, { label: t(i18nKeys.REFETCH), color: ButtonColor.BLUE, isLoading: isManuallySyncExternalEmailsLoading || isExternalMailsLoading, onClick: handleManuallySyncExtEmails, noMargin: true, disabled: !canUseExternalMails, title: canUseExternalMails ? '' : t(i18nKeys.NOT_INCLUDED), noShadow: true, childrenBefore: true },
                React.createElement(IconRefresh, { size: "17px", style: { marginRight: '10px' } })),
            React.createElement(Button, { label: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.ADD), onClick: createExternalMailboxConfiguration, noMargin: true, title: canAddMailbox ? '' : t(tooltipMessage), disabled: !canAddMailbox, noShadow: true }))) }));
};
