import React from 'react';
import { NavLink, Text } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { styles } from './NavMenuItem';
export var NavMenu = function (_a) {
    var menu = _a.menu, setMenu = _a.setMenu;
    if (menu.isHidden)
        return null;
    return (React.createElement(NavLink, { onClick: function () { return setMenu(menu); }, className: styles.navLink, classNames: {
            root: styles.navLinkRoot(),
        }, label: React.createElement(Text, { c: "#4D4D4D" }, menu.label), leftSection: React.createElement(menu.Icon, { color: "#67869B", stroke: "1.7", size: 20 }), rightSection: React.createElement(IconChevronRight, { color: "#4D4D4D", size: 16 }) }));
};
