import { __read } from "tslib";
import React, { useState } from 'react';
import { ExternalMailStatus } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory, useParams } from 'react-router';
import { AccountManagerSelector } from 'shared/components';
import AYSModal from 'shared/components/AYSModal';
import Card from 'shared/components/Card';
import { Icon, IconName } from 'shared/components/Icon';
import { RichTextArea } from 'shared/components/Inputs';
import RecovrLogoLoader from 'shared/components/Loader';
import { useDeleteExternalMail, useGetExternalMail, useLoadExternalMailboxes, useUpdateExternalMail, } from 'shared/hooks';
import { useSendExternalMail } from 'shared/hooks/use-send-external-mails';
import { Button, ButtonColor } from 'shared/io';
import { useGetCompany } from 'shared/utils/selectors';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { ExternalMailAction } from '../ExternalMailActions/ExternalMailAction';
import { ExternalMailActionDebtor } from '../ExternalMailActions/ExternalMailActionDebtor';
import { ExternalMailDetailHead } from './ExternalMailDetailHead';
import { ExternalMailDetailInfoRight } from './ExternalMailDetailInfoRight';
import { ExternalMailReplyAction } from './ExternalMailReplyAction';
import { ExternalMailReplyHead } from './ExternalMailReplyHead';
import styleIdentifiers from './ExternalMailDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export var ExternalMailDetail = function (_a) {
    var emailId = _a.emailId;
    var t = useTranslation().t;
    var history = useHistory();
    var externalMailId = useParams().id;
    var customDomain = useGetCompany().custom_domain;
    var _b = __read(useState(false), 2), isFullDetailOpen = _b[0], setIsFullDetailOpen = _b[1];
    var _c = __read(useState(false), 2), isReplying = _c[0], setReply = _c[1];
    var _d = __read(useState(), 2), replyBody = _d[0], setReplyBody = _d[1];
    var _e = __read(useState(), 2), replySubject = _e[0], setReplySubject = _e[1];
    var sendExternalMail = useSendExternalMail().sendExternalMail;
    var externalMailboxes = useLoadExternalMailboxes().externalMailboxes;
    var _f = useGetExternalMail(externalMailId), externalMail = _f.externalMail, externalMailError = _f.externalMailError, isExternalMailLoading = _f.isExternalMailLoading, refetchExternalMail = _f.refetchExternalMail;
    var updateExternalMail = useUpdateExternalMail().updateExternalMail;
    var deleteExternalMail = useDeleteExternalMail().deleteExternalMail;
    if (isExternalMailLoading && !externalMail)
        return React.createElement(RecovrLogoLoader, null);
    if (externalMailError || !externalMail)
        return React.createElement("span", null, t(i18nKeys.ERROR.ERROR));
    var goToList = function () {
        history.goBack();
    };
    var id = externalMail.id, _g = externalMail.attributes, subject = _g.subject, body = _g.body, status = _g.status, from_mail = _g.from_mail, reply_mails = _g.reply_mails, to_mail = _g.to_mail;
    var replyMails = reply_mails.data;
    var handleAssign = function () {
        return showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.TASK.ACTIONS.ASSIGN),
            children: (React.createElement(ExternalMailAction, { externalMail: externalMail, onSubmit: function (data) {
                    updateExternalMail({ id: id, debtor_id: data.debtor_id }, {
                        onSuccess: function () { return dialogHide(DialogShowId.CUSTOM); },
                    });
                } },
                React.createElement(ExternalMailActionDebtor, null),
                React.createElement("div", { style: { height: '10px' } }),
                React.createElement(AccountManagerSelector, { loadInactive: true, name: "user_id" }))),
        });
    };
    var handleChangeStatus = function () {
        var newStatus = status === ExternalMailStatus.processed
            ? ExternalMailStatus.unprocessed
            : ExternalMailStatus.processed;
        updateExternalMail({ id: id, status: newStatus });
    };
    var handleDelete = function () {
        return showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS.DELETE_EXTERNAL_MAIL), confirmButtonColor: ButtonColor.RED, confirmButtonText: t(i18nKeys.DELETE), onConfirm: function () {
                    return deleteExternalMail({ id: id }, {
                        onSuccess: function () {
                            dialogHide(DialogShowId.CONFIRM);
                            history.push('/mails');
                        },
                    });
                } })),
        });
    };
    var replyToMail = function () {
        sendExternalMail({
            original_mail_id: id,
            body: replyBody,
            subject: replySubject,
            to_mail: [from_mail],
        }, {
            onSuccess: function () {
                refetchExternalMail();
                setReply(false);
            },
        });
    };
    var userSignature = externalMailboxes === null || externalMailboxes === void 0 ? void 0 : externalMailboxes.data[0].attributes.signature;
    var isSameDomain = to_mail.filter(function (mail) { return mail.includes(customDomain); }).length !== 0;
    var replyButtonTitle = function () {
        if (customDomain && isSameDomain)
            return '';
        return t(customDomain ? i18nKeys.CUSTOM_DOMAIN.NO_MATCH : i18nKeys.CUSTOM_DOMAIN.NO_DOMAIN);
    };
    return (React.createElement("article", { className: styles('external-mail-detail') },
        !emailId && (React.createElement("nav", { className: styles('title-line') },
            React.createElement("div", null,
                React.createElement(Icon, { name: IconName.MINIMAL_LEFT, onClick: goToList }),
                t(i18nKeys.MAILS.NAV)),
            React.createElement("div", { className: styles('buttons-wrapper') },
                React.createElement(Button, { label: t(i18nKeys.TASK.ACTIONS.ASSIGN), onClick: handleAssign, color: ButtonColor.BLUE, iconLeft: IconName.USER, noMargin: true }),
                React.createElement(Button, { label: t(i18nKeys.EXTERNAL_MAIL.ACTIONS["".concat(status === ExternalMailStatus.processed ? 'UN' : '', "PROCESS")]), onClick: handleChangeStatus, color: ButtonColor.BLUE, iconLeft: IconName[status === ExternalMailStatus.processed ? 'SIMPLE_REMOVE' : 'CHECK'], noMargin: true }),
                React.createElement(Button, { label: (React.createElement(Icon, { name: IconName.TRASH_SIMPLE })), onClick: handleDelete, color: ButtonColor.RED, noMargin: true }),
                React.createElement("a", { href: "#reply-to-mail" },
                    React.createElement(Button, { label: t(i18nKeys.REPLY), onClick: function () {
                            setReply(true);
                            setReplySubject("RE: ".concat(subject));
                        }, color: ButtonColor.GREEN, iconLeft: IconName.SEND, disabled: !customDomain || !isSameDomain, title: replyButtonTitle(), noMargin: true }))))),
        React.createElement("div", null,
            React.createElement(Card, { className: styles('mail'), title: subject, subtitle: React.createElement(ExternalMailDetailHead, { externalMail: externalMail, fullDetail: isFullDetailOpen }), infosRight: React.createElement(ExternalMailDetailInfoRight, { externalMail: externalMail, setFullDetail: function () { return setIsFullDetailOpen(!isFullDetailOpen); } }) },
                React.createElement("iframe", { title: "email", srcDoc: body })),
            replyMails.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement("h3", { className: styles('centered-text-with-lines') },
                    React.createElement("span", null, t(i18nKeys.REPLIES))),
                replyMails.map(function (replyMail) { return (React.createElement(Card, { className: styles('mail', 'mail-reply'), key: replyMail.id, title: replyMail.attributes.subject, subtitle: React.createElement(ExternalMailDetailHead, { externalMail: replyMail }), infosRight: React.createElement(ExternalMailDetailInfoRight, { externalMail: replyMail, isReply: true }) },
                    React.createElement("p", { className: styles('reply-body') }, replyMail.attributes.body))); }))),
            isReplying && (React.createElement("div", { id: "reply-to-mail", className: styles('reply-to-mail') },
                React.createElement(Card, { className: styles('mail', 'reply-to-card'), title: t(i18nKeys.REPLY), subtitle: React.createElement(ExternalMailReplyHead, { externalMail: externalMail, originalSubject: subject, subject: replySubject, setSubject: setReplySubject }), infosRight: React.createElement(ExternalMailReplyAction, { disabled: !(replyBody && replySubject), onSubmit: replyToMail }) },
                    React.createElement(RichTextArea, { content: "".concat(replyBody !== null && replyBody !== void 0 ? replyBody : '').concat(userSignature ? "<br/><br/>".concat(userSignature) : ''), onChange: function (content) {
                            return setReplyBody(content);
                        } })))))));
};
