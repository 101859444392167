import { __read } from "tslib";
import React, { useState } from 'react';
import { useCreateTask } from 'api';
import { useSetAtom } from 'jotai';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import EditClient from 'shared/action-component/EditClient';
import ImportClientsForm from 'shared/forms/ImportClientsForm';
import InvoiceImportForm from 'shared/forms/InvoiceImportForm';
import { ublProgressAtom } from 'shared/forms/InvoiceImportForm/InvoiceImportForm';
import ProductForm from 'shared/forms/ProductForm';
import { useIsMobile } from 'shared/hooks/utils';
import { isConnectorActive } from 'shared/utils/connector';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { productActions } from 'store/product/product.actions';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { Button, Menu, rem, Text } from '@mantine/core';
import { IconArrowRight, IconPlus, IconX } from '@tabler/icons-react';
import { TaskForm } from './Tasks';
var buttonStyles = function (isOpen) { return ({
    root: {
        width: '100%',
        color: '#666666',
        backgroundColor: 'white',
        height: 40,
        outline: isOpen ? '2px solid #DEDEDE' : '1px solid #DEDEDE',
        outlineOffset: isOpen ? '2px' : '0px',
        transition: 'outline-offset 0.1s ease, outline 0.1s ease',
    },
    label: {
        flexGrow: 1,
    },
}); };
var dropdownStyles = {
    dropdown: {
        width: rem(200),
        borderRadius: 8,
        borderColor: '#EDEDED',
        marginLeft: 15,
        boxShadow: '4px 8px 12px -2px rgba(0, 0, 0, 0.12)',
    },
};
export var QuickActionMenu = function (_a) {
    var t = useTranslation().t;
    var history = useHistory();
    var isMobile = useIsMobile();
    var _b = __read(useState(false), 2), isOpen = _b[0], setIsOpened = _b[1];
    var hasActiveConnector = isConnectorActive();
    var setUblProgress = useSetAtom(ublProgressAtom);
    var createTask = useCreateTask().mutate;
    var newInvoice = function () {
        invoiceActions.detailReset();
        sideMenuHide();
        history.push('/invoices/create');
    };
    var newCreditNote = function () {
        creditNoteActions.detailReset();
        sideMenuHide();
        history.push('/credit-notes/create');
    };
    var addClient = function () {
        EditClient({});
    };
    var importClient = function () {
        setUblProgress({ active: false });
        sideMenuShow({ unmount: true, content: React.createElement(ImportClientsForm, null) });
    };
    var importInvoice = function () {
        setUblProgress({ active: false });
        sideMenuShow({ unmount: true, content: React.createElement(InvoiceImportForm, null) });
    };
    var addProduct = function () {
        sideMenuShow({
            unmount: true,
            content: (React.createElement(ProductForm, { onSubmit: function (values) {
                    productActions.add({ data: values, callback: sideMenuHide });
                } })),
        });
    };
    var addTask = function () {
        sideMenuShow({
            unmount: true,
            content: (React.createElement(TaskForm, { onSubmit: function (data) { return createTask({ data: data }, { onSuccess: sideMenuHide() }); } })),
        });
    };
    return (React.createElement(Menu, { opened: isOpen, onChange: setIsOpened, position: isMobile ? 'top' : 'right-end' },
        React.createElement(Menu.Target, null,
            React.createElement(Button, { justify: "space-between", radius: 8, styles: buttonStyles(isOpen), leftSection: React.createElement(IconPlus, { style: { width: rem(16), height: rem(16), color: '#666666' } }), rightSection: isOpen ? (React.createElement(IconX, { style: { width: rem(14), height: rem(14) } })) : (React.createElement(IconArrowRight, { style: { width: rem(14), height: rem(14) } })) },
                React.createElement(Text, { py: 12 },
                    " ",
                    t(i18nKeys.NAV.QUICK_ACTION.LABEL),
                    " "))),
        React.createElement(Menu.Dropdown, { styles: dropdownStyles },
            React.createElement(Menu.Label, null, t(i18nKeys.NAV.QUICK_ACTION.ADD_LABEL)),
            !hasActiveConnector && (React.createElement(React.Fragment, null,
                React.createElement(Menu.Item, { onClick: newInvoice }, t(i18nKeys.NAV.QUICK_ACTION.INVOICE)),
                React.createElement(Menu.Item, { onClick: newCreditNote }, t(i18nKeys.NAV.QUICK_ACTION.CREDIT_NOTE)),
                React.createElement(Menu.Item, { onClick: addProduct }, t(i18nKeys.NAV.QUICK_ACTION.PRODUCT)))),
            React.createElement(Menu.Item, { onClick: addClient }, t(i18nKeys.NAV.QUICK_ACTION.CUSTOMER)),
            React.createElement(Menu.Item, { onClick: addTask }, t(i18nKeys.NAV.QUICK_ACTION.TASK)),
            React.createElement(Menu.Label, null, t(i18nKeys.NAV.QUICK_ACTION.IMPORT_LABEL)),
            !hasActiveConnector && (React.createElement(Menu.Item, { onClick: importInvoice }, t(i18nKeys.NAV.QUICK_ACTION.IMPORT_INVOICES))),
            React.createElement(Menu.Item, { onClick: importClient }, t(i18nKeys.NAV.QUICK_ACTION.IMPORT_CUSTOMERS)))));
};
