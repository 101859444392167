import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { i18nKeys, useTranslation } from 'locales/';
import { useLoadViewsV2 } from 'shared/hooks';
import { useScrollShadow } from 'shared/hooks/ui/use-scroll-shadow';
import { Box, Button, Group, LoadingOverlay, ScrollArea, Space, Stack } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useCreateView } from '../../../hooks/use-create-view';
import { ViewSettings } from './components';
export function ViewSettingsModal(_a) {
    var resourceType = _a.resourceType;
    var t = useTranslation().t;
    var _b = useLoadViewsV2({ resourceType: resourceType }), views = _b.views, isViewsLoading = _b.isViewsLoading;
    var _c = useCreateView(), createView = _c.createView, isCreateViewLoading = _c.isCreateViewLoading;
    var _d = useScrollShadow(views === null || views === void 0 ? void 0 : views.length, 'dark'), topShadow = _d.topShadow, bottomShadow = _d.bottomShadow, scrollableRef = _d.scrollableRef;
    var _e = __read(useState(false), 2), shouldScrollToNewElement = _e[0], setShouldScrollToNewElement = _e[1];
    useEffect(function () {
        var _a;
        if (shouldScrollToNewElement) {
            (_a = scrollableRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
                top: scrollableRef.current.scrollHeight,
                behavior: 'smooth',
            });
            setShouldScrollToNewElement(false);
        }
    }, [views === null || views === void 0 ? void 0 : views.length]);
    return (React.createElement(Stack, null,
        React.createElement(ScrollArea, { px: "sm", viewportRef: scrollableRef, viewportProps: { style: { maxHeight: 'min(900px, calc(100vh - 250px))' } }, bg: "gray.2", style: function (theme) { return ({ borderRadius: theme.radius.sm }); } },
            topShadow,
            bottomShadow,
            isViewsLoading ? (React.createElement(Box, { h: 450 },
                React.createElement(LoadingOverlay, { visible: true, overlayProps: { backgroundOpacity: 0 } }))) : (React.createElement(React.Fragment, null,
                React.createElement(Space, { h: "sm" }),
                React.createElement(Stack, null, views === null || views === void 0 ? void 0 : views.map(function (view) {
                    return (React.createElement(ViewSettings, { key: view.id, view: view, onDuplicate: function () { return setShouldScrollToNewElement(true); } }));
                })),
                React.createElement(Space, { h: "sm" })))),
        React.createElement(Group, { justify: "end" },
            React.createElement(Button, { disabled: isViewsLoading, loading: isCreateViewLoading, rightSection: React.createElement(IconPlus, { strokeWidth: 1.5, size: 20 }), onClick: function () {
                    createView({ resourceType: resourceType }, { onSuccess: function () { return setShouldScrollToNewElement(true); } });
                } }, t(i18nKeys.VIEWS.SETTINGS.NEW)))));
}
