import { __assign } from "tslib";
import React from 'react';
import { SetReasonForm } from 'app/Private/Reasons/SetReasonForm/SetReasonForm';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import AYSModal from 'shared/components/AYSModal';
import { useIsMobile } from 'shared/hooks/utils';
import { Dropdown } from 'shared/io';
import { isConnectorActive } from 'shared/utils/connector';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, sideMenuHide, } from 'store/view/view.actions';
import styleIdentifiers from './markedInvoiceActions.scss';
var styles = classNames.bind(styleIdentifiers);
var Flags;
(function (Flags) {
    Flags["DISPUTE"] = "dispute";
    Flags["LOSE"] = "lose";
    Flags["PAUSE"] = "pause";
})(Flags || (Flags = {}));
export default function MarkedInvoiceActions(_a) {
    var invoice = _a.invoice, element = _a.element;
    var t = useTranslation().t;
    var isMobile = useIsMobile();
    var checkIfInactive = function (type) { return !(invoice === null || invoice === void 0 ? void 0 : invoice.actions.includes(type)); };
    var hasActiveConnector = isConnectorActive();
    var reloadInvoice = function () {
        invoiceActions.info({
            id: invoice.id,
            noReset: true,
            noLoading: true,
        });
    };
    var handlePause = function (values) {
        invoiceActions.actionsPause({
            id: invoice.id,
            data: values,
            callback: function () {
                reloadInvoice();
                dialogHide(DialogShowId.CUSTOM);
            },
        });
    };
    var handleLost = function (values) {
        invoiceActions.actionsLost({
            id: invoice.id,
            data: values,
            callback: function () {
                reloadInvoice();
                dialogHide(DialogShowId.CUSTOM);
            },
        });
    };
    var handleDisputed = function (values) {
        invoiceActions.actionsDisputed({
            id: invoice.id,
            data: __assign(__assign({}, values), { dispute: true }),
            callback: function () {
                reloadInvoice();
                dialogHide(DialogShowId.CUSTOM);
            },
        });
    };
    var toggleClose = function () {
        if (isMobile)
            sideMenuHide();
        invoiceActions.actionsPause({
            id: invoice.id,
            callback: reloadInvoice,
        });
    };
    var paid = function () {
        if (checkIfInactive('mark_as_paid'))
            return;
        if (isMobile)
            sideMenuHide();
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            keepMountOnExit: true,
            title: t(i18nKeys.INVOICE.PAID),
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS.INVOICE_ACTION_PAID), onConfirm: function () {
                    invoiceActions.actionsPaid({
                        id: invoice.id,
                        callback: reloadInvoice,
                    });
                } })),
        });
    };
    var disputed = function () {
        if (isMobile)
            sideMenuHide();
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.INVOICE.DISPUTED),
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS.INVOICE_ACTION_UNDISPUTED), onConfirm: function () {
                    invoiceActions.actionsDisputed({
                        id: invoice.id,
                        data: {
                            dispute: false,
                        },
                        actionFailure: function () {
                            dialogHide(DialogShowId.CUSTOM);
                        },
                        callback: function () {
                            reloadInvoice();
                            dialogHide(DialogShowId.CUSTOM);
                        },
                    });
                } })),
        });
    };
    var handleFlag = function (actionType) {
        switch (actionType) {
            case Flags.PAUSE:
                return handlePause;
            case Flags.LOSE:
                return handleLost;
            default:
                return handleDisputed;
        }
    };
    var flag = function (actionType, title) {
        if (isMobile)
            sideMenuHide();
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(title),
            children: React.createElement(SetReasonForm, { onSubmit: handleFlag(actionType), reasonType: actionType }),
        });
    };
    return (React.createElement(Dropdown, { className: styles('marked-invoice-actions'), contentClass: styles('content-class'), selectorContent: element, toggleContent: true, sideMenuInMobile: true, sideMenuTitle: t(i18nKeys.INVOICE.CHANGE_STATUS) },
        !hasActiveConnector && (React.createElement("div", { className: styles('dropdown-item', checkIfInactive('mark_as_paid') && 'disabled'), onClick: paid },
            React.createElement("div", { className: styles('item-content') },
                React.createElement("div", { className: styles('header') }, t(i18nKeys.INVOICE.MARK_INVOICE_AS_PAID))))),
        React.createElement("div", { className: styles('dropdown-item', checkIfInactive('mark_as_lost') && 'disabled'), onClick: function () { return flag(Flags.LOSE, i18nKeys.INVOICE.INVOICE_LOST); } },
            React.createElement("div", { className: styles('item-content') },
                React.createElement("div", { className: styles('header') }, t(i18nKeys.INVOICE.MARK_INVOICE_AS_LOST)))),
        checkIfInactive('undispute') ? (React.createElement("div", { className: styles('dropdown-item', checkIfInactive(Flags.DISPUTE) && 'disabled'), onClick: function () { return flag(Flags.DISPUTE, i18nKeys.INVOICE.DISPUTED); } },
            React.createElement("div", { className: styles('item-content') },
                React.createElement("div", { className: styles('header') }, t(i18nKeys.INVOICE.MARK_AS_DISPUTED))))) : (React.createElement("div", { className: styles('dropdown-item'), onClick: disputed },
            React.createElement("div", { className: styles('item-content') },
                React.createElement("div", { className: styles('header') }, t(i18nKeys.INVOICE.UNDO_DISPUTED))))),
        !checkIfInactive('pause') && (React.createElement("div", { className: styles('dropdown-item'), onClick: function () { return flag('pause', i18nKeys.CLIENT.PAUSE); } },
            React.createElement("div", { className: styles('item-content') },
                React.createElement("div", { className: styles('header') }, t(i18nKeys.INVOICE.PAUSE_THE_FOLLOW_UP))))),
        !checkIfInactive('unpause') && (React.createElement("div", { className: styles('dropdown-item'), onClick: toggleClose },
            React.createElement("div", { className: styles('item-content') },
                React.createElement("div", { className: styles('header') }, t(i18nKeys.INVOICE.RESUME_FOLLUW_UP)))))));
}
