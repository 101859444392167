import { __assign } from "tslib";
import React from 'react';
import { isDefined } from 'remeda';
import { Badge, Group, Text } from '@mantine/core';
var badgeStyles = {
    notification: {
        root: {
            padding: '11px 6px',
        },
    },
    tag: {
        root: {
            borderColor: '#CCD7DE',
            borderWidth: 1,
            padding: '11px 6px',
        },
    },
};
export var MenuItemBadges = function (_a) {
    var unreadCount = _a.unreadCount, tagLabel = _a.tagLabel;
    if (!isDefined(unreadCount) && !isDefined(tagLabel))
        return null;
    return (React.createElement(Group, { gap: 5 },
        isDefined(unreadCount) && unreadCount > 0 && (React.createElement(Badge, { styles: badgeStyles.notification, size: "sm", color: "#CCD7DE", radius: 6 },
            React.createElement(Text, { fw: 500, fz: 12, c: "#345D79" }, unreadCount))),
        isDefined(tagLabel) && (React.createElement(Badge, { styles: __assign(__assign({}, badgeStyles.tag), { label: { textTransform: 'capitalize' } }), color: "#F2F5F7", size: "sm", radius: 6 },
            React.createElement(Text, { fw: 500, fz: 12, c: "#345D79" }, tagLabel)))));
};
