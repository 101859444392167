import { __read, __values } from "tslib";
import { isNullish } from 'remeda';
import { LOCALES } from 'types';
export function getTranslationForKey(key, params, localeTree) {
    var rawText = getTextForKey(key, localeTree);
    if (isNullish(params))
        return rawText;
    return replaceParamsInText(rawText, params);
}
export function getTextForKey(key, localeTree) {
    var e_1, _a;
    var fragments = key.toString().split('.');
    var currentElement = localeTree;
    try {
        for (var fragments_1 = __values(fragments), fragments_1_1 = fragments_1.next(); !fragments_1_1.done; fragments_1_1 = fragments_1.next()) {
            var fragment = fragments_1_1.value;
            currentElement = currentElement[fragment];
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (fragments_1_1 && !fragments_1_1.done && (_a = fragments_1.return)) _a.call(fragments_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return currentElement;
}
export function replaceParamsInText(text, params) {
    var newText = text;
    Object.keys(params).forEach(function (paramKey) {
        newText = newText.replace("${".concat(paramKey, "}"), String(params[paramKey]));
    });
    return newText;
}
export function getPreferredBrowserLanguageOrDefault() {
    var _a;
    var navigatorLanguage = navigator.language;
    var parsedNavigatorLocale = '';
    if (navigatorLanguage.includes('-'))
        _a = __read(navigatorLanguage.split('-'), 1), parsedNavigatorLocale = _a[0];
    if (navigatorLanguage.length === 2)
        parsedNavigatorLocale = navigatorLanguage;
    if (parsedNavigatorLocale in LOCALES)
        return parsedNavigatorLocale;
    return 'fr';
}
