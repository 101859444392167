import { __awaiter, __generator } from "tslib";
import { jotaiStore } from 'init/jotai';
import { useSetAtom } from 'jotai';
import { i18nKeys } from 'locales';
import { currentLanguageAtom, translationStateAtom } from 'locales/logic';
import { isDefined } from 'remeda';
import { LocaleSchema } from 'types';
import { z, ZodIssueCode } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult, useAxiosInstance } from './utils';
var MAX_FILE_SIZE = 5000000;
var ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];
// https://github.com/colinhacks/zod/blob/master/ERROR_HANDLING.md
z.setErrorMap(function (issue, ctx) {
    var t = jotaiStore.get(translationStateAtom).t;
    if (issue.code === ZodIssueCode.invalid_string && issue.validation === 'email') {
        return { message: t(i18nKeys.ERROR.INVALID_EMAIL) };
    }
    if (issue.code === ZodIssueCode.too_small && issue.minimum === 1) {
        return { message: t(i18nKeys.ERROR.FIELD_REQUIRED) };
    }
    return { message: ctx.defaultError };
});
var avatarSchema = z
    .any()
    .refine(function (file) { return file.size <= MAX_FILE_SIZE; }, 'Max image size is 5MB.')
    .refine(function (file) { return ACCEPTED_IMAGE_TYPES.includes(file.type); }, 'Invalid image type.')
    .nullable();
export var UpdateProfileFormSchema = z
    .object({
    avatarUrl: z.string().url().nullable(),
    email: z.string().email(),
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    locale: LocaleSchema,
    pageLimit: z.string().transform(function (val) { return Number(val); }),
    receiveDailyEmails: z.boolean(),
    receiveNotificationEmails: z.boolean(),
    receiveWeeklyEmails: z.boolean(),
    signature: z.string().nullable(),
    avatar: avatarSchema,
})
    .partial();
function createFormData(data) {
    var formData = new FormData();
    var parsedData = UpdateProfileFormSchema.parse(data);
    var hasAvatar = 'avatar' in parsedData;
    if (hasAvatar) {
        var avatar = parsedData.avatar;
        formData.append('avatar', avatar instanceof File ? avatar : '');
    }
    return { formData: formData, hasAvatar: hasAvatar };
}
export function updateProfileFn(axiosInstance, data) {
    return __awaiter(this, void 0, void 0, function () {
        var instance, _a, formData, hasAvatar;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _b.sent();
                    _a = createFormData(data), formData = _a.formData, hasAvatar = _a.hasAvatar;
                    if (hasAvatar)
                        return [2 /*return*/, instance.put('/profile', formData)];
                    return [2 /*return*/, instance.put('/profile', UpdateProfileFormSchema.parse(data))];
            }
        });
    });
}
export function useUpdateProfile() {
    var client = useQueryClient();
    var axiosInstance = useAxiosInstance();
    var setCurrentLocale = useSetAtom(currentLanguageAtom);
    var mutation = useMutation({
        mutationFn: function (data) { return updateProfileFn(axiosInstance, data); },
        onSuccess: function (_, context) {
            if (isDefined(context.locale))
                setCurrentLocale(context.locale);
            var t = jotaiStore.get(translationStateAtom).t;
            notifications.show({
                message: t(i18nKeys.SETTINGS.PROFILE.SUCCESSFULLY_UPDATE),
                color: 'green',
            });
            client.invalidateQueries({
                queryKey: ['profile'],
            });
        },
        onError: function (error) {
            var _a;
            (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error_message.forEach(function (message) {
                notifications.show({
                    title: 'Error',
                    color: 'red',
                    message: message,
                });
            });
        },
    });
    return addActionNameToMutationResult('updateProfile', mutation);
}
