import { __awaiter, __generator } from "tslib";
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { useAxiosInstance } from './utils';
export function updateViewFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance;
        var viewId = _b.viewId, name = _b.name;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [2 /*return*/, instance.put("views/".concat(viewId), { name: name })];
            }
        });
    });
}
export var useUpdateView = function () {
    var client = useQueryClient();
    var axiosInstance = useAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (variables) { return updateViewFn(axiosInstance, variables); },
        onSuccess: function (response, _a) {
            var resourceType = _a.resourceType;
            client.invalidateQueries({ queryKey: ['views', resourceType] });
        },
        onError: function (error, _a) {
            var _b, _c, _d, _e;
            var resourceType = _a.resourceType;
            notifications.show({
                message: (_e = (_c = (_b = error.response) === null || _b === void 0 ? void 0 : _b.data.error_message) !== null && _c !== void 0 ? _c : (_d = error.response) === null || _d === void 0 ? void 0 : _d.data.errors[0]) !== null && _e !== void 0 ? _e : error.message,
                color: 'red',
            });
            client.invalidateQueries({ queryKey: ['views', resourceType] });
        },
    });
    return addActionNameToMutationResult('updateView', mutation);
};
