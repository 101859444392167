import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { useLegacyAxiosInstance } from './utils';
var updateExternalMailVariablesSchema = z.object({
    id: z.string(),
    status: z.enum(['processed', 'unprocessed']).optional(),
    debtor_id: z.string().optional(),
    user_id: z
        .string()
        .optional()
        .describe("This is the associated account manager's id. Rename when migrating to v2"),
});
export function updateExternalMailFn(axiosInstance, data) {
    return __awaiter(this, void 0, void 0, function () {
        var instance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [2 /*return*/, instance.put("external_mails/".concat(data.id), data)];
            }
        });
    });
}
export function useUpdateExternalMail() {
    var client = useQueryClient();
    var axiosInstance = useLegacyAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (variables) { return updateExternalMailFn(axiosInstance, variables); },
        onSuccess: function () {
            client.invalidateQueries({ queryKey: ['externalMails'] });
        },
        onError: function (error) {
            var _a, _b, _c, _d;
            notifications.show({
                message: (_d = (_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error_message) !== null && _b !== void 0 ? _b : (_c = error.response) === null || _c === void 0 ? void 0 : _c.data.errors[0]) !== null && _d !== void 0 ? _d : error.message,
                color: 'red',
            });
        },
    });
    return addActionNameToMutationResult('updateExternalMail', mutation);
}
