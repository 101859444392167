import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { get } from 'lodash-es';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import FormSection from 'shared/forms/FormSection';
import { Input } from 'shared/io';
import { email } from 'shared/utils/validation';
import styleIdentifiers from './ContactPersonForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var ContactPersonFormEmails = function (_a) {
    var t = useTranslation().t;
    var _b = useFormContext(), register = _b.register, errors = _b.formState.errors;
    var _c = useFieldArray({
        name: 'emails',
    }), fields = _c.fields, append = _c.append, remove = _c.remove;
    useEffect(function () {
        if (!fields.length) {
            append('');
        }
    }, [fields, append]);
    return (React.createElement(FormSection, { title: t(i18nKeys.EMAILS), onAdd: function () {
            append('');
        } },
        React.createElement("div", { className: styles('emails') }, fields.map(function (item, index) { return (React.createElement("div", { key: item.id, className: styles('line-with-delete') },
            React.createElement(Input, { label: t(i18nKeys.EMAIL), type: "email", className: styles('input'), errorMessage: get(errors, "emails.".concat(index)), register: register("emails.".concat(index), {
                    validate: email,
                    required: true,
                }), withBorder: true }),
            fields.length > 1 && (React.createElement(Icon, { name: IconName.TRASH_SIMPLE, className: styles('delete'), onClick: function () { return remove(index); } })))); }))));
};
