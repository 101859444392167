import { __makeTemplateObject, __read } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import { css, cx } from '@emotion/css';
export function useScrollShadow(dependency, theme) {
    var _a, _b;
    if (theme === void 0) { theme = 'light'; }
    var scrollableRef = useRef(null);
    var _c = __read(useState(false), 2), shouldShowTopShadow = _c[0], setShowTopShadow = _c[1];
    var _d = __read(useState(true), 2), shouldShowBottomShadow = _d[0], setShowBottomShadow = _d[1];
    useEffect(function () {
        var scrollableElement = scrollableRef.current;
        var handleScroll = function () {
            var _a = scrollableElement, scrollTop = _a.scrollTop, scrollHeight = _a.scrollHeight, clientHeight = _a.clientHeight;
            setShowTopShadow(scrollTop > 0);
            // The scroll sometimes does not bottom out full and lands on a decimal value so we ceil
            setShowBottomShadow(Math.ceil(scrollTop) < scrollHeight - clientHeight);
        };
        handleScroll(); // To avoid first render glitch when content is not scrollable
        scrollableElement === null || scrollableElement === void 0 ? void 0 : scrollableElement.addEventListener('scroll', handleScroll);
        return function () {
            scrollableElement === null || scrollableElement === void 0 ? void 0 : scrollableElement.removeEventListener('scroll', handleScroll);
        };
    }, [dependency]);
    var topShadow = (React.createElement("div", { className: cx(styles.shadow.base, (_a = {},
            _a[styles.shadow.hidden] = !shouldShowTopShadow,
            _a[styles.shadow.darkShadow] = theme === 'dark',
            _a)) }));
    var bottomShadow = (React.createElement("div", { className: cx(styles.shadow.base, styles.shadow.bottom, (_b = {},
            _b[styles.shadow.hidden] = !shouldShowBottomShadow,
            _b[styles.shadow.darkShadow] = theme === 'dark',
            _b)) }));
    return { topShadow: topShadow, bottomShadow: bottomShadow, scrollableRef: scrollableRef };
}
var styles = {
    shadow: {
        base: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      position: absolute;\n      top: 0;\n      left: 0;\n      z-index: 1;\n      width: 100%;\n      height: 60px;\n      background: linear-gradient(\n        180deg,\n        rgba(255, 255, 255, 0.7) 20%,\n        rgba(255, 255, 255, 0.5),\n        transparent\n      );\n      transition: opacity 0.3s;\n      pointer-events: none;\n    "], ["\n      position: absolute;\n      top: 0;\n      left: 0;\n      z-index: 1;\n      width: 100%;\n      height: 60px;\n      background: linear-gradient(\n        180deg,\n        rgba(255, 255, 255, 0.7) 20%,\n        rgba(255, 255, 255, 0.5),\n        transparent\n      );\n      transition: opacity 0.3s;\n      pointer-events: none;\n    "]))),
        darkShadow: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      background: linear-gradient(180deg, rgba(200, 200, 200, 0.3), transparent);\n    "], ["\n      background: linear-gradient(180deg, rgba(200, 200, 200, 0.3), transparent);\n    "]))),
        bottom: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      top: unset;\n      bottom: 0;\n      transform: rotate(180deg);\n    "], ["\n      top: unset;\n      bottom: 0;\n      transform: rotate(180deg);\n    "]))),
        hidden: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      opacity: 0;\n    "], ["\n      opacity: 0;\n    "]))),
    },
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
