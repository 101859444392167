import { __assign } from "tslib";
import { treatActivity, treatInvoice } from 'shared/serializer';
import { removeAttributes } from 'shared/utils/view';
import { creditNoteConstants as events } from 'store/creditNote/creditnote.actions';
import { baseReducerData, baseReducerListPage, handlePageRequest, handlePageResponse, handleRequest, handleReset, handleResponse, } from 'store/reducers';
export var initialState = {
    list: baseReducerListPage,
    invoiceSearch: null,
    current: baseReducerData,
    currentActivity: baseReducerData,
    isLoading: false,
};
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case events.send.request:
        case events.updateOriginalFile.request:
            return __assign(__assign({}, state), { isLoading: true });
        case events.send.result:
        case events.updateOriginalFile.result:
            return __assign(__assign({}, state), { isLoading: false });
        case events.listPage.request:
            return handlePageRequest(state, action, 'list');
        case events.listPage.result:
            return handlePageResponse(state, action, 'list', removeAttributes);
        case events.detail.request:
            return handleRequest(state, action, 'current', true);
        case events.detail.result:
            return handleResponse(state, action, 'current', treatInvoice);
        case events.detail.reset:
            return handleReset(state, action, 'current');
        case events.invoiceSearch.request:
            return handleRequest(state, action, 'invoiceSearch');
        case events.invoiceSearch.result:
            return handleResponse(state, action, 'invoiceSearch');
        case events.activity.request:
            return handleRequest(state, action, 'currentActivity', true);
        case events.activity.result:
            return handleResponse(state, action, 'currentActivity', treatActivity);
        default:
            return state;
    }
};
export default reducer;
