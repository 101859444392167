import { __assign } from "tslib";
import { treatTableViews } from 'shared/serializer';
import { appConstants as events } from 'store/app/app.actions';
import { handleResponse } from 'store/reducers';
export var initialState = {
    constants: null,
    viewsByResource: {},
};
var reducer = function (state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case events.getViews.result:
            return __assign(__assign({}, state), { viewsByResource: __assign(__assign({}, state.viewsByResource), (_a = {}, _a[action.requestData.view_type] = treatTableViews(action.payload.data).slice(), _a)) });
        case events.constants.result:
            return handleResponse(state, action, 'constants');
        default:
            return state;
    }
};
export default reducer;
