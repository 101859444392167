import { __assign, __awaiter, __generator } from "tslib";
import { omit } from 'remeda';
import { string, z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';
export var LightUserSchema = z
    .object({
    email: z.string().email(),
    firstName: string().nonempty(),
    id: z.number(),
    isActive: z.boolean(),
    lastName: string().nonempty(),
    profilePicture: z.string().url().nullable(),
})
    .transform(function (data) { return (__assign(__assign({}, omit(data, ['firstName', 'lastName'])), { name: {
        full: "".concat(data.firstName, " ").concat(data.lastName),
        first: data.firstName,
        last: data.lastName,
        initials: "".concat(data.firstName[0]).concat(data.lastName[0]),
    } })); });
var LightUsersSchema = z.array(LightUserSchema);
export function loadLightUsersQueryFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [4 /*yield*/, instance.get("/users?".concat(new URLSearchParams({
                            fullResponse: 'false',
                            showInactive: queryKey[1].loadInactiveUsers.toString(),
                        }).toString()))];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, LightUsersSchema.parse(data)];
            }
        });
    });
}
export var useLoadLightUsers = function (loadInactiveUsers) {
    if (loadInactiveUsers === void 0) { loadInactiveUsers = false; }
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['users', { loadInactiveUsers: loadInactiveUsers }],
        queryFn: function (context) { return loadLightUsersQueryFn(axiosInstance, context); },
    });
    return addResourceNameToQueryResult('users', queryResult);
};
