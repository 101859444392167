import dayjs from 'dayjs';
import { atom, useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { atomEffect } from 'jotai-effect';
import moment from 'moment';
import { getPreferredBrowserLanguageOrDefault, getTranslationForKey } from './utils';
export * from './types';
export var currentLanguageAtom = atomWithStorage('lastUsedLanguage', getPreferredBrowserLanguageOrDefault());
var propagateLanguageChangeEffect = atomEffect(function (get) {
    var currentLanguage = get(currentLanguageAtom);
    moment.locale(currentLanguage);
    dayjs.locale(currentLanguage);
});
var translationsObjectAtom = atom(function (get) {
    get(propagateLanguageChangeEffect);
    var currentLanguage = get(currentLanguageAtom);
    return loadLanguage(currentLanguage);
});
var translateFunctionAtom = atom(function (get) {
    var translationsObject = get(translationsObjectAtom);
    return function (key, params) { return getTranslationForKey(key, params, translationsObject); };
});
export var translationStateAtom = atom(function (get) {
    var currentLang = get(currentLanguageAtom);
    var t = get(translateFunctionAtom);
    return { currentLang: currentLang, t: t };
});
export function useTranslation() {
    var translationState = useAtomValue(translationStateAtom);
    return translationState;
}
function loadLanguage(language) {
    // eslint-disable-next-line global-require, import/no-dynamic-require
    return require("../source/".concat(language, ".i18n.json"));
}
