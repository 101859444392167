import { __read } from "tslib";
import React, { useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { useSelector } from 'react-redux';
import { partition } from 'remeda';
import { useIsMobile } from 'shared/hooks/utils';
import { getEnvIcon } from 'shared/utils/environment';
import { styled } from '@stitches/react';
var Actions = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
});
var Title = styled('h1', {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginBottom: 'unset',
});
var TitleActions = function (_a) {
    var children = _a.children;
    if (children == null)
        return null;
    return React.createElement(Actions, null, children);
};
TitleActions.displayName = 'TitleActions';
var Header = styled('div', {
    marginBottom: '20px',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    variants: {
        isMobile: {
            true: {
                height: 'unset',
                flexDirection: 'column',
                alignItems: 'flex-start',
            },
        },
    },
});
export var PageTitle = function (_a) {
    var _b;
    var children = _a.children;
    var isMobile = useIsMobile();
    var companyName = (_b = useSelector(function (state) { var _a, _b; return (_b = (_a = state.account.company) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.name; })) !== null && _b !== void 0 ? _b : '';
    var childrenArray = React.Children.toArray(children);
    var _c = __read(partition(childrenArray, function (child) {
        return React.isValidElement(child) &&
            typeof child !== 'string' &&
            typeof child.type !== 'string' &&
            // @ts-ignore
            child.type.displayName === 'TitleActions';
    }), 2), actions = _c[0], title = _c[1];
    useEffect(function () {
        // Remove any icons or other elements from the title we display in the tab
        var titleString = title.filter(function (e) { return typeof e === 'string'; }).join(' ');
        document.title = "".concat(getEnvIcon()).concat(companyName, " - ").concat(titleString);
        return function cleanup() {
            document.title = "".concat(getEnvIcon()).concat(companyName !== null && companyName !== void 0 ? companyName : 'Recovr');
        };
    }, [title, companyName]);
    if (actions.length > 1) {
        console.error('PageTitle can only have one TitleActions child');
        return null;
    }
    return (React.createElement(Header, { isMobile: isMobile },
        React.createElement(Title, null, title),
        !isEmpty(actions) ? React.createElement(Actions, null, actions) : null));
};
PageTitle.Actions = TitleActions;
