import { buildActions, makeReqCons } from 'store/actions';
export var appConstants = {
    reloadCompanyConfiguration: makeReqCons('RELOAD_COMPANY_CONFIGURATION'),
    constants: makeReqCons('APP_CONSTANTS'),
    fetchAddress: makeReqCons('APP_FETCH_ADDRESS'),
    getView: makeReqCons('GET_VIEW'),
    getViews: makeReqCons('GET_VIEWS'),
    updateViews: makeReqCons('UPDATE_VIEWS'),
    getColumns: makeReqCons('GET_AVAILABLE_COLUMNS'),
    getFiltersName: makeReqCons('GET_FILTER_NAME'),
    getFilters: makeReqCons('GET_AVAILABLE_FILTERS'),
    customUrl: makeReqCons('CUSTOM_URL'),
    export: makeReqCons('INVOICE_EXPORT'),
};
// Autogenerated actions according to constants
var appActions = buildActions('', appConstants, {});
export { appActions };
