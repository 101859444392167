import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useLegacyAxiosInstance } from './utils';
export var ExternalMailAttributesSchema = z.object({
    date: z.coerce.date(),
    subject: z.string(),
    from_mail: z.string(),
    to_mail: z.array(z.string().email()),
    cc: z.array(z.string().email()).nullable(),
    bcc: z.array(z.string().email()).nullable(),
    read: z.boolean(),
    status: z.enum(['processed', 'unprocessed']),
    body: z.string(),
    from_name: z.string().nullable(),
    has_attachments: z.boolean(),
    reply_mails: z.object({
        data: z.array(z.lazy(function () { return ExternalMailBase.shape.data; })),
    }),
    attachments: z.array(z.object({
        filename: z.string(),
        url: z.string().url(),
    })),
    debtor: z
        .object({
        id: z.string(),
        attributes: z.object({
            currency: z.string(),
            emails: z.array(z.string().email()),
            full_name: z.string(),
        }),
    })
        .nullable(),
    reply_count: z.number(),
    account_manager: z
        .object({
        id: z.string(),
        attributes: z.object({
            first_name: z.string(),
            last_name: z.string(),
            email: z.string().email(),
        }),
    })
        .nullable(),
});
var ExternalMailBase = z.object({
    data: z.object({
        id: z.string(),
        attributes: ExternalMailAttributesSchema,
    }),
});
export var ExternalMailSchema = ExternalMailBase.transform(function (data) { return data.data; });
export function loadExternalMailFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [4 /*yield*/, instance.get("/external_mails/".concat(queryKey[1]))];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, ExternalMailSchema.parse(data)];
            }
        });
    });
}
export function useGetExternalMail(externalMailId) {
    var axiosInstance = useLegacyAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['externalMail', externalMailId],
        queryFn: function (context) { return loadExternalMailFn(axiosInstance, context); },
        placeholderData: function (previousData) { return previousData; },
    });
    return addResourceNameToQueryResult('externalMail', queryResult);
}
