import React from 'react';
import { Box, Text } from '@mantine/core';
import { useLoadReminders } from '../api/use-load-index-reminders';
export var ReminderIndex = function (_a) {
    var reminderIndex = useLoadReminders().reminderIndex;
    if (!reminderIndex) {
        return null;
    }
    return (React.createElement(Box, null, reminderIndex.map(function (reminder, index) { return (React.createElement(Box, { key: index },
        React.createElement(Text, null, reminder.id))); })));
};
