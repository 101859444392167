import { __assign, __awaiter, __generator } from "tslib";
import { mapKeys, omit, toCamelCase } from 'remeda';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useLegacyAxiosInstance } from './utils';
var SaldoSchema = z
    .object({
    localized_total: z.coerce.number(),
    EUR: z.string().optional(),
    USD: z.string().optional(),
    GBP: z.string().optional(),
    CHF: z.string().optional(),
    SEK: z.string().optional(),
    JPY: z.string().optional(),
    NOK: z.string().optional(),
})
    .transform(function (obj) { return (__assign(__assign({}, omit(obj, ['localized_total'])), { localizedTotal: obj.localized_total })); });
var FinancialDataSchema = z
    .object({
    saldo: z.record(z.union([z.string().regex(/^\d+$/), z.literal('total')]), SaldoSchema),
    saldo_one_week: SaldoSchema,
    saldo_one_month: SaldoSchema,
})
    .transform(function (obj) {
    return mapKeys(obj, toCamelCase);
});
var RecentActivitiesSchema = z
    .object({
    invoices_to_treat: z.number(),
    payment_count: z.number(),
    ongoing_tasks_count: z.number(),
    unprocessed_emails_count: z.number(),
    unread_third_party_messages_count: z.number(),
})
    .transform(function (obj) {
    return mapKeys(obj, toCamelCase);
});
var DashboardSchema = z.object({
    data: z
        .object({
        financial_data: FinancialDataSchema,
        recent_activities: RecentActivitiesSchema,
    })
        .transform(function (obj) {
        return mapKeys(obj, toCamelCase);
    }),
});
export function LoadDashboardQueryFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [4 /*yield*/, instance.get('/dashboard', {
                            params: { account_manager_id: queryKey[1] },
                        })];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, DashboardSchema.parse(data)];
            }
        });
    });
}
export var useLoadDashboard = function (variables) {
    var axiosInstance = useLegacyAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['dashboard', variables.accountManagerId],
        queryFn: function (context) { return LoadDashboardQueryFn(axiosInstance, context); },
        placeholderData: function (previousData) { return previousData; },
    });
    return addResourceNameToQueryResult('dashboard', queryResult);
};
