import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import AddressBlock from 'shared/components/AddressBlock';
import { AVAILABLE_LANGUAGES } from 'types';
import styleIdentifiers from './ContactPersonDetails.scss';
var styles = classNames.bind(styleIdentifiers);
export var ContactPersonDetails = function (_a) {
    var _b;
    var contactPerson = _a.contactPerson;
    var t = useTranslation().t;
    var _c = contactPerson.attributes, main_contact = _c.main_contact, job_title = _c.job_title, locale = _c.locale, emails = _c.emails, phones = _c.phones, address = _c.address, notifications = _c.notifications;
    return (React.createElement("div", { className: styles('contact-person') },
        React.createElement("div", { className: styles('grid-row') },
            main_contact && (React.createElement("div", { className: styles('col-12') },
                React.createElement("span", { className: styles('main') }, t(i18nKeys.MAIN)))),
            React.createElement("div", { className: styles('col-6') },
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.FORM.JOB_TITLE)),
                    React.createElement("div", { className: styles('value') }, job_title || '-')),
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.FORM.CLIENT.CONTACT_LANG)),
                    React.createElement("span", { className: styles('value') }, ((_b = AVAILABLE_LANGUAGES.find(function (lang) { return lang.value === locale; })) === null || _b === void 0 ? void 0 : _b.description) || '-'))),
            React.createElement("div", { className: styles('col-6') },
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') },
                        " ",
                        t(i18nKeys.ADDRESS)),
                    address ? (React.createElement(AddressBlock, { className: styles('value'), address: __assign({ id: Number(address.id), type: address.type }, address.attributes) })) : (React.createElement("div", { className: styles('value') }, "-")))),
            React.createElement("div", { className: styles('col-6') },
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.EMAILS)),
                    (emails === null || emails === void 0 ? void 0 : emails.length) ? (React.createElement("div", null, emails.map(function (email) { return (React.createElement("a", { key: email, href: "mailto:".concat(email), className: styles('value', 'block') }, email)); }))) : (React.createElement("div", { className: styles('value') }, "-")))),
            React.createElement("div", { className: styles('col-6') },
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.PHONES)),
                    (phones === null || phones === void 0 ? void 0 : phones.length) ? (React.createElement("div", null, phones.map(function (phone) { return (React.createElement("a", { key: phone.id, href: "tel:".concat(phone.attributes.number), className: styles('value', 'block') }, phone.attributes.number)); }))) : (React.createElement("div", { className: styles('value') }, "-")))),
            React.createElement("div", { className: styles('col-12') },
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.FORM.NOTIFICATIONS)),
                    (notifications === null || notifications === void 0 ? void 0 : notifications.length) ? (React.createElement("div", { className: styles('value', 'notifications') }, notifications.map(function (notification) { return (React.createElement("span", { key: notification, className: styles('notification') }, t(i18nKeys.CONTACT_PERSON.NOTIFICATIONS[notification.toUpperCase()]))); }))) : (React.createElement("div", { className: styles('value') }, "-")))))));
};
