import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import Card from 'shared/components/Card';
import Integrations, { ExactForm, HorusForm, Integration, isEnabledIntegration, TeamleaderForm, YukiForm, } from 'shared/components/Integrations';
import { Button } from 'shared/io';
import { accountActions } from 'store/account/account.actions';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { useQueryClient } from '@tanstack/react-query';
import styleIdentifiers from './Integrations.scss';
var styles = classNames.bind(styleIdentifiers);
export default function IntegrationsOnBoarding(_a) {
    var _b, _c, _d, _e;
    var onValidStep = _a.onValidStep;
    var t = useTranslation().t;
    var client = useQueryClient();
    var callback = function () {
        dialogHide(DialogShowId.CUSTOM);
        client.invalidateQueries({ queryKey: ['profile'] });
        accountActions.getCompany({
            callback: function () {
                onValidStep();
            },
            actionFailure: function (response) {
                onValidStep(response.data.next_step);
            },
        });
    };
    var skipStep = function () {
        onBoardingActions.yuki({
            callback: callback,
            data: {
                skip: true,
            },
        });
    };
    var yukiSubmit = function (data) {
        onBoardingActions.yuki({
            callback: callback,
            data: data,
        });
    };
    var horusSubmit = function (data) {
        onBoardingActions.horus({
            callback: callback,
            data: data,
        });
    };
    var teamleaderSubmit = function (data) {
        onBoardingActions.teamleader({
            callback: callback,
            data: data,
        });
    };
    var exactSubmit = function (data) {
        onBoardingActions.exact({
            callback: callback,
            data: data,
        });
    };
    // const billitSubmit = (data: BillitFormFields) => {
    //   onBoardingActions.billit({
    //     callback,
    //     data,
    //   });
    // };
    var integrationsProps = {
        onboarding: true,
    };
    var integrations = __assign(__assign(__assign(__assign({}, (isEnabledIntegration(Integration.HORUS)
        ? (_b = {},
            _b[Integration.HORUS] = React.createElement(HorusForm, __assign({}, integrationsProps, { onSubmit: horusSubmit })),
            _b) : undefined)), (isEnabledIntegration(Integration.YUKI)
        ? (_c = {},
            _c[Integration.YUKI] = React.createElement(YukiForm, __assign({}, integrationsProps, { onSubmit: yukiSubmit })),
            _c) : undefined)), (isEnabledIntegration(Integration.TEAMLEADER)
        ? (_d = {},
            _d[Integration.TEAMLEADER] = (React.createElement(TeamleaderForm, __assign({}, integrationsProps, { onSubmit: teamleaderSubmit }))),
            _d) : undefined)), (isEnabledIntegration(Integration.EXACT)
        ? (_e = {},
            _e[Integration.EXACT] = React.createElement(ExactForm, __assign({}, integrationsProps, { onSubmit: exactSubmit })),
            _e) : undefined));
    var onIntegrationSelect = function (_a) {
        var title = _a.title;
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            keepMountOnExit: true,
            title: title,
            children: integrations[title],
        });
    };
    return (React.createElement("div", { className: styles('integrations') },
        React.createElement("div", { className: styles('steps') },
            React.createElement(Card, { noHead: true },
                React.createElement(Integrations, { isOnBoarding: true, onIntegrationSelect: onIntegrationSelect }),
                React.createElement("div", { className: styles('button-wrapper') },
                    React.createElement(Button, { onClick: skipStep, type: "submit", className: styles('button'), label: t(i18nKeys.ONBOARDING[5].CONFIGURATION_DONE) }))))));
}
