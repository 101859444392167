import { __assign } from "tslib";
import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory } from 'react-router';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import DateItem from 'shared/components/DateItem';
import { Icon, IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import EncodePaymentForm from 'shared/forms/EncodePaymentForm';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import { isConnectorActive } from 'shared/utils/connector';
import { formattedDate } from 'shared/utils/view';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { invoice } from 'store/root.reducer';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, sideMenuHide, sideMenuShow, } from 'store/view/view.actions';
import InvoiceDetailContext from '../InvoiceDetail.context';
import LatePaymentInfoTermsModal from './LatePaymentInfoTermsModal';
import styleIdentifiers from '../ToHandleDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export function LatePaymentInfo() {
    var _a;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var _c = useContext(InvoiceDetailContext), data = _c.data, isCreditNote = _c.credit, unmatchPayment = _c.unmatchPayment, clientData = _c.clientData, reloadInfo = _c.reloadInfo, 
    // TODO: invert this flag
    // eslint-disable-next-line @typescript-eslint/naming-convention
    noValidate = _c.noValidate;
    var history = useHistory();
    var hasActiveConnector = isConnectorActive();
    var canShowInvoiceButtons = !noValidate && !isCreditNote && !hasActiveConnector;
    var submitPayment = function (invoiceId, reload, currency) { return function (values) {
        if (!invoice)
            return;
        invoiceActions.actionsAddPayment({
            id: invoiceId,
            data: __assign({ currency: currency }, values),
            callback: function () {
                reload();
                sideMenuHide();
            },
        });
    }; };
    var addPayment = function (invoiceId, reload) { return function () {
        sideMenuShow({
            unmount: true,
            content: (React.createElement(EncodePaymentForm, { onSubmit: submitPayment(invoiceId, reload, data.currency), currency: data.currency, amountWithoutFees: data.remaining_balance_with_fees - data.late_fees, totalAmount: data.remaining_balance_with_fees })),
        });
    }; };
    var createCreditNote = function (reference) { return function () {
        dialogHide(DialogShowId.INVOICE);
        creditNoteActions.detailReset();
        history.push("/credit-notes/create?reference=".concat(reference));
    }; };
    var canUnmatch = (function () {
        var _a;
        if (isCreditNote)
            return Number(data.remaining_balance) !== Number(data.total_tvac);
        if (noValidate)
            return false;
        if (data.actions.includes('unmatch_payment'))
            return true;
        if (((_a = data.credit_notes_attributes) !== null && _a !== void 0 ? _a : []).length > 0)
            return true; // Sentry 181
        return false;
    })();
    var showCgvDetails = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.SALE_CONDITION_APPLIED),
            children: React.createElement(LatePaymentInfoTermsModal, { invoice: data }),
        });
    };
    return (React.createElement(Card, { title: t(i18nKeys.INVOICE.PAYMENT_INFO), className: styles('box', 'late-payment-info'), actionHead: canUnmatch ? unmatchPayment : undefined, infosRight: canUnmatch && (React.createElement("div", { onClick: unmatchPayment },
            React.createElement(Icon, { name: IconName.EYE, size: "22px" }))) },
        React.createElement("div", { className: styles('item-container') },
            React.createElement("div", { className: styles('item') },
                React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.DUE_DATE)),
                React.createElement("span", { className: styles('value', 'due-date') },
                    React.createElement(DateItem, { date: data.due_date, lg: currentLang, format: "DD/MM/YY", className: styles('value', 'blue', 'bigger') }),
                    React.createElement("span", { className: styles('value', 'medium', 'red', 'ml-10') },
                        "+ ",
                        t(i18nKeys.DAYS, { count: data.days_late || 0 })))),
            !data.paid && (clientData === null || clientData === void 0 ? void 0 : clientData.average_payment_delay) !== null && (React.createElement("div", { className: styles('item') },
                React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.INVOICE.ESTIMATED_PAYMENT_DATE)),
                React.createElement("div", null, data.issue_date ? (React.createElement("span", { className: styles('value', 'bigger', 'blue') }, formattedDate(data.issue_date.unix() * 1000 +
                    1000 * 60 * 60 * 24 * clientData.average_payment_delay, currentLang, 'DD/MM/YY'))) : (React.createElement("span", { className: styles('value', 'bigger', 'blue') }, "-"))))),
            !isCreditNote && (React.createElement("div", { className: styles('item') },
                React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.INVOICE.TOTAL_LATE_FEES)),
                React.createElement("div", { className: styles('value') },
                    React.createElement(Amount, { localizedValue: data.late_fees, value: data.localized_money_object.late_fees, suffix: data.currency, className: styles('bigger') }),
                    React.createElement(Tooltip, { className: styles('ml-10'), direction: DropdownDirection.LEFT, icon: IconName.INFO },
                        React.createElement("div", { className: styles('payment-fees-tooltip') },
                            data.interest_amount > 0 && (React.createElement("div", { className: styles('item') },
                                React.createElement("span", { className: styles('subtitle') },
                                    t(i18nKeys.INVOICE.INTEREST),
                                    ' ',
                                    data.late_fees_calculated_at &&
                                        t(i18nKeys.INVOICE.CALCULATE_DATE, {
                                            date: formattedDate(data.late_fees_calculated_at, currentLang),
                                        })),
                                React.createElement(Amount, { value: data.interest_amount, suffix: data.currency, className: styles('value', 'bigger'), tooltip: false, localizedValue: data.localized_money_object.interest_amount }))),
                            data.fix_fee_amount > 0 && (React.createElement("div", { className: styles('item') },
                                React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.FIX_FEE_AMOUNT)),
                                React.createElement(Amount, { value: data.fix_fee_amount, suffix: data.currency, className: styles('value', 'bigger'), tooltip: false, localizedValue: data.localized_money_object.fix_fee_amount }))),
                            data.penalty_clause_amount > 0 && (React.createElement("div", { className: styles('item') },
                                React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.PENALTY_CLAUSE)),
                                React.createElement(Amount, { value: data.penalty_clause_amount, suffix: data.currency, className: styles('value', 'bigger'), tooltip: false, localizedValue: data.localized_money_object.penalty_clause_amount }))),
                            React.createElement("div", { className: styles('item') },
                                React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.SALE_CONDITION)),
                                React.createElement("span", { onClick: showCgvDetails, className: styles('value', 'medium', 'link') }, t(i18nKeys.SHOW_CGV)))))))),
            React.createElement("div", { className: styles('item') }, data.remaining_balance_with_fees === data.remaining_balance || isCreditNote ? (React.createElement(React.Fragment, null,
                React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.BALANCE)),
                React.createElement("span", null,
                    React.createElement(Amount, { localizedValue: data.remaining_balance, value: data.localized_money_object.remaining_balance, suffix: data.currency, className: styles('value', 'bigger') })))) : (React.createElement(React.Fragment, null,
                React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.INVOICE.BALANCE_FEES)),
                React.createElement("span", { className: styles('value', 'inline', 'bigger') },
                    React.createElement(Amount, { localizedValue: data.remaining_balance, value: data.localized_money_object.remaining_balance, suffix: data.currency, style: { display: ' inline-block' } }),
                    ' / ',
                    React.createElement(Amount, { localizedValue: data.remaining_balance_with_fees, value: data.localized_money_object.remaining_balance_with_fees, suffix: data.currency, style: { display: ' inline-block' } })))))),
        canShowInvoiceButtons && (React.createElement("div", { className: styles('card-header') },
            React.createElement("div", { title: t(i18nKeys.INVOICE.CREATE_CREDIT_NOTE), className: styles('icon', !data.total_htva && 'disabled'), onClick: createCreditNote(data.reference) },
                React.createElement(Icon, { name: IconName.CREDIT_NOTE, className: styles('icon') })),
            React.createElement("div", { title: t(i18nKeys.INVOICE.ADD_PAYMENT), className: styles(!((_a = data.actions) === null || _a === void 0 ? void 0 : _a.includes('add_payment')) && 'disabled'), onClick: addPayment(data.id, reloadInfo) },
                React.createElement(Icon, { name: IconName.ADD_PAYMENT, className: styles('icon') }))))));
}
