import { useMemo } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { INTEGRATIONS_ROUTE } from 'shared/components/Integrations';
import { IconAdjustmentsAlt, IconBuilding, IconEdit, IconFileDollar, IconMailCog, IconMapDollar, IconPackage, IconPlugConnected, IconSettings, IconTemplate, IconUser, IconUsers, } from '@tabler/icons-react';
export function useBuildSettingMenu() {
    var t = useTranslation().t;
    return useMemo(function () {
        var items = [
            {
                path: '/settings/user',
                label: t(i18nKeys.SETTINGS.PROFILE.TITLE),
                Icon: IconUser,
            },
            {
                path: '/settings/company',
                label: t(i18nKeys.SETTINGS.COMPANY.TITLE),
                Icon: IconBuilding,
            },
            {
                path: '/settings/preferences',
                label: t(i18nKeys.PREFERENCES),
                Icon: IconAdjustmentsAlt,
            },
            {
                path: '/settings/package',
                label: t(i18nKeys.SETTINGS.PACKAGE.TITLE),
                Icon: IconPackage,
            },
            {
                path: '/settings/external-mailbox',
                label: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.TITLE),
                Icon: IconMailCog,
            },
            {
                path: '/settings/billing',
                label: t(i18nKeys.COMMON.INVOICING),
                Icon: IconFileDollar,
            },
            {
                path: '/settings/workflows',
                label: t(i18nKeys.AUTOMATED_PLANS),
                Icon: IconMapDollar,
            },
            {
                path: '/settings/document-customization',
                label: t(i18nKeys.TEMPLATES),
                Icon: IconTemplate,
            },
            {
                path: '/settings/customization',
                label: t(i18nKeys.NAV.CUSTOMIZATION),
                Icon: IconEdit,
            },
            {
                path: '/settings/collaborators',
                label: t(i18nKeys.SETTINGS.STAFF.TITLE),
                Icon: IconUsers,
            },
            {
                path: INTEGRATIONS_ROUTE,
                label: t(i18nKeys.SETTINGS.INTEGRATIONS.TITLE),
                Icon: IconPlugConnected,
            },
        ];
        return {
            parent: null,
            label: t(i18nKeys.NAV.SETTINGS),
            Icon: IconSettings,
            items: items,
        };
    }, [t]);
}
