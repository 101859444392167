import { __assign } from "tslib";
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { i18nKeys, useTranslation } from 'locales';
import qs from 'query-string';
import CustomTable from 'shared/components/CustomTable';
import RecovrLogoLoader from 'shared/components/Loader';
import { useCreateExternalMailbox, useLoadExternalMailboxes, } from 'shared/hooks';
import { Button } from 'shared/io';
import { useGetCompany } from 'shared/utils/selectors';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { notifications } from '@mantine/notifications';
import { ExternalMailboxSettingsForm } from './ExternalMailboxSettingsForm';
import { ExternalMailboxSettingsItem } from './ExternalMailboxSettingsItem';
import styleIdentifiers from './ExternalMailboxSettings.scss';
var styles = classNames.bind(styleIdentifiers);
var ExternalMailboxSettingsCustomTable = CustomTable(ExternalMailboxSettingsItem);
export var ExternalMailboxSettings = function () {
    var t = useTranslation().t;
    var _a = useGetCompany().package, canAddMailbox = _a.can_add_mailbox, canUseExternalMails = _a.can_use_external_mails;
    var _b = useLoadExternalMailboxes(), externalMailboxes = _b.externalMailboxes, isExternalMailboxesLoading = _b.isExternalMailboxesLoading, externalMailboxesError = _b.externalMailboxesError;
    var createExternalMailbox = useCreateExternalMailbox().createExternalMailbox;
    var queryParams = qs.parse(location.search);
    var messages = queryParams.message;
    useEffect(function () {
        if (messages) {
            notifications.show({
                title: t(i18nKeys.SUCCESS),
                color: 'green',
                message: messages,
            });
        }
    }, [messages, t]);
    if (isExternalMailboxesLoading && !externalMailboxes)
        return React.createElement(RecovrLogoLoader, null);
    if (externalMailboxesError || !externalMailboxes)
        return React.createElement("span", null, "Error");
    var createExternalMailboxConfiguration = function () {
        return sideMenuShow({
            unmount: true,
            content: (React.createElement(ExternalMailboxSettingsForm, { onSubmit: function (data) {
                    var temp = __assign(__assign({}, data), { is_microsoft: false });
                    createExternalMailbox(temp, { onSuccess: sideMenuHide });
                } })),
        });
    };
    var TABLE_HEADERS = [
        {
            key: 'id',
            title: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.ID),
        },
        {
            key: 'host',
            title: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.HOST),
        },
        {
            key: 'port',
            title: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.PORT),
        },
        {
            key: 'ssl',
            title: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.SSL),
        },
        {
            key: 'login',
            title: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.LOGIN),
        },
        {
            title: t(i18nKeys.FORM.ACTIONS),
            width: 100,
        },
    ];
    var tooltipMessage = canUseExternalMails
        ? i18nKeys.SETTINGS.EXTERNAL_MAILBOX.LIMIT_EXCEEDED
        : i18nKeys.NOT_INCLUDED;
    return (React.createElement(ExternalMailboxSettingsCustomTable, { className: styles('Mailbox-settings'), title: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.TITLE), headers: TABLE_HEADERS, items: externalMailboxes.data || [], pagination: externalMailboxes.metadata.pagination, itemProps: { externalMailboxes: externalMailboxes }, loading: isExternalMailboxesLoading, loaded: !isExternalMailboxesLoading, notSortable: true, noCheckbox: true, actions: React.createElement(Button, { label: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.ADD), onClick: createExternalMailboxConfiguration, noMargin: true, title: canAddMailbox ? '' : t(tooltipMessage), disabled: !canAddMailbox }) }));
};
