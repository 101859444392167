import { __assign, __read, __spreadArray } from "tslib";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory, useLocation } from 'react-router';
import { Icon, IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { useIsMobile } from 'shared/hooks/utils';
import { Dropdown, Input } from 'shared/io';
import { useGetViewsByResource } from 'shared/utils/selectors';
import { Text, useMantineTheme } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconStarFilled } from '@tabler/icons-react';
import { quickSearchKey } from '../CustomTable.utils';
import { ViewSettingsModal } from '../ViewSettings';
import styleIdentifiers from './CustomTableTitle.scss';
var styles = classNames.bind(styleIdentifiers);
export default function CustomTableTitle(_a) {
    var title = _a.title, actions = _a.actions, tableName = _a.tableName, submit = _a.submit, invalidateViews = _a.invalidateViews;
    var t = useTranslation().t;
    var history = useHistory();
    var location = useLocation();
    var isMobile = useIsMobile();
    var theme = useMantineTheme();
    var tableViews = useGetViewsByResource()[tableName];
    var _b = __read(useState(), 2), currentViewId = _b[0], setCurrentViewId = _b[1];
    var _c = __read(useState(0), 2), currentTabIndex = _c[0], _setCurrentTabIndex = _c[1];
    var _d = __read(useState(''), 2), searchedView = _d[0], setSearchedView = _d[1];
    // Float the favorite view if any to the first position
    var orderedViews = (function () {
        var favoriteViewIndex = tableViews.findIndex(function (view) { return view.is_favorite; });
        if (favoriteViewIndex === -1) {
            return tableViews;
        }
        return __spreadArray([
            tableViews[favoriteViewIndex]
        ], __read(tableViews.filter(function (_, index) { return index !== favoriteViewIndex; })), false);
    })();
    useEffect(function () {
        if (location.hash !== currentViewId) {
            setCurrentViewId(location.hash.slice(1));
        }
    }, [location.hash]);
    var openViewModal = function () {
        modals.open({
            title: t(i18nKeys.VIEWS.SETTINGS.TITLE),
            size: '800px',
            centered: true,
            onClose: invalidateViews,
            children: React.createElement(ViewSettingsModal, { resourceType: tableName }),
        });
    };
    var filtersToUrl = function (view) {
        var newFilters = {};
        view.filter_fields.forEach(function (filter) {
            if (filter.applied_filter.operator === 'null' ||
                filter.applied_filter.operator === 'not_null') {
                newFilters["".concat(filter.name, "_handler")] = filter.applied_filter.operator;
            }
            else {
                if (filter.filter_type === 'date') {
                    newFilters["".concat(filter.name, "_start")] = filter.applied_filter.value[0];
                    newFilters["".concat(filter.name, "_end")] = filter.applied_filter.value[1];
                    delete newFilters[filter.name];
                }
                else if (filter.filter_type === 'number') {
                    newFilters["min_".concat(filter.name)] = filter.applied_filter.value[0];
                    newFilters["max_".concat(filter.name)] = filter.applied_filter.value[1];
                    delete newFilters[filter.name];
                }
                else {
                    newFilters[filter.name] = filter.applied_filter.value;
                }
                if (filter.applied_filter.operator) {
                    newFilters["".concat(filter.name, "_operator")] = filter.applied_filter.operator;
                }
            }
        });
        return newFilters;
    };
    var setQuickSearchValue = function (value) {
        var input = document.querySelector("[data-quick-search='1']");
        if (input) {
            input.value = value;
        }
    };
    var changeView = function (view) { return function () {
        var urlFilters = Object.entries(__assign({}, filtersToUrl(view)));
        history.push("".concat(location.pathname).concat(urlFilters.reduce(function (acc, _a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            return "".concat(acc).concat(key, "=").concat(value, "&");
        }, '?')).concat("#".concat(view.id)));
        var quickSearchValue = '';
        urlFilters.forEach(function (filter) {
            if (filter[0] === 'quick_search') {
                quickSearchValue = filter[1];
            }
        });
        setQuickSearchValue(quickSearchValue);
    }; };
    var setCurrentTabIndex = function (nbre) { return function () {
        _setCurrentTabIndex(currentTabIndex + nbre);
    }; };
    var filteredViews = function (views) {
        return searchedView
            ? views.filter(function (view) { return view.name.toLowerCase().indexOf(searchedView.toLowerCase()) !== -1; })
            : views;
    };
    var optionsMenu = function () {
        return (React.createElement("div", { className: styles('options') },
            React.createElement(Dropdown, { toggleContent: true, selectorContent: React.createElement(Icon, { name: IconName.MINIMAL_DOWN }) },
                React.createElement("div", { className: styles('dropdown-input') },
                    React.createElement(Input, { name: "searched_view", autoComplete: "off", placeholder: t(i18nKeys.FORM.SEARCH), noMargin: true, onValueChanged: setSearchedView, onClick: function (e) {
                            e.stopPropagation();
                        } })),
                filteredViews(tableViews).map(function (view) { return (React.createElement("div", { key: view.id, className: styles('dropdown-item'), onClick: changeView(view) }, view.name)); })),
            React.createElement(Icon, { name: IconName.SETTINGS_GEAR, onClick: openViewModal })));
    };
    return (React.createElement("div", { className: styles('custom-table-title', tableName && 'table-view') },
        React.createElement("div", { className: styles('tab-container') },
            React.createElement("div", { className: styles('title') },
                React.createElement("h1", null, title),
                tableViews && isMobile && optionsMenu()),
            tableViews && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles('tab-wrapper') },
                    currentTabIndex > 0 && (React.createElement("div", { className: styles('tab-item'), onClick: setCurrentTabIndex(-1) },
                        React.createElement(Icon, { name: IconName.MINIMAL_LEFT }))),
                    orderedViews.slice(currentTabIndex, currentTabIndex + 3).map(function (view) { return (React.createElement("div", { key: view.id, className: styles('tab-item', view.id === currentViewId && 'active'), onClick: changeView(view) },
                        view.is_favorite && React.createElement(IconStarFilled, { size: 20, color: theme.colors.gray[6] }),
                        React.createElement(Text, { ml: view.is_favorite ? 8 : 0 }, view.name))); }),
                    currentTabIndex < tableViews.length - 3 && (React.createElement("div", { className: styles('tab-item'), onClick: setCurrentTabIndex(1) },
                        React.createElement(Icon, { name: IconName.MINIMAL_RIGHT }))),
                    React.createElement("div", { className: styles('border-mobile') })),
                !isMobile && optionsMenu()))),
        React.createElement("div", { className: styles('actions-container', isMobile && 'space-between') },
            isMobile && (React.createElement("div", { className: styles('quick-search') },
                React.createElement(Input, { noMargin: true, quickSearch: true, shadow: true, type: "text", placeholder: t(i18nKeys.FORM.QUICK_SEARCH.PLACEHOLDER), onValueChanged: submit }),
                React.createElement(Tooltip, { icon: IconName.INFO, size: "14px", text: t(i18nKeys.FORM.QUICK_SEARCH.TOOLTIP[quickSearchKey(tableName)]) }))),
            React.createElement("div", { className: styles('actions') }, actions))));
}
