/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */
// eslint-disable-next-line @typescript-eslint/no-redeclare
export var TimelineTypes = {
    external_mail: 'external_mail',
    debtor_reaction: 'debtor_reaction',
    activity: 'activity',
};
