import { __awaiter, __generator, __read, __spreadArray } from "tslib";
import dayjs from 'dayjs';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';
var ExportSchema = z.object({
    file: z.string().url(),
    executionDate: z.string().transform(function (date) { return dayjs(date); }),
});
var ReportSchema = z.object({
    id: z.string(),
    name: z.string().min(1),
    periodicity: z.object({
        type: z.enum(['weekly', 'monthly']),
        days: z.array(z.number()).transform(function (days) { return __spreadArray([], __read(days), false).sort(function (a, b) { return a - b; }); }),
    }),
    emails: z.array(z.string().email()),
    exports: z.array(ExportSchema),
});
export function loadReportFooterQueryFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [4 /*yield*/, instance.get("/reports/export_tasks/".concat(queryKey[1]))];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, ReportSchema.parse(data)];
            }
        });
    });
}
export var useLoadReport = function (reportId) {
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['report', reportId],
        queryFn: function (context) { return loadReportFooterQueryFn(axiosInstance, context); },
    });
    return addResourceNameToQueryResult('report', queryResult);
};
