import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';
var BalanceValueSchema = z.preprocess(function (val) { return Number(val); }, z.number());
var BalanceSchema = z.object({
    '0': BalanceValueSchema,
    '30': BalanceValueSchema,
    '60': BalanceValueSchema,
    '90': BalanceValueSchema,
    notDue: BalanceValueSchema,
});
var AgedDebtorSchema = z.object({
    debtorId: z.number(),
    debtorName: z.string().min(1),
    credits: BalanceSchema,
    debits: BalanceSchema,
});
var AgedBalanceSchema = z
    .object({
    computedAt: z.string(),
    agedBalances: z.array(AgedDebtorSchema),
})
    .transform(function (_a) {
    var computedAt = _a.computedAt, agedBalances = _a.agedBalances;
    return ({ computedAt: computedAt, data: agedBalances });
});
var AgedBalanceCompactSchema = z.object({
    computedAt: z.string(),
    credits: BalanceSchema,
    debits: BalanceSchema,
});
export function loadAgedBalanceQueryFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [4 /*yield*/, instance.get('/analytics/aged_balances?compact=false', {
                            params: {
                                view_id: queryKey[2],
                            },
                        })];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, AgedBalanceSchema.parse(data)];
            }
        });
    });
}
export var useLoadAgedBalance = function (_a) {
    var viewId = _a.viewId;
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['aged-balance', 'regular', viewId],
        queryFn: function (context) { return loadAgedBalanceQueryFn(axiosInstance, context); },
        placeholderData: function (previousData) { return previousData; },
    });
    return addResourceNameToQueryResult('agedBalance', queryResult);
};
export function loadAgedBalanceCompactQueryFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [4 /*yield*/, instance.get('/analytics/aged_balances?compact=true', {
                            params: {
                                view_id: queryKey[2],
                            },
                        })];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, AgedBalanceCompactSchema.parse(data)];
            }
        });
    });
}
export var useLoadAgedBalanceCompact = function (_a) {
    var _b = _a === void 0 ? {} : _a, viewId = _b.viewId;
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['aged-balance', 'compact', viewId],
        queryFn: function (context) { return loadAgedBalanceCompactQueryFn(axiosInstance, context); },
        placeholderData: function (previousData) { return previousData; },
    });
    return addResourceNameToQueryResult('agedBalanceCompact', queryResult);
};
