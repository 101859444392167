import { __assign, __awaiter, __generator } from "tslib";
import dayjs from 'dayjs';
import { last, omit } from 'remeda';
import { v4 } from 'uuid';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';
var DebtorBalanceParamsSchema = z.object({
    start_date: z.string().optional(),
    end_date: z.string().optional(),
    types: z.array(z.string()),
    showSettled: z.boolean(),
});
var ApiAmountSchema = z.preprocess(function (val) { return Number(val !== null && val !== void 0 ? val : 0); }, z.number());
var MatchedCreditNoteSchema = z.object({
    reference: z.string(),
    issueDate: z
        .string()
        .transform(function (date) { return dayjs(date); })
        .optional(),
    amount: ApiAmountSchema,
});
var PaymentSchema = z
    .object({
    amount: ApiAmountSchema,
    remainingBalance: ApiAmountSchema,
    date: z.string().transform(function (date) { return dayjs(date); }),
    communication: z.string().nullable(),
})
    .transform(function (data) { return (__assign(__assign({}, omit(data, ['date'])), { issueDate: data.date, id: v4() })); });
var MatchedPaymentSchema = z
    .object({
    amount: ApiAmountSchema,
    remainingBalance: ApiAmountSchema,
    date: z
        .string()
        .transform(function (date) { return dayjs(date); })
        .nullable(),
    communication: z.string().nullable(),
    isLoss: z.boolean(),
})
    .transform(function (data) { return (__assign(__assign({}, omit(data, ['date'])), { issueDate: data.date })); });
var InvoiceSchema = z.object({
    id: z.number(),
    reference: z.string().nullable().default(''),
    paid: z.boolean(),
    totalTvac: ApiAmountSchema,
    totalHtva: ApiAmountSchema,
    remainingBalance: ApiAmountSchema,
    remainingLateFees: ApiAmountSchema,
    lateFees: ApiAmountSchema,
    penaltyClauseAmount: ApiAmountSchema,
    issueDate: z.string().transform(function (date) { return dayjs(date); }),
    dueDate: z.string(),
    status: z.string(),
    matchedPayments: z.array(MatchedPaymentSchema),
    creditNotes: z.array(MatchedCreditNoteSchema),
});
var CreditNoteSchema = InvoiceSchema.pick({
    id: true,
    reference: true,
    paid: true,
    totalTvac: true,
    totalHtva: true,
    remainingBalance: true,
    issueDate: true,
    dueDate: true,
    status: true,
}).extend({
    invoices: z.array(z.any()),
    payments: z.array(PaymentSchema).optional(),
});
var DebtorBalanceSchema = z.object({
    invoices: z.array(InvoiceSchema),
    payments: z.array(PaymentSchema),
    creditNotes: z.array(CreditNoteSchema),
});
export function loadDebtorBalanceFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, showSettled, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    showSettled = last(queryKey);
                    return [4 /*yield*/, instance.get("/debtors/".concat(queryKey[1], "/balance?showSettled=").concat(showSettled))];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, DebtorBalanceSchema.parse(data)];
            }
        });
    });
}
export function useLoadDebtorBalance(debtorId, showSettled) {
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['debtors', debtorId, 'balance', showSettled !== null && showSettled !== void 0 ? showSettled : false],
        queryFn: function (context) { return loadDebtorBalanceFn(axiosInstance, context); },
        placeholderData: function (previousData) { return previousData; },
    });
    return addResourceNameToQueryResult('debtorBalance', queryResult);
}
