import React from 'react';
import { Button, Text } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
var styles = {
    root: {
        height: 40,
        width: '100%',
    },
    inner: {
        justifyContent: 'flex-start',
    },
    label: {
        fontFamily: 'inherit',
    },
};
export var NavBackButton = function (_a) {
    var label = _a.label, onClick = _a.onClick;
    return (React.createElement(Button, { variant: "default", fullWidth: true, mb: 4, styles: styles, radius: 8, leftSection: React.createElement(IconArrowLeft, { color: "#4D4D4D", size: 16 }), onClick: onClick },
        React.createElement(Text, { c: "#4D4D4D" }, label)));
};
