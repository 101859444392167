import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Button } from 'shared/io';
import { useGetCompany } from 'shared/utils/selectors';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import { Integration, INTEGRATIONS_ROUTE } from '../../Integrations.constants';
import { INTEGRATION_UPDATE_CALLBACK } from '../Integration.constants';
import { getIntegrationRights } from '../Integration.utils';
import { IntegrationHeader } from '../IntegrationHeader';
import { PontoForm } from './PontoForm';
import styleIdentifiers from '../Integration.scss';
var styles = classNames.bind(styleIdentifiers);
export var Ponto = function (_a) {
    var _b = _a.history, goBack = _b.goBack, replace = _b.replace;
    var t = useTranslation().t;
    var company = useGetCompany();
    var _c = __read(useState(false), 2), isEditting = _c[0], setIsEditting = _c[1];
    var _d = getIntegrationRights(Integration.PONTO, company), isActive = _d.isActive, canActivate = _d.canActivate;
    useEffect(function () {
        if (!canActivate && !isActive) {
            replace(INTEGRATIONS_ROUTE);
        }
    }, [canActivate, isActive, replace]);
    if (!(company === null || company === void 0 ? void 0 : company.tp_accounting)) {
        return (React.createElement("div", { className: styles('loading') },
            React.createElement("div", null, t(i18nKeys.LOADING))));
    }
    var handleSubmit = function (data) {
        onBoardingActions.ponto({
            settings: true,
            data: data,
            callback: function () {
                INTEGRATION_UPDATE_CALLBACK();
                setIsEditting(false);
            },
        });
    };
    return (React.createElement("article", { className: styles('ponto') },
        React.createElement(IntegrationHeader, { name: Integration.PONTO, goBack: goBack }),
        !isEditting && isActive ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles('row') },
                React.createElement("div", { className: styles('attribute') },
                    t(i18nKeys.SETTINGS.INTEGRATIONS.PONTO.SECRET_CODE),
                    ":"),
                React.createElement("div", { className: styles('value') },
                    "*****",
                    company.ponto_integration.ponto_client_secret)),
            React.createElement("div", { className: styles('row') },
                React.createElement("div", { className: styles('attribute') },
                    t(i18nKeys.SETTINGS.CLIENT_ID),
                    ":"),
                React.createElement("div", { className: styles('value') },
                    "*****",
                    company.ponto_integration.ponto_client_id)),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { label: t(i18nKeys.EDIT), onClick: function () { return setIsEditting(function (current) { return !current; }); } })))) : (React.createElement(PontoForm, { onSubmit: handleSubmit }))));
};
