import { __assign } from "tslib";
import React, { useEffect } from 'react';
import { MantineSettingsCard } from 'app/Private/Settings/MantineSettingsCard';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { FormProvider, useForm } from 'react-hook-form';
import NavigationPrompt from 'shared/components/BlockNavigation';
import { Box } from '@mantine/core';
import { CustomPreferencesPartialForm } from './CustomPreferencesPartialForm/CustomPreferencesPartialForm';
import { GeneralConditionsPartialSendTos } from './GeneralConditionsPartialForm/GeneralConditionsPartialSendTos';
import { PaymentPlanPartialForm } from './PaymentPlanPartialForm/PaymentPlanPartialForm';
import { PostalPreferencesPartialForm } from './PostalPreferencesPartialForm/PostalPreferencesPartialForm';
import { SalePreferencesPartialForm } from './SalePreferencesPartialForm/SalePreferencesPartialForm';
import styleIdentifiers from './PreferencesForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var PreferencesForm = function (_a) {
    var company = _a.company, onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var form = useForm({
        defaultValues: company,
    });
    var dirtyFields = form.formState.dirtyFields, reset = form.reset, handleSubmit = form.handleSubmit;
    useEffect(function () {
        reset(__assign({}, company));
    }, [company, reset]);
    var isDirty = !!Object.keys(dirtyFields).length;
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement(MantineSettingsCard, { title: t(i18nKeys.SALE_CONDITION), onSave: isDirty ? handleSubmit(onSubmit) : undefined },
            React.createElement(Box, { mb: "xl", mx: "sm" },
                React.createElement(NavigationPrompt, { when: isDirty, onSaveAndQuit: handleSubmit(onSubmit) }),
                React.createElement("form", { className: styles('PreferencesForm'), onSubmit: handleSubmit(onSubmit) },
                    React.createElement(SalePreferencesPartialForm, null),
                    React.createElement(GeneralConditionsPartialSendTos, null),
                    React.createElement("h3", { className: styles('margin') }, t(i18nKeys.PAYMENT_PLAN.TITLE.ONE)),
                    React.createElement(PaymentPlanPartialForm, null),
                    React.createElement("h3", { className: styles('margin') }, t(i18nKeys.FORM.PREFERENCES.PREFERENCES_SEND)),
                    React.createElement("h4", { className: styles('margin') }, t(i18nKeys.EMAIL)),
                    React.createElement(CustomPreferencesPartialForm, null),
                    React.createElement("h4", { className: styles('margin') }, t(i18nKeys.FORM.PREFERENCES.PREFERENCES_POSTAL_SEND)),
                    React.createElement(PostalPreferencesPartialForm, null))))));
};
