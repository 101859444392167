import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { ModalFooter } from 'shared/components';
import { RichTextArea } from 'shared/components/Inputs';
import { Modal } from '@mantine/core';
export default function SignatureEditorModal(_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onSave = _a.onSave, signature = _a.signature;
    var t = useTranslation().t;
    var _b = __read(useState(signature), 2), htmlContent = _b[0], setHtmlContent = _b[1];
    useEffect(function () {
        setHtmlContent(signature);
    }, [signature]);
    var handleSave = function () {
        onSave(htmlContent !== null && htmlContent !== void 0 ? htmlContent : null);
        onClose();
    };
    return (React.createElement(Modal, { opened: isOpen, onClose: onClose, title: t(i18nKeys.EDIT_SIGNATURE), size: "845px" },
        React.createElement(RichTextArea, { content: htmlContent !== null && htmlContent !== void 0 ? htmlContent : undefined, onChange: function (content) { return setHtmlContent(content); }, style: { height: '200px' } }),
        React.createElement(ModalFooter, { onCancel: onClose, onSave: handleSave })));
}
