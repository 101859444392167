import React from 'react';
import { useIsMobile } from 'shared/hooks/utils';
import { ActionIcon, Box, Center } from '@mantine/core';
import { IconMenu2 } from '@tabler/icons-react';
export var MobileMenuButton = function (_a) {
    var onClickOpenNavbar = _a.onClickOpenNavbar;
    var isMobile = useIsMobile();
    if (!isMobile)
        return null;
    return (React.createElement(Box, { onClick: onClickOpenNavbar, bg: "white", w: 50, h: 50, pos: "absolute", bottom: 20, right: -60, p: 4, style: {
            borderRadius: '100%',
            border: '1px solid #EDEDED',
            boxShadow: 'var(--mantine-shadow-md)',
        } },
        React.createElement(Center, { h: "100%" },
            React.createElement(ActionIcon, { variant: "transparent" },
                React.createElement(IconMenu2, null)))));
};
