import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { useAxiosInstance } from './utils';
export var CreateReportSchema = z.object({
    viewId: z.string(),
    name: z.string(),
    emails: z.array(z.string()),
    periodicity: z.object({
        type: z.union([z.literal('weekly'), z.literal('monthly')]),
        days: z.array(z.number()),
    }),
});
export function createReportFn(axiosInstance, data) {
    return __awaiter(this, void 0, void 0, function () {
        var instance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [2 /*return*/, instance.post('reports/export_tasks', CreateReportSchema.parse(data))];
            }
        });
    });
}
export function useCreateReport() {
    var client = useQueryClient();
    var axiosInstance = useAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (data) { return createReportFn(axiosInstance, data); },
        onSuccess: function (response) {
            notifications.show({
                message: response.data.message,
                color: 'green',
            });
            client.invalidateQueries({
                queryKey: ['reports'],
            });
        },
    });
    return addActionNameToMutationResult('createReport', mutation);
}
