import { __read } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useIsMobile } from 'shared/hooks/utils';
import { sideMenuShow } from 'store/view/view.actions';
import styleIdentifiers from './Dropdown.scss';
var styles = classNames.bind(styleIdentifiers);
export var DropdownDirection;
(function (DropdownDirection) {
    DropdownDirection["BOTTOM"] = "bottom";
    DropdownDirection["TOP"] = "top";
    DropdownDirection["LEFT"] = "left";
    DropdownDirection["TOP_LEFT"] = "top-left";
    DropdownDirection["RIGHT"] = "right";
    DropdownDirection["KANBAN"] = "kanban";
})(DropdownDirection || (DropdownDirection = {}));
export var Dropdown = function (_a) {
    var active = _a.active, forceClose = _a.forceClose, action = _a.action, toggleContent = _a.toggleContent, actionContent = _a.actionContent, selectorContent = _a.selectorContent, className = _a.className, _b = _a.direction, direction = _b === void 0 ? DropdownDirection.BOTTOM : _b, selectorClass = _a.selectorClass, contentClass = _a.contentClass, dropdownContentStyle = _a.dropdownContentStyle, children = _a.children, noArrow = _a.noArrow, activeClass = _a.activeClass, oneLine = _a.oneLine, hover = _a.hover, sideMenuInMobile = _a.sideMenuInMobile, sideMenuTitle = _a.sideMenuTitle, containerStyle = _a.containerStyle;
    var isMobile = useIsMobile();
    var node = useRef(null);
    var _c = __read(useState(active || false), 2), isDropdownActive = _c[0], setDropdownActive = _c[1];
    /**
     * Alert if clicked on outside of element
     */
    var handleClickOutside = function (_a) {
        var target = _a.target;
        if (isDropdownActive && node && !node.current.contains(target)) {
            setDropdownActive(false);
        }
    };
    useEffect(function () {
        document.addEventListener('mouseup', handleClickOutside);
        return function () {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, [isDropdownActive, node]);
    useEffect(function () {
        setDropdownActive(false);
    }, [forceClose]);
    var toggleClick = function (forcedValue) {
        if (isMobile && sideMenuInMobile) {
            sideMenuShow({
                unmount: true,
                content: (React.createElement("div", { className: styles('dropdown-side-menu') },
                    React.createElement("div", { className: styles('head') }, sideMenuTitle),
                    React.createElement("div", { className: styles('body') },
                        " ",
                        children))),
            });
        }
        else {
            if (!isDropdownActive)
                action === null || action === void 0 ? void 0 : action();
            setDropdownActive(forcedValue || !isDropdownActive);
        }
    };
    var onAction = function (event) {
        if (toggleContent)
            setDropdownActive(!isDropdownActive);
        if (actionContent)
            actionContent(event);
    };
    return (React.createElement("div", { className: styles('Dropdown', className, noArrow && 'no-triangle', direction, oneLine && 'one-line'), style: containerStyle, onMouseEnter: function () { return (hover ? toggleClick(true) : null); }, onMouseLeave: function () { return (hover ? toggleClick(false) : null); }, ref: node },
        React.createElement("div", { className: styles('dropdown-selector', isDropdownActive && 'active', isDropdownActive && activeClass, selectorClass), onClick: function () { return toggleClick(false); } },
            React.createElement("div", { className: styles('dropdown-selector-content') }, selectorContent),
            !noArrow && (React.createElement("div", { className: styles('arrow'), onMouseEnter: function () {
                    if (hover)
                        toggleClick(true);
                } }))),
        React.createElement("div", { className: styles('dropdown-content', isDropdownActive && 'active', contentClass), style: dropdownContentStyle, onClick: onAction }, children)));
};
