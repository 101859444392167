import { __assign, __read } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { FormProvider, useForm } from 'react-hook-form';
import { useCreateExternalMailbox, } from 'shared/hooks';
import { Button, ButtonColor, Checkbox, Input } from 'shared/io';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { useDisclosure } from '@mantine/hooks';
import logoMicrosoft from '../../../../shared/components/Logo/assets/logo-microsoft.svg';
import SignatureEditorModal from '../SignatureEditorModal';
import OAauthModal from './OAuthModal';
import styleIdentifiers from './ExternalMailboxSettingsForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var ExternalMailboxSettingsForm = function (_a) {
    var _b, _c;
    var onSubmit = _a.onSubmit, externalMailboxConfiguration = _a.externalMailboxConfiguration, edit = _a.edit;
    var t = useTranslation().t;
    var _d = __read(useDisclosure(false), 2), isSignatureModalOpen = _d[0], _e = _d[1], openSignatureModal = _e.open, closeSignatureModal = _e.close;
    var form = useForm({
        shouldUnregister: true,
        defaultValues: __assign(__assign({}, externalMailboxConfiguration === null || externalMailboxConfiguration === void 0 ? void 0 : externalMailboxConfiguration.attributes), { is_microsoft: (_c = (_b = externalMailboxConfiguration === null || externalMailboxConfiguration === void 0 ? void 0 : externalMailboxConfiguration.attributes) === null || _b === void 0 ? void 0 : _b.is_microsoft) !== null && _c !== void 0 ? _c : undefined }),
    });
    var register = form.register, watch = form.watch, errors = form.formState.errors, handleSubmit = form.handleSubmit;
    var signature = form.getValues('signature');
    var microsoftLogo = (React.createElement("img", { className: styles('microsoft-logo'), src: logoMicrosoft, alt: "microsoft-logo" }));
    var createExternalMailbox = useCreateExternalMailbox().createExternalMailbox;
    var openConfirmationModal = function () {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            keepMountOnExit: true,
            children: (React.createElement(OAauthModal, { confirmButtonColor: ButtonColor.WHITE, onConfirm: function (data) {
                    createExternalMailbox(data, {
                        onSuccess: function (_a) {
                            var redirect_uri = _a.data.redirect_uri;
                            window.location.replace(redirect_uri);
                        },
                    });
                } }, microsoftLogo)),
        });
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('external-mailbox-form'), onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", { className: styles('head') }, t(externalMailboxConfiguration
                ? i18nKeys.SETTINGS.EXTERNAL_MAILBOX.EDIT
                : i18nKeys.SETTINGS.EXTERNAL_MAILBOX.ADD)),
            !edit && (React.createElement("div", { className: styles('quick-setup') },
                React.createElement("h3", null, t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.QUICK_SETUP)),
                React.createElement(Button, { label: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.SIGN_IN_WITH.MICROSOFT), color: ButtonColor.WHITE, noShadow: true, childrenBefore: true, onClick: openConfirmationModal }, microsoftLogo))),
            React.createElement("div", { className: styles('content') },
                !edit && React.createElement("h3", null, t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.MANUAL_SETUP)),
                React.createElement(Input, { register: register('host', {
                        required: true,
                    }), errorMessage: get(errors, 'host'), label: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.HOST), withBorder: true, noMargin: true }),
                React.createElement(Input, { type: "number", register: register('port', {
                        required: true,
                    }), errorMessage: get(errors, 'port'), label: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.PORT), withBorder: true }),
                React.createElement(Checkbox, { register: register('ssl'), watch: watch, label: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.SSL).toUpperCase(), name: "ssl" }),
                React.createElement(Input, { type: "text", register: register('login', {
                        required: true,
                    }), errorMessage: get(errors, 'login'), label: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.LOGIN), withBorder: true }),
                React.createElement(Input, { type: "password", register: register('password', {
                        required: !edit,
                    }), errorMessage: get(errors, 'password'), label: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.PASSWORD), withBorder: true }),
                React.createElement("div", { style: { marginTop: '30px' } }),
                React.createElement(Button, { onClick: openSignatureModal }, t(i18nKeys.EDIT_SIGNATURE))),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { type: "submit", label: t(i18nKeys.SAVE), noShadow: true, noMargin: true }))),
        React.createElement(SignatureEditorModal, { isOpen: isSignatureModalOpen, onClose: closeSignatureModal, onSave: function (htmlVal) { return form.setValue('signature', htmlVal); }, signature: signature !== null && signature !== void 0 ? signature : null })));
};
