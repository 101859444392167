import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useAxiosInstance, } from '../../../shared/hooks/utils';
var Reminder = z.object({
    id: z.number(),
});
export var RemindersSchema = z.array(Reminder);
export function loadRemindersQueryFn(axiosInstance) {
    return __awaiter(this, void 0, void 0, function () {
        var instance, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [4 /*yield*/, instance.get('/payment_requests')];
                case 2:
                    data = (_a.sent()).data;
                    return [2 /*return*/, RemindersSchema.parse(data)];
            }
        });
    });
}
export var useLoadReminders = function () {
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['reminders'],
        queryFn: function () { return loadRemindersQueryFn(axiosInstance); },
    });
    return addResourceNameToQueryResult('reminderIndex', queryResult);
};
