import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';
var InvoiceFeeSchema = z.object({
    id: z.number(),
    reference: z.string(),
    collectionFee: z.preprocess(Number, z.number()),
    gcollectCollectionRate: z.preprocess(Number, z.number()),
    remainingAmount: z.preprocess(Number, z.number()),
});
var GcollectCaseFeeSchema = z.object({
    invoices: z.array(InvoiceFeeSchema),
});
export function loadGcollectCaseFeeFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, params, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    params = new URLSearchParams();
                    queryKey[1].forEach(function (caseId) { return params.append('third_party_case_ids[]', caseId); });
                    return [4 /*yield*/, instance.get("/gcollect_cases/rates?".concat(params.toString()))];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, GcollectCaseFeeSchema.parse(data)];
            }
        });
    });
}
export var useLoadGcollectCaseFee = function (caseIds) {
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['gcollectCaseFee', caseIds],
        queryFn: function (context) { return loadGcollectCaseFeeFn(axiosInstance, context); },
    });
    return addResourceNameToQueryResult('gcollectCaseFee', queryResult);
};
