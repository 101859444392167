import { __assign, __read, __spreadArray } from "tslib";
/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recovr Private API
 * This is the private API for Recovr
 * OpenAPI spec version: 1.0
 */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { customInstance } from './mutator/custom-instance';
/**
 * @summary Get activities for query parameters
 */
export var getActivities = function (params, signal) {
    return customInstance({
        url: "/private_api/activities",
        method: 'GET',
        params: params,
        signal: signal,
    });
};
export var getGetActivitiesQueryKey = function (params) {
    return __spreadArray(["/private_api/activities"], __read((params ? [params] : [])), false);
};
export var getGetActivitiesQueryOptions = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetActivitiesQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getActivities(params, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Get activities for query parameters
 */
export function useGetActivities(params, options) {
    var queryOptions = getGetActivitiesQueryOptions(params, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
export var getPrivateConstants = function (params, signal) {
    return customInstance({
        url: "/private_api/private_constants",
        method: 'GET',
        params: params,
        signal: signal,
    });
};
export var getGetPrivateConstantsQueryKey = function (params) {
    return __spreadArray(["/private_api/private_constants"], __read((params ? [params] : [])), false);
};
export var getGetPrivateConstantsQueryOptions = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetPrivateConstantsQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getPrivateConstants(params, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
export function useGetPrivateConstants(params, options) {
    var queryOptions = getGetPrivateConstantsQueryOptions(params, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Get debtors for query parameters
 */
export var getDebtors = function (params, signal) {
    return customInstance({
        url: "/private_api/debtors",
        method: 'GET',
        params: params,
        signal: signal,
    });
};
export var getGetDebtorsQueryKey = function (params) {
    return __spreadArray(["/private_api/debtors"], __read((params ? [params] : [])), false);
};
export var getGetDebtorsInfiniteQueryOptions = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDebtorsQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal, pageParam = _a.pageParam;
        return getDebtors(__assign(__assign({}, params), { page: pageParam || (params === null || params === void 0 ? void 0 : params['page']) }), signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Get debtors for query parameters
 */
export function useGetDebtorsInfinite(params, options) {
    var queryOptions = getGetDebtorsInfiniteQueryOptions(params, options);
    var query = useInfiniteQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
export var getGetDebtorsQueryOptions = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDebtorsQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getDebtors(params, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Get debtors for query parameters
 */
export function useGetDebtors(params, options) {
    var queryOptions = getGetDebtorsQueryOptions(params, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Get debtor details
 */
export var getDebtor = function (id, params, signal) {
    return customInstance({
        url: "/private_api/debtors/".concat(id),
        method: 'GET',
        params: params,
        signal: signal,
    });
};
export var getGetDebtorQueryKey = function (id, params) {
    return __spreadArray(["/private_api/debtors/".concat(id)], __read((params ? [params] : [])), false);
};
export var getGetDebtorQueryOptions = function (id, params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDebtorQueryKey(id, params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getDebtor(id, params, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn, enabled: !!id }, queryOptions);
};
/**
 * @summary Get debtor details
 */
export function useGetDebtor(id, params, options) {
    var queryOptions = getGetDebtorQueryOptions(id, params, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Mark a debtor as disputed
 */
export var disputeDebtor = function (id, disputeDebtorBody, signal) {
    return customInstance({
        url: "/private_api/debtors/".concat(id, "/dispute_debtor"),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: disputeDebtorBody,
        signal: signal,
    });
};
export var getDisputeDebtorMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return disputeDebtor(id, data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Mark a debtor as disputed
 */
export var useDisputeDebtor = function (options) {
    var mutationOptions = getDisputeDebtorMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get a debtor's emails and activities sorted by date
 */
export var getDebtorTimeline = function (id, params, signal) {
    return customInstance({
        url: "/private_api/debtors/".concat(id, "/timeline"),
        method: 'GET',
        params: params,
        signal: signal,
    });
};
export var getGetDebtorTimelineQueryKey = function (id, params) {
    return __spreadArray(["/private_api/debtors/".concat(id, "/timeline")], __read((params ? [params] : [])), false);
};
export var getGetDebtorTimelineQueryOptions = function (id, params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDebtorTimelineQueryKey(id, params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getDebtorTimeline(id, params, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn, enabled: !!id }, queryOptions);
};
/**
 * @summary Get a debtor's emails and activities sorted by date
 */
export function useGetDebtorTimeline(id, params, options) {
    var queryOptions = getGetDebtorTimelineQueryOptions(id, params, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Get products for query parameters
 */
export var getProducts = function (params, signal) {
    return customInstance({
        url: "/private_api/products",
        method: 'GET',
        params: params,
        signal: signal,
    });
};
export var getGetProductsQueryKey = function (params) {
    return __spreadArray(["/private_api/products"], __read((params ? [params] : [])), false);
};
export var getGetProductsQueryOptions = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetProductsQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getProducts(params, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Get products for query parameters
 */
export function useGetProducts(params, options) {
    var queryOptions = getGetProductsQueryOptions(params, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Gets an invoice
 */
export var getInvoices = function (id, signal) {
    return customInstance({
        url: "/private_api/invoices/".concat(id),
        method: 'GET',
        signal: signal,
    });
};
export var getGetInvoicesQueryKey = function (id) {
    return ["/private_api/invoices/".concat(id)];
};
export var getGetInvoicesQueryOptions = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetInvoicesQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getInvoices(id, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn, enabled: !!id }, queryOptions);
};
/**
 * @summary Gets an invoice
 */
export function useGetInvoices(id, options) {
    var queryOptions = getGetInvoicesQueryOptions(id, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Updates an invoice
 */
export var updateInvoice = function (id, updateInvoiceBody) {
    return customInstance({
        url: "/private_api/invoices/".concat(id),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: updateInvoiceBody,
    });
};
export var getUpdateInvoiceMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateInvoice(id, data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Updates an invoice
 */
export var useUpdateInvoice = function (options) {
    var mutationOptions = getUpdateInvoiceMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Search invoices for query parameters
 */
export var searchInvoices = function (params, signal) {
    return customInstance({
        url: "/private_api/credit_note/invoices",
        method: 'GET',
        params: params,
        signal: signal,
    });
};
export var getSearchInvoicesQueryKey = function (params) {
    return __spreadArray(["/private_api/credit_note/invoices"], __read((params ? [params] : [])), false);
};
export var getSearchInvoicesInfiniteQueryOptions = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getSearchInvoicesQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal, pageParam = _a.pageParam;
        return searchInvoices(__assign(__assign({}, params), { page: pageParam || (params === null || params === void 0 ? void 0 : params['page']) }), signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Search invoices for query parameters
 */
export function useSearchInvoicesInfinite(params, options) {
    var queryOptions = getSearchInvoicesInfiniteQueryOptions(params, options);
    var query = useInfiniteQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
export var getSearchInvoicesQueryOptions = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getSearchInvoicesQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return searchInvoices(params, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Search invoices for query parameters
 */
export function useSearchInvoices(params, options) {
    var queryOptions = getSearchInvoicesQueryOptions(params, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Get recovery plans list
 */
export var getRecoveryPlans = function (signal) {
    return customInstance({
        url: "/private_api/recovery_plans",
        method: 'GET',
        signal: signal,
    });
};
export var getGetRecoveryPlansQueryKey = function () {
    return ["/private_api/recovery_plans"];
};
export var getGetRecoveryPlansQueryOptions = function (options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetRecoveryPlansQueryKey();
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getRecoveryPlans(signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Get recovery plans list
 */
export function useGetRecoveryPlans(options) {
    var queryOptions = getGetRecoveryPlansQueryOptions(options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Create a recovery plan
 */
export var createRecoveryPlan = function (createRecoveryPlanBody, signal) {
    return customInstance({
        url: "/private_api/recovery_plans",
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: createRecoveryPlanBody,
        signal: signal,
    });
};
export var getCreateRecoveryPlanMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createRecoveryPlan(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Create a recovery plan
 */
export var useCreateRecoveryPlan = function (options) {
    var mutationOptions = getCreateRecoveryPlanMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get a email domain stored in DB
 */
export var getEmailDomain = function (signal) {
    return customInstance({
        url: "/private_api/email_domain",
        method: 'GET',
        signal: signal,
    });
};
export var getGetEmailDomainQueryKey = function () {
    return ["/private_api/email_domain"];
};
export var getGetEmailDomainQueryOptions = function (options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetEmailDomainQueryKey();
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getEmailDomain(signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Get a email domain stored in DB
 */
export function useGetEmailDomain(options) {
    var queryOptions = getGetEmailDomainQueryOptions(options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Get a domain from Sendgrid
 */
export var getDomain = function (id, signal) {
    return customInstance({
        url: "/private_api/sendgrid/authenticate_domains/".concat(id),
        method: 'GET',
        signal: signal,
    });
};
export var getGetDomainQueryKey = function (id) {
    return ["/private_api/sendgrid/authenticate_domains/".concat(id)];
};
export var getGetDomainQueryOptions = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDomainQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getDomain(id, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn, enabled: !!id }, queryOptions);
};
/**
 * @summary Get a domain from Sendgrid
 */
export function useGetDomain(id, options) {
    var queryOptions = getGetDomainQueryOptions(id, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Update a domain from Sendgrid
 */
export var updateDomain = function (id) {
    return customInstance({
        url: "/private_api/sendgrid/authenticate_domains/".concat(id),
        method: 'PUT',
    });
};
export var getUpdateDomainMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return updateDomain(id);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Update a domain from Sendgrid
 */
export var useUpdateDomain = function (options) {
    var mutationOptions = getUpdateDomainMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Delete a domain
 */
export var deleteDomain = function (id) {
    return customInstance({
        url: "/private_api/sendgrid/authenticate_domains/".concat(id),
        method: 'DELETE',
    });
};
export var getDeleteDomainMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteDomain(id);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Delete a domain
 */
export var useDeleteDomain = function (options) {
    var mutationOptions = getDeleteDomainMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Authenticate a domain
 */
export var authenticateDomain = function (authenticateDomainBody, signal) {
    return customInstance({
        url: "/private_api/sendgrid/authenticate_domains",
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: authenticateDomainBody,
        signal: signal,
    });
};
export var getAuthenticateDomainMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return authenticateDomain(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Authenticate a domain
 */
export var useAuthenticateDomain = function (options) {
    var mutationOptions = getAuthenticateDomainMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get a debtor sending options
 */
export var getDebtorSendingOptions = function (id, signal) {
    return customInstance({
        url: "/private_api/debtors/".concat(id, "/sending_options"),
        method: 'GET',
        signal: signal,
    });
};
export var getGetDebtorSendingOptionsQueryKey = function (id) {
    return ["/private_api/debtors/".concat(id, "/sending_options")];
};
export var getGetDebtorSendingOptionsQueryOptions = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDebtorSendingOptionsQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getDebtorSendingOptions(id, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn, enabled: !!id }, queryOptions);
};
/**
 * @summary Get a debtor sending options
 */
export function useGetDebtorSendingOptions(id, options) {
    var queryOptions = getGetDebtorSendingOptionsQueryOptions(id, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Get a recovery plan
 */
export var getRecoveryPlan = function (id, signal) {
    return customInstance({
        url: "/private_api/recovery_plans/".concat(id),
        method: 'GET',
        signal: signal,
    });
};
export var getGetRecoveryPlanQueryKey = function (id) {
    return ["/private_api/recovery_plans/".concat(id)];
};
export var getGetRecoveryPlanQueryOptions = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetRecoveryPlanQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getRecoveryPlan(id, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn, enabled: !!id }, queryOptions);
};
/**
 * @summary Get a recovery plan
 */
export function useGetRecoveryPlan(id, options) {
    var queryOptions = getGetRecoveryPlanQueryOptions(id, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Update a recovery plan
 */
export var updateRecoveryPlan = function (id, createRecoveryPlanBody) {
    return customInstance({
        url: "/private_api/recovery_plans/".concat(id),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: createRecoveryPlanBody,
    });
};
export var getUpdateRecoveryPlanMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateRecoveryPlan(id, data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Update a recovery plan
 */
export var useUpdateRecoveryPlan = function (options) {
    var mutationOptions = getUpdateRecoveryPlanMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Delete a recovery plan
 */
export var deleteRecoveryPlan = function (id) {
    return customInstance({
        url: "/private_api/recovery_plans/".concat(id),
        method: 'DELETE',
    });
};
export var getDeleteRecoveryPlanMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteRecoveryPlan(id);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Delete a recovery plan
 */
export var useDeleteRecoveryPlan = function (options) {
    var mutationOptions = getDeleteRecoveryPlanMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get reasons list
 */
export var getReasons = function (params, signal) {
    return customInstance({
        url: "/private_api/reasons",
        method: 'GET',
        params: params,
        signal: signal,
    });
};
export var getGetReasonsQueryKey = function (params) {
    return __spreadArray(["/private_api/reasons"], __read((params ? [params] : [])), false);
};
export var getGetReasonsQueryOptions = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetReasonsQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getReasons(params, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Get reasons list
 */
export function useGetReasons(params, options) {
    var queryOptions = getGetReasonsQueryOptions(params, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Create a reason
 */
export var createReason = function (createReasonBody, signal) {
    return customInstance({
        url: "/private_api/reasons",
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: createReasonBody,
        signal: signal,
    });
};
export var getCreateReasonMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createReason(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Create a reason
 */
export var useCreateReason = function (options) {
    var mutationOptions = getCreateReasonMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Update a reason
 */
export var updateReason = function (id, createReasonBody) {
    return customInstance({
        url: "/private_api/reasons/".concat(id),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: createReasonBody,
    });
};
export var getUpdateReasonMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateReason(id, data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Update a reason
 */
export var useUpdateReason = function (options) {
    var mutationOptions = getUpdateReasonMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Delete a reason
 */
export var deleteReason = function (id) {
    return customInstance({ url: "/private_api/reasons/".concat(id), method: 'DELETE' });
};
export var getDeleteReasonMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteReason(id);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Delete a reason
 */
export var useDeleteReason = function (options) {
    var mutationOptions = getDeleteReasonMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get invoices to confirm list
 */
export var getInvoicesToConfirm = function (params, signal) {
    return customInstance({
        url: "/private_api/import_invoices",
        method: 'GET',
        params: params,
        signal: signal,
    });
};
export var getGetInvoicesToConfirmQueryKey = function (params) {
    return __spreadArray(["/private_api/import_invoices"], __read((params ? [params] : [])), false);
};
export var getGetInvoicesToConfirmQueryOptions = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetInvoicesToConfirmQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getInvoicesToConfirm(params, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Get invoices to confirm list
 */
export function useGetInvoicesToConfirm(params, options) {
    var queryOptions = getGetInvoicesToConfirmQueryOptions(params, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Upload invoices CSV
 */
export var uploadInvoiceCSV = function (uploadInvoiceCSVBody, signal) {
    return customInstance({
        url: "/private_api/import_invoices",
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: uploadInvoiceCSVBody,
        signal: signal,
    });
};
export var getUploadInvoiceCSVMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return uploadInvoiceCSV(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Upload invoices CSV
 */
export var useUploadInvoiceCSV = function (options) {
    var mutationOptions = getUploadInvoiceCSVMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Delete an unvalidated invoice
 */
export var deleteUnvalidatedInvoice = function (id) {
    return customInstance({
        url: "/private_api/import_invoices/".concat(id),
        method: 'DELETE',
    });
};
export var getDeleteUnvalidatedInvoiceMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteUnvalidatedInvoice(id);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Delete an unvalidated invoice
 */
export var useDeleteUnvalidatedInvoice = function (options) {
    var mutationOptions = getDeleteUnvalidatedInvoiceMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get debtors to confirm list
 */
export var getDebtorsToConfirm = function (params, signal) {
    return customInstance({
        url: "/private_api/import_debtors",
        method: 'GET',
        params: params,
        signal: signal,
    });
};
export var getGetDebtorsToConfirmQueryKey = function (params) {
    return __spreadArray(["/private_api/import_debtors"], __read((params ? [params] : [])), false);
};
export var getGetDebtorsToConfirmQueryOptions = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDebtorsToConfirmQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getDebtorsToConfirm(params, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Get debtors to confirm list
 */
export function useGetDebtorsToConfirm(params, options) {
    var queryOptions = getGetDebtorsToConfirmQueryOptions(params, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Upload a debtor CSV
 */
export var uploadDebtorCSV = function (uploadDebtorCSVBody, signal) {
    return customInstance({
        url: "/private_api/import_debtors",
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: uploadDebtorCSVBody,
        signal: signal,
    });
};
export var getUploadDebtorCSVMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return uploadDebtorCSV(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Upload a debtor CSV
 */
export var useUploadDebtorCSV = function (options) {
    var mutationOptions = getUploadDebtorCSVMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get debtor to confirm details
 */
export var getDebtorToConfirm = function (id, signal) {
    return customInstance({
        url: "/private_api/import_debtors/".concat(id),
        method: 'GET',
        signal: signal,
    });
};
export var getGetDebtorToConfirmQueryKey = function (id) {
    return ["/private_api/import_debtors/".concat(id)];
};
export var getGetDebtorToConfirmQueryOptions = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetDebtorToConfirmQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getDebtorToConfirm(id, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn, enabled: !!id }, queryOptions);
};
/**
 * @summary Get debtor to confirm details
 */
export function useGetDebtorToConfirm(id, options) {
    var queryOptions = getGetDebtorToConfirmQueryOptions(id, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Delete an unvalidated debtor
 */
export var deleteUnvalidatedDebtor = function (id) {
    return customInstance({
        url: "/private_api/import_debtors/".concat(id),
        method: 'DELETE',
    });
};
export var getDeleteUnvalidatedDebtorMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteUnvalidatedDebtor(id);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Delete an unvalidated debtor
 */
export var useDeleteUnvalidatedDebtor = function (options) {
    var mutationOptions = getDeleteUnvalidatedDebtorMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get similar debtors list
 */
export var getSimilarDebtors = function (signal) {
    return customInstance({
        url: "/private_api/similar_debtors",
        method: 'GET',
        signal: signal,
    });
};
export var getGetSimilarDebtorsQueryKey = function () {
    return ["/private_api/similar_debtors"];
};
export var getGetSimilarDebtorsQueryOptions = function (options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetSimilarDebtorsQueryKey();
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getSimilarDebtors(signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Get similar debtors list
 */
export function useGetSimilarDebtors(options) {
    var queryOptions = getGetSimilarDebtorsQueryOptions(options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Merge similar debtors
 */
export var mergeSimilarDebtors = function (mergeSimilarDebtorsBody, signal) {
    return customInstance({
        url: "/private_api/similar_debtors",
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: mergeSimilarDebtorsBody,
        signal: signal,
    });
};
export var getMergeSimilarDebtorsMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return mergeSimilarDebtors(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Merge similar debtors
 */
export var useMergeSimilarDebtors = function (options) {
    var mutationOptions = getMergeSimilarDebtorsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Delete unvalidated imports (debtors or invoices)
 */
export var deleteUnvalidatedImports = function (deleteUnvalidatedImportsBody) {
    return customInstance({
        url: "/private_api/unvalidated_imports",
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        data: deleteUnvalidatedImportsBody,
    });
};
export var getDeleteUnvalidatedImportsMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return deleteUnvalidatedImports(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Delete unvalidated imports (debtors or invoices)
 */
export var useDeleteUnvalidatedImports = function (options) {
    var mutationOptions = getDeleteUnvalidatedImportsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get similar debtors to given id
 */
export var getMergeableDebtors = function (id, signal) {
    return customInstance({
        url: "/private_api/similar_debtors/".concat(id),
        method: 'GET',
        signal: signal,
    });
};
export var getGetMergeableDebtorsQueryKey = function (id) {
    return ["/private_api/similar_debtors/".concat(id)];
};
export var getGetMergeableDebtorsQueryOptions = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetMergeableDebtorsQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getMergeableDebtors(id, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn, enabled: !!id }, queryOptions);
};
/**
 * @summary Get similar debtors to given id
 */
export function useGetMergeableDebtors(id, options) {
    var queryOptions = getGetMergeableDebtorsQueryOptions(id, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Ignore duplicates
 */
export var ignoreDuplicates = function (id) {
    return customInstance({
        url: "/private_api/similar_debtors/".concat(id),
        method: 'DELETE',
    });
};
export var getIgnoreDuplicatesMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return ignoreDuplicates(id);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Ignore duplicates
 */
export var useIgnoreDuplicates = function (options) {
    var mutationOptions = getIgnoreDuplicatesMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get task types list
 */
export var getTaskTypes = function (signal) {
    return customInstance({
        url: "/private_api/task_types",
        method: 'GET',
        signal: signal,
    });
};
export var getGetTaskTypesQueryKey = function () {
    return ["/private_api/task_types"];
};
export var getGetTaskTypesQueryOptions = function (options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetTaskTypesQueryKey();
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getTaskTypes(signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Get task types list
 */
export function useGetTaskTypes(options) {
    var queryOptions = getGetTaskTypesQueryOptions(options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Create a task type
 */
export var createTaskType = function (createTaskTypeBody, signal) {
    return customInstance({
        url: "/private_api/task_types",
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: createTaskTypeBody,
        signal: signal,
    });
};
export var getCreateTaskTypeMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createTaskType(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Create a task type
 */
export var useCreateTaskType = function (options) {
    var mutationOptions = getCreateTaskTypeMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Update a task type
 */
export var updateTaskType = function (id, createTaskTypeBody) {
    return customInstance({
        url: "/private_api/task_types/".concat(id),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: createTaskTypeBody,
    });
};
export var getUpdateTaskTypeMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateTaskType(id, data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Update a task type
 */
export var useUpdateTaskType = function (options) {
    var mutationOptions = getUpdateTaskTypeMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Delete a task type
 */
export var deleteTaskType = function (id) {
    return customInstance({
        url: "/private_api/task_types/".concat(id),
        method: 'DELETE',
    });
};
export var getDeleteTaskTypeMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteTaskType(id);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Delete a task type
 */
export var useDeleteTaskType = function (options) {
    var mutationOptions = getDeleteTaskTypeMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get tasks list
 */
export var getTasks = function (params, signal) {
    return customInstance({
        url: "/private_api/tasks",
        method: 'GET',
        params: params,
        signal: signal,
    });
};
export var getGetTasksQueryKey = function (params) {
    return __spreadArray(["/private_api/tasks"], __read((params ? [params] : [])), false);
};
export var getGetTasksQueryOptions = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetTasksQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getTasks(params, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Get tasks list
 */
export function useGetTasks(params, options) {
    var queryOptions = getGetTasksQueryOptions(params, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Create a task
 */
export var createTask = function (createTaskBody, signal) {
    return customInstance({
        url: "/private_api/tasks",
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: createTaskBody,
        signal: signal,
    });
};
export var getCreateTaskMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createTask(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Create a task
 */
export var useCreateTask = function (options) {
    var mutationOptions = getCreateTaskMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get a task
 */
export var getTask = function (id, signal) {
    return customInstance({ url: "/private_api/tasks/".concat(id), method: 'GET', signal: signal });
};
export var getGetTaskQueryKey = function (id) {
    return ["/private_api/tasks/".concat(id)];
};
export var getGetTaskQueryOptions = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetTaskQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getTask(id, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn, enabled: !!id }, queryOptions);
};
/**
 * @summary Get a task
 */
export function useGetTask(id, options) {
    var queryOptions = getGetTaskQueryOptions(id, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Update a task
 */
export var updateTask = function (id, createTaskBody) {
    return customInstance({
        url: "/private_api/tasks/".concat(id),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: createTaskBody,
    });
};
export var getUpdateTaskMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateTask(id, data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Update a task
 */
export var useUpdateTask = function (options) {
    var mutationOptions = getUpdateTaskMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Delete a task
 */
export var deleteTask = function (id) {
    return customInstance({ url: "/private_api/tasks/".concat(id), method: 'DELETE' });
};
export var getDeleteTaskMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteTask(id);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Delete a task
 */
export var useDeleteTask = function (options) {
    var mutationOptions = getDeleteTaskMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Create a comment on a task
 */
export var createTaskComment = function (id, createTaskCommentBody, signal) {
    return customInstance({
        url: "/private_api/tasks/".concat(id, "/comments"),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: createTaskCommentBody,
        signal: signal,
    });
};
export var getCreateTaskCommentMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return createTaskComment(id, data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Create a comment on a task
 */
export var useCreateTaskComment = function (options) {
    var mutationOptions = getCreateTaskCommentMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Update tasks status
 */
export var updateTasksStatus = function (updateTasksStatusBody, signal) {
    return customInstance({
        url: "/private_api/tasks/update_status",
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: updateTasksStatusBody,
        signal: signal,
    });
};
export var getUpdateTasksStatusMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return updateTasksStatus(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Update tasks status
 */
export var useUpdateTasksStatus = function (options) {
    var mutationOptions = getUpdateTasksStatusMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * Get available conditions list
 */
export var getAvailableConditions = function (params, signal) {
    return customInstance({
        url: "/private_api/available_conditions",
        method: 'GET',
        params: params,
        signal: signal,
    });
};
export var getGetAvailableConditionsQueryKey = function (params) {
    return __spreadArray(["/private_api/available_conditions"], __read((params ? [params] : [])), false);
};
export var getGetAvailableConditionsQueryOptions = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetAvailableConditionsQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getAvailableConditions(params, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
export function useGetAvailableConditions(params, options) {
    var queryOptions = getGetAvailableConditionsQueryOptions(params, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Get automatic assignations list
 */
export var getAutomaticAssignations = function (params, signal) {
    return customInstance({
        url: "/private_api/automatic_assignations",
        method: 'GET',
        params: params,
        signal: signal,
    });
};
export var getGetAutomaticAssignationsQueryKey = function (params) {
    return __spreadArray(["/private_api/automatic_assignations"], __read((params ? [params] : [])), false);
};
export var getGetAutomaticAssignationsQueryOptions = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetAutomaticAssignationsQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getAutomaticAssignations(params, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
export function useGetAutomaticAssignations(params, options) {
    var queryOptions = getGetAutomaticAssignationsQueryOptions(params, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Create an automatic assignation
 */
export var createAutomaticAssignation = function (createAutomaticAssignationBody, signal) {
    return customInstance({
        url: "/private_api/automatic_assignations",
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: createAutomaticAssignationBody,
        signal: signal,
    });
};
export var getCreateAutomaticAssignationMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createAutomaticAssignation(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
export var useCreateAutomaticAssignation = function (options) {
    var mutationOptions = getCreateAutomaticAssignationMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * Get an automatic assignation
 */
export var getAutomaticAssignation = function (id, signal) {
    return customInstance({
        url: "/private_api/automatic_assignations/".concat(id),
        method: 'GET',
        signal: signal,
    });
};
export var getGetAutomaticAssignationQueryKey = function (id) {
    return ["/private_api/automatic_assignations/".concat(id)];
};
export var getGetAutomaticAssignationQueryOptions = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetAutomaticAssignationQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getAutomaticAssignation(id, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn, enabled: !!id }, queryOptions);
};
export function useGetAutomaticAssignation(id, options) {
    var queryOptions = getGetAutomaticAssignationQueryOptions(id, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Update an automatic assignation
 */
export var updateAutomaticAssignation = function (id, createAutomaticAssignationBody) {
    return customInstance({
        url: "/private_api/automatic_assignations/".concat(id),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: createAutomaticAssignationBody,
    });
};
export var getUpdateAutomaticAssignationMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateAutomaticAssignation(id, data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
export var useUpdateAutomaticAssignation = function (options) {
    var mutationOptions = getUpdateAutomaticAssignationMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * Delete an automatic assignation
 */
export var deleteAutomaticAssignation = function (id) {
    return customInstance({
        url: "/private_api/automatic_assignations/".concat(id),
        method: 'DELETE',
    });
};
export var getDeleteAutomaticAssignationMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteAutomaticAssignation(id);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
export var useDeleteAutomaticAssignation = function (options) {
    var mutationOptions = getDeleteAutomaticAssignationMutationOptions(options);
    return useMutation(mutationOptions);
};
export var getContactPersons = function (id, signal) {
    return customInstance({
        url: "/private_api/debtors/".concat(id, "/contact_persons"),
        method: 'GET',
        signal: signal,
    });
};
export var getGetContactPersonsQueryKey = function (id) {
    return ["/private_api/debtors/".concat(id, "/contact_persons")];
};
export var getGetContactPersonsQueryOptions = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetContactPersonsQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getContactPersons(id, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn, enabled: !!id }, queryOptions);
};
export function useGetContactPersons(id, options) {
    var queryOptions = getGetContactPersonsQueryOptions(id, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
export var createContactPerson = function (id, createContactPersonBody, signal) {
    return customInstance({
        url: "/private_api/debtors/".concat(id, "/contact_persons"),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: createContactPersonBody,
        signal: signal,
    });
};
export var getCreateContactPersonMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return createContactPerson(id, data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
export var useCreateContactPerson = function (options) {
    var mutationOptions = getCreateContactPersonMutationOptions(options);
    return useMutation(mutationOptions);
};
export var getContactPerson = function (id, contactPersonId, signal) {
    return customInstance({
        url: "/private_api/debtors/".concat(id, "/contact_persons/").concat(contactPersonId),
        method: 'GET',
        signal: signal,
    });
};
export var getGetContactPersonQueryKey = function (id, contactPersonId) {
    return ["/private_api/debtors/".concat(id, "/contact_persons/").concat(contactPersonId)];
};
export var getGetContactPersonQueryOptions = function (id, contactPersonId, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetContactPersonQueryKey(id, contactPersonId);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getContactPerson(id, contactPersonId, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn, enabled: !!(id && contactPersonId) }, queryOptions);
};
export function useGetContactPerson(id, contactPersonId, options) {
    var queryOptions = getGetContactPersonQueryOptions(id, contactPersonId, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
export var updateContactPerson = function (id, contactPersonId, createContactPersonBody) {
    return customInstance({
        url: "/private_api/debtors/".concat(id, "/contact_persons/").concat(contactPersonId),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: createContactPersonBody,
    });
};
export var getUpdateContactPersonMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, contactPersonId = _a.contactPersonId, data = _a.data;
        return updateContactPerson(id, contactPersonId, data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
export var useUpdateContactPerson = function (options) {
    var mutationOptions = getUpdateContactPersonMutationOptions(options);
    return useMutation(mutationOptions);
};
export var deleteContactPerson = function (id, contactPersonId) {
    return customInstance({
        url: "/private_api/debtors/".concat(id, "/contact_persons/").concat(contactPersonId),
        method: 'DELETE',
    });
};
export var getDeleteContactPersonMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, contactPersonId = _a.contactPersonId;
        return deleteContactPerson(id, contactPersonId);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
export var useDeleteContactPerson = function (options) {
    var mutationOptions = getDeleteContactPersonMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Create a payment step calculation
 */
export var createPaymentStepCalculation = function (createPaymentStepCalculationBody, signal) {
    return customInstance({
        url: "/private_api/payment_step_calculations",
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: createPaymentStepCalculationBody,
        signal: signal,
    });
};
export var getCreatePaymentStepCalculationMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createPaymentStepCalculation(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Create a payment step calculation
 */
export var useCreatePaymentStepCalculation = function (options) {
    var mutationOptions = getCreatePaymentStepCalculationMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Update a payment step calculation
 */
export var updatePaymentStepCalculation = function (id, updatePaymentStepCalculationBody) {
    return customInstance({
        url: "/private_api/payment_step_calculations/".concat(id),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: updatePaymentStepCalculationBody,
    });
};
export var getUpdatePaymentStepCalculationMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updatePaymentStepCalculation(id, data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Update a payment step calculation
 */
export var useUpdatePaymentStepCalculation = function (options) {
    var mutationOptions = getUpdatePaymentStepCalculationMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get payment plans list
 */
export var getPaymentPlans = function (params, signal) {
    return customInstance({
        url: "/private_api/payment_plans",
        method: 'GET',
        params: params,
        signal: signal,
    });
};
export var getGetPaymentPlansQueryKey = function (params) {
    return __spreadArray(["/private_api/payment_plans"], __read((params ? [params] : [])), false);
};
export var getGetPaymentPlansQueryOptions = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetPaymentPlansQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getPaymentPlans(params, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Get payment plans list
 */
export function useGetPaymentPlans(params, options) {
    var queryOptions = getGetPaymentPlansQueryOptions(params, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Create a payment plan
 */
export var createPaymentPlan = function (createPaymentPlanBody, signal) {
    return customInstance({
        url: "/private_api/payment_plans",
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: createPaymentPlanBody,
        signal: signal,
    });
};
export var getCreatePaymentPlanMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createPaymentPlan(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Create a payment plan
 */
export var useCreatePaymentPlan = function (options) {
    var mutationOptions = getCreatePaymentPlanMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get a payment plan
 */
export var getPaymentPlan = function (id, signal) {
    return customInstance({
        url: "/private_api/payment_plans/".concat(id),
        method: 'GET',
        signal: signal,
    });
};
export var getGetPaymentPlanQueryKey = function (id) {
    return ["/private_api/payment_plans/".concat(id)];
};
export var getGetPaymentPlanQueryOptions = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetPaymentPlanQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getPaymentPlan(id, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn, enabled: !!id }, queryOptions);
};
/**
 * @summary Get a payment plan
 */
export function useGetPaymentPlan(id, options) {
    var queryOptions = getGetPaymentPlanQueryOptions(id, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Update a payment plan
 */
export var updatePaymentPlan = function (id, createPaymentPlanBody) {
    return customInstance({
        url: "/private_api/payment_plans/".concat(id),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: createPaymentPlanBody,
    });
};
export var getUpdatePaymentPlanMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updatePaymentPlan(id, data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Update a payment plan
 */
export var useUpdatePaymentPlan = function (options) {
    var mutationOptions = getUpdatePaymentPlanMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Cancel a payment plan
 */
export var cancelPaymentPlan = function (id, cancelPaymentPlanBody, signal) {
    return customInstance({
        url: "/private_api/payment_plans/".concat(id, "/cancel"),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: cancelPaymentPlanBody,
        signal: signal,
    });
};
export var getCancelPaymentPlanMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return cancelPaymentPlan(id, data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Cancel a payment plan
 */
export var useCancelPaymentPlan = function (options) {
    var mutationOptions = getCancelPaymentPlanMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get workflows list
 */
export var getWorkflows = function (signal) {
    return customInstance({
        url: "/private_api/workflows",
        method: 'GET',
        signal: signal,
    });
};
export var getGetWorkflowsQueryKey = function () {
    return ["/private_api/workflows"];
};
export var getGetWorkflowsQueryOptions = function (options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetWorkflowsQueryKey();
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getWorkflows(signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Get workflows list
 */
export function useGetWorkflows(options) {
    var queryOptions = getGetWorkflowsQueryOptions(options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Create a workflow
 */
export var createWorkflow = function (createWorkflowBody, signal) {
    return customInstance({
        url: "/private_api/workflows",
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: createWorkflowBody,
        signal: signal,
    });
};
export var getCreateWorkflowMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createWorkflow(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Create a workflow
 */
export var useCreateWorkflow = function (options) {
    var mutationOptions = getCreateWorkflowMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get a workflow
 */
export var getWorkflow = function (id, signal) {
    return customInstance({
        url: "/private_api/workflows/".concat(id),
        method: 'GET',
        signal: signal,
    });
};
export var getGetWorkflowQueryKey = function (id) {
    return ["/private_api/workflows/".concat(id)];
};
export var getGetWorkflowQueryOptions = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetWorkflowQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getWorkflow(id, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn, enabled: !!id }, queryOptions);
};
/**
 * @summary Get a workflow
 */
export function useGetWorkflow(id, options) {
    var queryOptions = getGetWorkflowQueryOptions(id, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Update a workflow
 */
export var updateWorkflow = function (id, createWorkflowBody) {
    return customInstance({
        url: "/private_api/workflows/".concat(id),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: createWorkflowBody,
    });
};
export var getUpdateWorkflowMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateWorkflow(id, data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Update a workflow
 */
export var useUpdateWorkflow = function (options) {
    var mutationOptions = getUpdateWorkflowMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Delete a workflow
 */
export var deleteWorkflow = function (id) {
    return customInstance({
        url: "/private_api/workflows/".concat(id),
        method: 'DELETE',
    });
};
export var getDeleteWorkflowMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteWorkflow(id);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Delete a workflow
 */
export var useDeleteWorkflow = function (options) {
    var mutationOptions = getDeleteWorkflowMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get Templates list
 */
export var getTemplates = function (params, signal) {
    return customInstance({
        url: "/private_api/templates",
        method: 'GET',
        params: params,
        signal: signal,
    });
};
export var getGetTemplatesQueryKey = function (params) {
    return __spreadArray(["/private_api/templates"], __read((params ? [params] : [])), false);
};
export var getGetTemplatesQueryOptions = function (params, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetTemplatesQueryKey(params);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getTemplates(params, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Get Templates list
 */
export function useGetTemplates(params, options) {
    var queryOptions = getGetTemplatesQueryOptions(params, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Create a template
 */
export var createTemplate = function (createTemplateBody, signal) {
    return customInstance({
        url: "/private_api/templates",
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: createTemplateBody,
        signal: signal,
    });
};
export var getCreateTemplateMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return createTemplate(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Create a template
 */
export var useCreateTemplate = function (options) {
    var mutationOptions = getCreateTemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get a template
 */
export var getTemplate = function (id, signal) {
    return customInstance({
        url: "/private_api/templates/".concat(id),
        method: 'GET',
        signal: signal,
    });
};
export var getGetTemplateQueryKey = function (id) {
    return ["/private_api/templates/".concat(id)];
};
export var getGetTemplateQueryOptions = function (id, options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetTemplateQueryKey(id);
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getTemplate(id, signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn, enabled: !!id }, queryOptions);
};
/**
 * @summary Get a template
 */
export function useGetTemplate(id, options) {
    var queryOptions = getGetTemplateQueryOptions(id, options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Update a template
 */
export var updateTemplate = function (id, updateTemplateBody) {
    return customInstance({
        url: "/private_api/templates/".concat(id),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: updateTemplateBody,
    });
};
export var getUpdateTemplateMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var _a = props !== null && props !== void 0 ? props : {}, id = _a.id, data = _a.data;
        return updateTemplate(id, data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Update a template
 */
export var useUpdateTemplate = function (options) {
    var mutationOptions = getUpdateTemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Delete a template
 */
export var deleteTemplate = function (id) {
    return customInstance({
        url: "/private_api/templates/".concat(id),
        method: 'DELETE',
    });
};
export var getDeleteTemplateMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var id = (props !== null && props !== void 0 ? props : {}).id;
        return deleteTemplate(id);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Delete a template
 */
export var useDeleteTemplate = function (options) {
    var mutationOptions = getDeleteTemplateMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Request a payment plan for debtor portal
 */
export var requestPaymentPlan = function (requestPaymentPlanBody, signal) {
    return customInstance({
        url: "/private_api/request_payment_plan",
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: requestPaymentPlanBody,
        signal: signal,
    });
};
export var getRequestPaymentPlanMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return requestPaymentPlan(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Request a payment plan for debtor portal
 */
export var useRequestPaymentPlan = function (options) {
    var mutationOptions = getRequestPaymentPlanMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Decline a payment plan request make from debtor portal
 */
export var declinePaymentPlanRequest = function (declinePaymentPlanRequestBody, signal) {
    return customInstance({
        url: "/private_api/decline_payment_plan_request",
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: declinePaymentPlanRequestBody,
        signal: signal,
    });
};
export var getDeclinePaymentPlanRequestMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return declinePaymentPlanRequest(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Decline a payment plan request make from debtor portal
 */
export var useDeclinePaymentPlanRequest = function (options) {
    var mutationOptions = getDeclinePaymentPlanRequestMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Get the current user encoded JWT
 */
export var getEmbedUserJWT = function (signal) {
    return customInstance({ url: "/private_api/jwt", method: 'GET', signal: signal });
};
export var getGetEmbedUserJWTQueryKey = function () {
    return ["/private_api/jwt"];
};
export var getGetEmbedUserJWTQueryOptions = function (options) {
    var _a;
    var queryOptions = (options !== null && options !== void 0 ? options : {}).query;
    var queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetEmbedUserJWTQueryKey();
    var queryFn = function (_a) {
        var signal = _a.signal;
        return getEmbedUserJWT(signal);
    };
    return __assign({ queryKey: queryKey, queryFn: queryFn }, queryOptions);
};
/**
 * @summary Get the current user encoded JWT
 */
export function useGetEmbedUserJWT(options) {
    var queryOptions = getGetEmbedUserJWTQueryOptions(options);
    var query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * @summary Reconcile payments and invoices
 */
export var manualReconciliation = function (reconcilePaymentsAndInvoicesBody, signal) {
    return customInstance({
        url: "/private_api/manual_reconciliation",
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: reconcilePaymentsAndInvoicesBody,
        signal: signal,
    });
};
export var getManualReconciliationMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function (props) {
        var data = (props !== null && props !== void 0 ? props : {}).data;
        return manualReconciliation(data);
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Reconcile payments and invoices
 */
export var useManualReconciliation = function (options) {
    var mutationOptions = getManualReconciliationMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
 * @summary Reset the accounting integration
 */
export var resetAccountingIntegration = function (signal) {
    return customInstance({
        url: "/private_api/accounting_integrations/reset",
        method: 'POST',
        signal: signal,
    });
};
export var getResetAccountingIntegrationMutationOptions = function (options) {
    var mutationOptions = (options !== null && options !== void 0 ? options : {}).mutation;
    var mutationFn = function () {
        return resetAccountingIntegration();
    };
    return __assign({ mutationFn: mutationFn }, mutationOptions);
};
/**
 * @summary Reset the accounting integration
 */
export var useResetAccountingIntegration = function (options) {
    var mutationOptions = getResetAccountingIntegrationMutationOptions(options);
    return useMutation(mutationOptions);
};
export * from './index';
