import { __assign } from "tslib";
import React, { useEffect } from 'react';
import { useCreateWorkflow, useDeleteWorkflow, useGetWorkflow, useUpdateWorkflow } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory, useRouteMatch } from 'react-router';
import RecovrLogoLoader from 'shared/components/Loader';
import { WorkflowForm } from './WorkflowForm';
import { formatValues, getDefaultValues, removeStepId } from './WorkflowForm.utils';
import styleIdentifiers from './WorkflowForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var WorkflowFormEdit = function () {
    var _a = useRouteMatch().params, planType = _a.planType, id = _a.id;
    var history = useHistory();
    var t = useTranslation().t;
    var _b = useGetWorkflow(id, { query: { enabled: false } }), workflowResponse = _b.data, isLoading = _b.isLoading, isError = _b.isError, refetch = _b.refetch;
    var _c = useUpdateWorkflow(), updateWorkflow = _c.mutate, isLoadingUpdate = _c.isPending;
    var _d = useCreateWorkflow(), createWorkflow = _d.mutate, isLoadingCreate = _d.isPending;
    var _e = useDeleteWorkflow(), deleteWorkflow = _e.mutate, isLoadingDelete = _e.isPending;
    var isProcessing = isLoadingUpdate || isLoadingCreate || isLoadingDelete;
    useEffect(function () {
        if (id) {
            refetch();
        }
    }, [id, refetch]);
    if (isLoading && !workflowResponse) {
        return React.createElement(RecovrLogoLoader, null);
    }
    if (isError || !workflowResponse) {
        return React.createElement("span", null, "Error");
    }
    var workflow = workflowResponse.data;
    var onSubmit = function (data) {
        return updateWorkflow({ id: id, data: formatValues(data) }, {
            onSuccess: function () {
                refetch();
            },
        });
    };
    var onDuplicate = function () {
        var _a = formatValues(__assign({}, getDefaultValues(planType, workflow))), name = _a.name, plan_type = _a.plan_type, before_due_date_steps = _a.before_due_date_steps, after_due_date_steps = _a.after_due_date_steps;
        createWorkflow({
            data: {
                name: "".concat(name, "_").concat(t(i18nKeys.COPY).toLowerCase()),
                plan_type: plan_type || planType,
                before_due_date_steps: before_due_date_steps.map(removeStepId),
                after_due_date_steps: after_due_date_steps.map(removeStepId),
            },
        }, {
            onSuccess: function (response) {
                history.push("/settings/workflows/".concat(planType, "/edit/").concat(response.data.id));
            },
        });
    };
    var onDelete = function () {
        return deleteWorkflow({ id: id }, {
            onSuccess: function () {
                history.push("/settings/workflows/".concat(planType));
            },
        });
    };
    return (React.createElement("div", { className: styles('workflows-edit') },
        React.createElement(WorkflowForm, { planType: planType, onSubmit: onSubmit, onDuplicate: onDuplicate, onDelete: onDelete, workflow: workflow, isLoading: isProcessing })));
};
