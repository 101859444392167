import { removeAttributes } from 'shared/utils/view';
import { colleagueConstants as events } from 'store/colleague/colleague.actions';
import { baseReducerListPage, handleAddResponse, handleDeleteResponse } from 'store/reducers';
export var initialState = {
    list: baseReducerListPage,
};
export var treatColleague = function (item) {
    var newItem = removeAttributes(item);
    newItem.roles.forEach(function (it) {
        newItem[it] = true;
    });
    return newItem;
};
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case events.add.result:
            return handleAddResponse(state, action, 'list', treatColleague);
        // delete
        case events.delete.result:
            return handleDeleteResponse(state, action, 'list');
        default:
            return state;
    }
};
export default reducer;
