import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { useProfile } from './use-enforce-profile';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';
var NotificationsCountSchema = z
    .object({ notificationsCount: z.number() })
    .transform(function (data) { return data.notificationsCount; });
export function loadNotificationsCountFn(axiosInstance) {
    return __awaiter(this, void 0, void 0, function () {
        var instance, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [4 /*yield*/, instance.get('/notifications/count')];
                case 2:
                    data = (_a.sent()).data;
                    return [2 /*return*/, NotificationsCountSchema.parse(data)];
            }
        });
    });
}
export function useLoadNotificationsCount() {
    var axiosInstance = useAxiosInstance();
    var profile = useProfile();
    var queryResult = useQuery({
        queryKey: ['notifications', 'count'],
        queryFn: function () { return loadNotificationsCountFn(axiosInstance); },
        enabled: profile.hasAcceptedGc,
        placeholderData: 0,
    });
    return addResourceNameToQueryResult('notificationsCount', queryResult);
}
