import { __awaiter, __generator } from "tslib";
import { i18nKeys, useTranslation } from 'locales';
import { z } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { useLegacyAxiosInstance } from './utils';
var CreateExternalMailboxVariablesSchema = z.discriminatedUnion('is_microsoft', [
    z.object({
        is_microsoft: z.literal(false),
        host: z.string(),
        login: z.string().email(),
        password: z.string(),
        port: z.coerce.number(),
        ssl: z.boolean(),
        signature: z.string().nullable().optional(),
    }),
    z.object({
        is_microsoft: z.literal(true),
        login: z.string().email(),
    }),
]);
export function createExternalMailbox(axiosInstance, data) {
    return __awaiter(this, void 0, void 0, function () {
        var instance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [2 /*return*/, instance.post('external_mailbox_configurations', CreateExternalMailboxVariablesSchema.parse(data))];
            }
        });
    });
}
export function useCreateExternalMailbox() {
    var t = useTranslation().t;
    var client = useQueryClient();
    var axiosInstance = useLegacyAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (data) { return createExternalMailbox(axiosInstance, data); },
        onSuccess: function () {
            notifications.show({
                message: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.CREATE_SUCCESS),
                color: 'green',
            });
            client.invalidateQueries({ queryKey: ['externalMailboxes'] });
        },
        onError: function () {
            notifications.show({
                message: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.CREATE_ERROR),
                color: 'red',
            });
        },
    });
    return addActionNameToMutationResult('createExternalMailbox', mutation);
}
