import React, { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group'; // ES6
import { useIsMobile } from 'shared/hooks/utils';
import { animationClassList } from 'shared/utils/view';
import { DialogShowId, DialogShowSize, showDialog, sideMenuHide } from 'store/view/view.actions';
import AYSModal from '../AYSModal';
import { Icon, IconName } from '../Icon';
import styleIdentifiers from './sideMenu.scss';
var styles = classNames.bind(styleIdentifiers);
export default function SideMenu() {
    var sideMenu = useSelector(function (state) { return state.view.sideMenu; });
    var isMobile = useIsMobile();
    var t = useTranslation().t;
    var sideMenuRef = useRef(null);
    var swipeData = {
        totalSize: 0,
        start: 0,
    };
    useEffect(function () {
        if (sideMenu.active) {
            document.addEventListener('keydown', handleKeyPress);
            if (isMobile) {
                swipeData.totalSize = window.innerWidth;
                sideMenuRef.current.addEventListener('touchstart', onTouchStart);
                sideMenuRef.current.addEventListener('touchend', onTouchEnd);
            }
        }
        else {
            document.removeEventListener('keydown', handleKeyPress);
            if (isMobile) {
                sideMenuRef.current.removeEventListener('touchstart', onTouchStart);
                sideMenuRef.current.removeEventListener('touchend', onTouchEnd);
            }
        }
        return function () {
            document.removeEventListener('keydown', handleKeyPress);
            if (isMobile) {
                sideMenuRef.current.removeEventListener('touchstart', onTouchStart);
                sideMenuRef.current.removeEventListener('touchend', onTouchEnd);
            }
        };
    }, [sideMenu.active, sideMenu.askBeforeClose]);
    var handleKeyPress = function (event) { return event.key === 'Escape' && sideMenu.active && hide(); };
    var onTouchStart = function (e) {
        swipeData.start = e.changedTouches[0].screenX;
    };
    var onTouchEnd = function (e) {
        return e.changedTouches[0].screenX - swipeData.start > swipeData.totalSize / 4 && hide();
    };
    var hide = function () {
        if (sideMenu.askBeforeClose) {
            showDialog({
                id: DialogShowId.CONFIRM,
                size: DialogShowSize.SMALL,
                keepMountOnExit: true,
                title: t(i18nKeys.ATTENTION),
                children: (React.createElement(AYSModal, { text: t(i18nKeys.FORM.SIDE_MENU.LOST_INFO), onConfirm: function () { return sideMenuHide(); } })),
            });
        }
        else {
            sideMenuHide();
        }
    };
    var nodeRef = useRef(null);
    return (React.createElement(CSSTransition, { in: sideMenu.active || false, timeout: 400, nodeRef: nodeRef, unmountOnExit: sideMenu.unmount, classNames: animationClassList('side', styles) },
        React.createElement("div", { ref: nodeRef, className: styles('SideMenu', 'right', status), style: {
                zIndex: sideMenu.zIndex,
            } },
            React.createElement("div", { className: styles('background', 'absolute-fill'), onClick: function () { return hide(); } }),
            React.createElement("div", { ref: sideMenuRef, className: styles('menu') },
                React.createElement(Icon, { name: IconName.SMALL_REMOVE, className: styles('close-icon'), onClick: hide }),
                sideMenu.content))));
}
