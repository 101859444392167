import { useMemo } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useGetCompany } from 'shared/utils/selectors';
import { IconBuildingBank, IconHistory, IconLayersLinked } from '@tabler/icons-react';
export function useBuildBankAccountMenu() {
    var t = useTranslation().t;
    var company = useGetCompany();
    return useMemo(function () {
        var items = [
            {
                path: '/transactions/reconciliation',
                label: t(i18nKeys.NAV.RECONCILIATION),
                Icon: IconLayersLinked,
            },
            {
                path: '/transactions/history',
                label: t(i18nKeys.NAV.PAYMENT_BOOK),
                Icon: IconHistory,
            },
        ];
        return {
            parent: null,
            label: t(i18nKeys.COMMON.BANK_ACCOUNT),
            Icon: IconBuildingBank,
            isHidden: (company === null || company === void 0 ? void 0 : company.banking_method) === 'no_bank',
            items: items,
        };
    }, [company, t]);
}
