import { __awaiter, __generator } from "tslib";
import { addResourceNameToQueryResult, useAxiosInstance } from 'shared/hooks/utils';
import { EmailDeliveryStatus } from 'types/store/activity';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
var ReminderSchema = z.object({
    debtor: z.object({
        id: z.number(),
        fullName: z.string(),
    }),
    recipients: z.array(z.object({
        body: z.string(),
        email: z.string(),
        sentAt: z.string(),
        statuses: z.record(z.nativeEnum(EmailDeliveryStatus), z.array(z.string())),
    })),
});
export function loadReminderQueryFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [4 /*yield*/, instance.get("/payment_requests/".concat(queryKey[1], "/emails"))];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, ReminderSchema.parse(data)];
            }
        });
    });
}
export var useLoadReminder = function (id) {
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['reminders', id],
        queryFn: function (context) { return loadReminderQueryFn(axiosInstance, context); },
    });
    return addResourceNameToQueryResult('reminder', queryResult);
};
