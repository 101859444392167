import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';
var DsoSchema = z.record(z.coerce.string().datetime({ offset: true }), z.number().nullable());
export function loadDsoFn(axiosInstance) {
    return __awaiter(this, void 0, void 0, function () {
        var instance, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [4 /*yield*/, instance.get('/analytics/dsos')];
                case 2:
                    data = (_a.sent()).data;
                    return [2 /*return*/, DsoSchema.parse(data)];
            }
        });
    });
}
export function useLoadDso() {
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['dso'],
        queryFn: function () { return loadDsoFn(axiosInstance); },
    });
    return addResourceNameToQueryResult('dso', queryResult);
}
