import { __awaiter, __generator } from "tslib";
import { LocaleSchema } from 'types';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, usePublicAxiosInstance } from './utils';
var OnboardingStepSchema = z.union([
    z.literal(1),
    z.literal(2),
    z.literal(3),
    z.literal(4),
    z.literal(5),
    z.literal(6),
    z.literal(7),
]);
var OnboardingStatusSchema = z.discriminatedUnion('isComplete', [
    z.object({ isComplete: z.literal(false), currentStep: OnboardingStepSchema }),
    z.object({ isComplete: z.literal(true), currentStep: z.null() }),
]);
var TenantSchema = z.object({
    locale: LocaleSchema,
    companyName: z.string().min(1),
    domain: z.string(),
    onboarding: OnboardingStatusSchema,
    customBranding: z
        .object({
        name: z.string().nullable(),
        logo: z.string().nullable(),
        color: z.string().nullable(),
    })
        .nullable(),
});
export function validateTenantFn(axiosInstance, subdomain) {
    return __awaiter(this, void 0, void 0, function () {
        var data, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance.get("/tenant?name=".concat(subdomain))];
                case 1:
                    data = (_a.sent()).data;
                    data.domain = subdomain;
                    try {
                        res = TenantSchema.parse(data);
                    }
                    catch (error) {
                        console.error(error === null || error === void 0 ? void 0 : error.toString());
                        throw new Error('Tenant data is invalid');
                    }
                    return [2 /*return*/, res];
            }
        });
    });
}
export var useValidateTenant = function (subdomain) {
    var axiosInstance = usePublicAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['tenant'],
        retry: false,
        queryFn: function () { return validateTenantFn(axiosInstance, subdomain); },
        placeholderData: function (previousData) { return previousData; },
    });
    return addResourceNameToQueryResult('tenant', queryResult);
};
