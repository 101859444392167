import React, { Fragment } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import Amount from 'shared/components/Amount';
import DateItem from 'shared/components/DateItem';
import { dateFromNow } from 'shared/utils/view';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { Center, Indicator } from '@mantine/core';
import { useMarkConversationAsRead } from '../../hooks';
import { Icon, IconName } from '../Icon';
import debtCollectorIcon from '../InvoiceActionsDropdown/ChooseThirdPartyTypeForm/debt-collector.svg';
import TpCaseDetail from '../TpCaseDetail';
import TpCaseStatusItem from '../TpCaseStatusItem';
import styleIdentifiers from './thirdpartyCaseListItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ThirdpartyCaseListItem(_a) {
    var _b, _c;
    var caseDetail = _a.item, noDebtorName = _a.noDebtorName, reloadInfo = _a.reloadInfo;
    var _d = useTranslation(), t = _d.t, currentLang = _d.currentLang;
    var markConversationAsRead = useMarkConversationAsRead().markConversationAsRead;
    var status = (function () {
        if (caseDetail.closed)
            return 'close';
        if (caseDetail.started)
            return 'open';
        return 'notOpen';
    })();
    if (caseDetail.case_type == null)
        return null;
    // Sentry 15C
    var caseTypeTranslated = t(i18nKeys.THIRD_PARTY_CASES.CASE_TYPE[caseDetail.case_type.toUpperCase()]);
    var openModal = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.FULL,
            title: t(i18nKeys.THIRD_PARTY_CASES.MODAL_TITLE, {
                case_type: caseTypeTranslated,
                case_reference: caseDetail.case_id || caseDetail.invoice_reference,
            }),
            children: React.createElement(TpCaseDetail, { item: caseDetail }),
            onClose: function () {
                markConversationAsRead({ id: caseDetail.conversation.id });
                reloadInfo();
            },
        });
    };
    // todo: duplicated elsewhere, extract to utils
    var getIcon = function () {
        switch (caseDetail.case_type) {
            case 'lawyer':
                return React.createElement(Icon, { name: IconName.LAW, size: "18px", className: styles('icon') });
            case 'bailiff':
                return React.createElement(Icon, { name: IconName.BALANCE, size: "18px", className: styles('icon') });
            case 'debt_collector':
                return React.createElement("img", { width: "24px", height: "24px", src: debtCollectorIcon, alt: "Debt collector" });
            case 'callcenter':
            default:
                return React.createElement(Icon, { name: IconName.PHONE_CALL, size: "18px", className: styles('icon') });
        }
    };
    return (React.createElement("tr", { className: styles('thirdparty-case-list-item'), onClick: openModal },
        React.createElement("td", { className: styles('check-box-container', 'small') },
            React.createElement(Center, null,
                React.createElement(Indicator, { color: "red", size: 5, disabled: !((_c = (_b = caseDetail === null || caseDetail === void 0 ? void 0 : caseDetail.conversation) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.unread), zIndex: 1 }, getIcon()))),
        React.createElement("td", null, caseTypeTranslated),
        React.createElement("td", { className: styles('InvoiceNumber') }, caseDetail.case_id || t(i18nKeys.THIRD_PARTY_CASES.NOT_COMMUNICATED)),
        React.createElement("td", { className: styles('Date') },
            React.createElement(DateItem, { lg: currentLang, date: caseDetail.created_at })),
        React.createElement("td", { className: styles('Date') }, dateFromNow(caseDetail.last_update, currentLang)),
        !noDebtorName && React.createElement("td", { className: styles('Client') }, caseDetail.invoice_debtor_name),
        React.createElement(Fragment, null,
            React.createElement("td", { className: styles('StatusContainer') },
                React.createElement(TpCaseStatusItem, { status: status }))),
        React.createElement("td", { className: styles('Amount') },
            React.createElement(Amount, { value: caseDetail.total_tvac })),
        React.createElement("td", { className: styles('Amount') },
            React.createElement(Amount, { value: caseDetail.total_remaining }))));
}
