import { useEffect } from 'react';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { currentLanguageAtom } from 'locales';
import * as Sentry from '@sentry/react';
import { useLoadProfile } from './use-load-profile';
// @ts-ignore Escape the typing because it is an invariant; The only place it can be breached
// is in the PrivateLoader which actually enforces it by leaving the app on the loading screen as long as
// profile !== null is not satisfied
export var profileAtom = atom(null);
profileAtom.debugLabel = 'Profile';
export var useProfile = function () { return useAtomValue(profileAtom); };
export function useEnforceProfile() {
    var setProfile = useSetAtom(profileAtom);
    var _a = useLoadProfile({}), profile = _a.profile, profileError = _a.profileError;
    var setCurrentLocale = useSetAtom(currentLanguageAtom);
    useEffect(function () {
        if (profile) {
            setProfile(profile);
            setCurrentLocale(profile.locale);
            Sentry.setTag('email', profile.email);
            Sentry.setUser({
                id: String(profile.id),
                email: profile.email,
                username: profile.name.full,
                roles: profile.roles,
                isAdmin: profile.isAdmin,
            });
        }
    }, [profile, setProfile, setCurrentLocale]);
    return { profile: profile, profileError: profileError };
}
