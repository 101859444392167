import { __assign, __awaiter, __generator } from "tslib";
import { mapKeys, omit, toCamelCase } from 'remeda';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useLegacyAxiosInstance } from './utils';
var CurrencyDataSchema = z
    .object({
    localized_total: z.coerce.number(),
    EUR: z.string().optional(),
    USD: z.string().optional(),
    GBP: z.string().optional(),
    CHF: z.string().optional(),
    SEK: z.string().optional(),
    JPY: z.string().optional(),
    NOK: z.string().optional(),
})
    .transform(function (obj) { return (__assign(__assign({}, omit(obj, ['localized_total'])), { localizedTotal: obj.localized_total })); });
var DebtorsDetailedBalanceSchema = z.object({
    data: z
        .object({
        detailed_balance: z
            .object({
            invoices_total: CurrencyDataSchema,
            claimed_fees: CurrencyDataSchema,
            credit_notes_total: CurrencyDataSchema,
            payments_total: z.string(),
            remaining_balance: z.string(),
            remaining_late_fees: z.string(),
            remaining_balance_with_fees: z.string(),
            lost_total: z.string(),
        })
            .transform(function (obj) {
            return mapKeys(obj, toCamelCase);
        }),
    })
        .transform(function (obj) {
        return mapKeys(obj, toCamelCase);
    }),
});
export function LoadDebtorsDetailedBalanceFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [4 /*yield*/, instance.get('/debtors_detailed_balance', {
                            params: { account_manager_id: queryKey[1] },
                        })];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, DebtorsDetailedBalanceSchema.parse(data)];
            }
        });
    });
}
export var useLoadDetailedDebtorBalance = function (variables) {
    var axiosInstance = useLegacyAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['detailedDebtorBalance', variables.accountManagerId],
        queryFn: function (context) { return LoadDebtorsDetailedBalanceFn(axiosInstance, context); },
    });
    return addResourceNameToQueryResult('detailedDebtorBalance', queryResult);
};
