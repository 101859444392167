import { __assign, __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { useGetSimilarDebtors } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory } from 'react-router-dom';
import EditClient from 'shared/action-component/EditClient';
import CustomTableViews from 'shared/components/CustomTable/CustomTableViews';
import { Icon, IconName } from 'shared/components/Icon';
import { useGetClientState } from 'shared/utils/selectors';
import { reloadCustomView } from 'shared/utils/view';
import { clientActions } from 'store/client/client.actions';
import { DialogShowId, DialogShowSize, showDialog, sideMenuHide } from 'store/view/view.actions';
import { NoCheckBox } from './BatchActions/NoCheckBox';
import { ShowCheckBox } from './BatchActions/ShowCheckBox';
import { ExportDebtorCsv } from './ExportDebtorCsv';
import styleIdentifiers from './ClientsList.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientsList = function () {
    var _a;
    var t = useTranslation().t;
    var history = useHistory();
    var clients = useGetClientState().list;
    var _b = __read(useState([]), 2), selectedClients = _b[0], setSelectedClients = _b[1];
    var _c = __read(useState(false), 2), areAllItemsAcrossPagesSelected = _c[0], setAreAllItemsAcrossPagesSelected = _c[1];
    var _d = useGetSimilarDebtors({
        query: {
            enabled: false,
        },
    }), similarDebtorsListResponse = _d.data, refetchSimilarDebtors = _d.refetch;
    var shouldShowBatchActions = selectedClients.length > 0;
    useEffect(function () {
        if (clients === null || clients === void 0 ? void 0 : clients.loaded) {
            refetchSimilarDebtors();
        }
    }, [clients, refetchSimilarDebtors]);
    var handleFilters = function (filters) {
        var newFilters = __assign({}, filters);
        var amountKeys = ['payment_delay', 'remaining_balance', 'annual_ca', 'overdue_amount'];
        var hasInvalidFilter = amountKeys.some(function (key) {
            return (newFilters[key] && !newFilters["".concat(key, "_operator")]) ||
                (!newFilters[key] && newFilters["".concat(key, "_operator")]);
        });
        if (hasInvalidFilter)
            return false;
        return newFilters;
    };
    var exportCsv = function () {
        sideMenuHide();
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.EXPORT_CSV),
            children: React.createElement(ExportDebtorCsv, null),
        });
    };
    var showDebtor = function (item) { return function () {
        history.push("/clients/".concat(item.id));
    }; };
    var handleClick = function (item) { return function (e) {
        e.stopPropagation();
        EditClient({
            client: item,
            callback: function () {
                reloadCustomView(clientActions.listPageRes);
                refetchSimilarDebtors();
            },
        });
    }; };
    var actionsCol = function (item) {
        return (React.createElement("div", { onClick: handleClick(item) },
            React.createElement(Icon, { name: IconName.PENCIL, size: "18px" })));
    };
    var getActions = function () {
        if (shouldShowBatchActions) {
            return (React.createElement(ShowCheckBox, { listUserChecked: selectedClients, selectAll: areAllItemsAcrossPagesSelected }));
        }
        return (React.createElement(NoCheckBox, { exportCSV: exportCsv, duplicatesCount: (similarDebtorsListResponse === null || similarDebtorsListResponse === void 0 ? void 0 : similarDebtorsListResponse.metadata.total) || 0 }));
    };
    return (React.createElement("div", { className: styles('client-list') },
        React.createElement(CustomTableViews, { title: t(i18nKeys.CLIENT.YOUR_CLIENTS), tableName: "debtors", onClickRow: showDebtor, actionsCol: actionsCol, actions: getActions(), callbackAction: clientActions.listPageRes, isLoading: !clients.loaded, handleFilters: handleFilters, pagination: (_a = clients.metadata) === null || _a === void 0 ? void 0 : _a.pagination, items: clients.pages, onChangeSelectionCallback: function (values) { return setSelectedClients(values); }, onChangeSelectItemsAcrossAllPagesCallback: setAreAllItemsAcrossPagesSelected, currentPage: clients.currentPage })));
};
