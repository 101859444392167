import { __assign, __awaiter, __generator, __read, __rest } from "tslib";
import React, { useEffect, useReducer, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider, get } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import { loadLightUsersQueryFn } from 'shared/hooks/use-load-light-users';
import { useAxiosInstance } from 'shared/hooks/utils';
import { CustomSelect, DateSelector, Input, IntervalFields, RadioButton } from 'shared/io';
import { filterToText } from 'shared/serializer';
import apiService from 'shared/service/api.service';
import { useFilterForm } from 'shared/utils/hooks';
import { reducerState, removeAttributes } from 'shared/utils/view';
import { appActions } from 'store/app/app.actions';
import { useQueryClient } from '@tanstack/react-query';
import styleIdentifiers from './TableFilters.scss';
var styles = classNames.bind(styleIdentifiers);
var serializer = {
    recovery_plan_id: ['name', 'id'],
    user_id: ['email', 'id'],
    billing_log_id: ['prefix', 'id'],
};
export function TableFilters(_a) {
    var _this = this;
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues, filterObject = _a.filterObject, filtersAvailable = _a.filtersAvailable, initialFiltersName = _a.initialFiltersName, setFiltersName = _a.setFiltersName, tableName = _a.tableName;
    var t = useTranslation().t;
    var queryClient = useQueryClient();
    var constants = useSelector(function (state) { return state.app.constants; });
    var _b = __read(useState(initialFiltersName), 2), filtersName = _b[0], _setFiltersName = _b[1];
    var company = useSelector(function (state) { return state.account.company.data; });
    var axiosInstance = useAxiosInstance();
    var _c = __read(useReducer(reducerState, {
        filtersSearched: [],
        listData: {},
        operator: 'value',
        searchText: '',
        filters: initialValues,
        filterCategories: {
            most_used: 2,
            task_data: 4,
            invoice_data: 6,
            debtor_data: 8,
            date_data: 10,
            recovery_data: 12,
            custom_data: 14,
        },
    }), 2), _d = _c[0], filtersSearched = _d.filtersSearched, listData = _d.listData, currentFilter = _d.currentFilter, operator = _d.operator, searchText = _d.searchText, filters = _d.filters, filterCategories = _d.filterCategories, setState = _c[1];
    if (!(company === null || company === void 0 ? void 0 : company.package.can_use_custom_variables) || tableName === 'tasks') {
        delete filterCategories.custom_data;
    }
    if (['tasks', 'debtors'].includes(tableName)) {
        delete filterCategories.invoice_data;
        if (tableName === 'tasks') {
            delete filterCategories.debtor_data;
            delete filterCategories.recovery_data;
        }
    }
    if (tableName !== 'tasks')
        delete filterCategories.task_data;
    var onSetFilters = function (_filters) {
        setState({ filters: _filters });
        onSubmit(_filters);
    };
    var _e = useFilterForm({
        onSubmit: onSetFilters,
        initialValues: initialValues,
        fieldToRegister: [],
        shouldUnregister: false,
    }), form = _e.form, submit = _e.submit, resetForm = _e.resetForm;
    useEffect(function () {
        if (filterObject) {
            filterObject.reset = function () {
                resetForm();
            };
        }
    }, [filterObject]);
    useEffect(function () {
        if (currentFilter) {
            form.setValue("".concat(currentFilter.name, "_handler"), operator === 'value' ? '' : operator);
            if (operator !== 'value') {
                ['', 'min_', 'max_'].forEach(function (filterPrefix) {
                    form.setValue(filterPrefix + currentFilter.name, '');
                });
            }
            submit();
        }
    }, [operator]);
    var register = form.register, watch = form.watch, setValue = form.setValue, control = form.control;
    var formData = watch();
    useEffect(function () {
        setState({
            filtersSearched: filtersAvailable.filter(function (filter) { return filter.name_translated.toLowerCase().indexOf(searchText === null || searchText === void 0 ? void 0 : searchText.toLowerCase()) !== -1; }),
        });
    }, [searchText]);
    var getData = function (filter) { return function () { return __awaiter(_this, void 0, void 0, function () {
        var res, serialized;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(filter.name === 'user_id')) return [3 /*break*/, 2];
                    return [4 /*yield*/, queryClient.fetchQuery({
                            queryKey: ['users', { loadInactiveUsers: false }],
                            queryFn: function (context) { return loadLightUsersQueryFn(axiosInstance, context); },
                        })];
                case 1:
                    res = _b.sent();
                    serialized = res.map(function (user) { return ({
                        description: user.name.full,
                        value: user.id,
                    }); });
                    setState({
                        listData: __assign(__assign({}, listData), (_a = {}, _a[filter.name] = serialized, _a)),
                    });
                    return [2 /*return*/];
                case 2:
                    appActions.customUrl({
                        raw: true,
                        url: "".concat(apiService.baseUrl, "/private_api/").concat(filter.api_url),
                        noLoading: true,
                        method: 'GET',
                        callback: function (_a) {
                            var _b;
                            var data = _a.data;
                            setState({
                                listData: __assign(__assign({}, listData), (_b = {}, _b[filter.name] = data.map(function (item) {
                                    var newItem = removeAttributes(item);
                                    if (serializer[filter.name]) {
                                        newItem.description = newItem[serializer[filter.name][0]];
                                        newItem.value = newItem[serializer[filter.name][1]];
                                    }
                                    else {
                                        newItem.description =
                                            newItem.type === 'light_invoice' ? newItem.reference : newItem.name;
                                        newItem.value = newItem.id;
                                    }
                                    return newItem;
                                }), _b)),
                            });
                        },
                    });
                    return [2 /*return*/];
            }
        });
    }); }; };
    var selectSubmit = function (filter) { return function (e) {
        var _a, _b;
        // eslint-disable-next-line
        if (Boolean(filter.constant_name)) {
            var values_1 = {};
            get(constants, filter.constant_name).forEach(function (_a) {
                var value = _a.value, description = _a.description;
                values_1[value] = description;
            });
            setFiltersName(filter.name, values_1);
            _setFiltersName(__assign(__assign({}, filtersName), (_a = {}, _a[filter.name] = values_1, _a)));
        }
        if (filter.is_api) {
            var values_2 = {};
            listData[filter.name].forEach(function (item) {
                values_2[item.id] = item.description;
            });
            setFiltersName(filter.name, values_2);
            _setFiltersName(__assign(__assign({}, filtersName), (_b = {}, _b[filter.name] = values_2, _b)));
        }
        submit(e);
    }; };
    var showFilter = function (currentFilter) { return function () {
        if ((company === null || company === void 0 ? void 0 : company.package.can_use_all_filters) || currentFilter.category === 'most_used') {
            setState({
                currentFilter: currentFilter,
                operator: initialValues["".concat(currentFilter.name, "_handler")] || 'value',
            });
        }
    }; };
    var setOperator = function (operator) {
        setState({
            operator: operator,
        });
    };
    var removeCurrentFilter = function () {
        setState({ currentFilter: null });
    };
    var setSearchInput = function (value) {
        setState({
            searchText: value,
        });
    };
    return form ? (React.createElement("div", { className: styles('table-filters') },
        React.createElement("div", { className: styles('filters-list') },
            React.createElement("div", { className: styles('search-filter') },
                React.createElement(Input, { value: searchText, onValueChanged: setSearchInput, noMargin: true, type: "text", label: t(i18nKeys.FORM.RESEARCH) })),
            React.createElement("div", { className: styles('container-fields') },
                Object.keys(filterCategories).map(function (filterCategory) { return (React.createElement("div", { key: filterCategory, className: styles('category-title'), style: { order: filterCategories[filterCategory] - 1 } }, t("VIEWS.FILTERS.CATEGORY.".concat(filterCategory.toUpperCase())))); }),
                filtersSearched.map(function (filter) { return (React.createElement("div", { className: styles('filter-item', (filters[filter.name] ||
                        filters["".concat(filter.name, "_handler")] ||
                        filters["min_".concat(filter.name)] ||
                        filters["max_".concat(filter.name)]) &&
                        'active', !(company === null || company === void 0 ? void 0 : company.package.can_use_all_filters) &&
                        filter.category !== 'most_used' &&
                        'inactive'), key: filter.id, style: { order: filterCategories[filter.category] || 12 }, onClick: showFilter(filter), title: !(company === null || company === void 0 ? void 0 : company.package.can_use_all_filters) && filter.category !== 'most_used'
                        ? t(i18nKeys.NOT_INCLUDED)
                        : '' },
                    React.createElement("div", null,
                        React.createElement("div", null, filter.name_translated),
                        React.createElement("div", { className: styles('filter-value') }, filter.name === 'status' && filters.status
                            ? (Array.isArray(filters.status) ? filters.status : filters.status.split(','))
                                .map(function (status) { var _a; return (_a = constants.statuses.find(function (el) { return el.value === status; })) === null || _a === void 0 ? void 0 : _a.description; })
                                .join(', ')
                            : filterToText(filter.filter_type, filtersName[filter.name]
                                ? (filters[filter.name] || []).map(function (value) { return filtersName[filter.name][value]; })
                                : filters[filter.name], filters["".concat(filter.name, "_handler")], filters["".concat(filter.name, "_operator")], filters["min_".concat(filter.name)], filters["max_".concat(filter.name)]))),
                    React.createElement(Icon, { name: IconName.MINIMAL_RIGHT }))); }))),
        React.createElement(FormProvider, __assign({}, form),
            React.createElement("div", { className: styles('filter-edition', currentFilter && 'show') }, currentFilter && (React.createElement("div", { className: styles('filter-container') },
                React.createElement("div", { className: styles('current-filter-head'), onClick: removeCurrentFilter },
                    React.createElement(Icon, { name: IconName.MINIMAL_LEFT }),
                    " ",
                    t(i18nKeys.BACK)),
                React.createElement("h3", null, currentFilter.name_translated),
                React.createElement(Controller, { control: control, name: "operator", render: function (_a) {
                        var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                        return (React.createElement(RadioButton, __assign({}, values, { value: operator, onChange: setOperator, className: styles('radio-button'), items: [{ value: 'value', label: t(i18nKeys.VALUE) }] })));
                    } }),
                operator === 'value' && (React.createElement("div", { className: styles('input-container') }, currentFilter.filter_type === 'string' ? (React.createElement(Input, { register: register(currentFilter.name), noMargin: true, withBorder: true, shadow: true, type: "text", placeholder: currentFilter.name_translated, onValueChanged: submit })) : currentFilter.filter_type === 'select' ? (React.createElement(Controller, { defaultValue: "", name: currentFilter.name, render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('large'), removeAll: t(i18nKeys.CLIENT.FILTER.ALL), keyText: "description", keyValue: "value", size: "small", filter: true, multiple: true, placeholderFilter: t(i18nKeys.FORM.RESEARCH), name: currentFilter.name, noMargin: true, noBorder: true, withBorder: true, shadow: true, load: currentFilter.is_api ? getData(currentFilter) : undefined, items: currentFilter.is_api
                            ? listData[currentFilter.name] || []
                            : currentFilter.constant_name
                                ? get(constants, currentFilter.constant_name)
                                : [], onValueChanged: selectSubmit(currentFilter) })); } })) : currentFilter.filter_type === 'date' ? (React.createElement(DateSelector, { className: styles('date-selector'), name: "".concat(currentFilter.name, "_start"), endName: "".concat(currentFilter.name, "_end"), placeholder: t(i18nKeys.OF), endPlaceholder: t(i18nKeys.FORM.TO), withBorder: true, shadow: true, noMinDate: true, forcePlacement: true, handleChange: function (value) {
                        setValue("".concat(currentFilter.name, "_start"), value);
                        submit();
                    }, handleEndChange: function (value) {
                        setValue("".concat(currentFilter.name, "_end"), value);
                        submit();
                    } })) : currentFilter.filter_type === 'number' ? (React.createElement(IntervalFields, { register: register, className: styles('spacing'), nameFrom: "min_".concat(currentFilter.name), nameTo: "max_".concat(currentFilter.name), label: "", placeholderTo: t(i18nKeys.FORM.MAX), placeholderFrom: t(i18nKeys.FORM.MIN), onChange: submit })) : currentFilter.filter_type === 'boolean' ? (React.createElement(Controller, { defaultValue: "", name: currentFilter.name, render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('large'), removeAll: t(i18nKeys.CLIENT.FILTER.ALL), keyText: "description", keyValue: "value", size: "small", name: currentFilter.name, noMargin: true, noBorder: true, withBorder: true, shadow: true, items: [
                            {
                                description: t(i18nKeys.YES),
                                value: 'true',
                            },
                            {
                                description: t(i18nKeys.NO),
                                value: 'false',
                            },
                        ], onValueChanged: submit })); } })) : null)),
                React.createElement("div", null,
                    React.createElement(Controller, { control: control, name: "operator", render: function (_a) {
                            var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                            return (React.createElement(RadioButton, __assign({}, values, { value: operator, onChange: setOperator, className: styles('radio-button'), noMargin: true, items: (currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.name) === 'missing_data'
                                    ? []
                                    : [
                                        {
                                            value: 'null',
                                            label: t(i18nKeys.VIEWS.FILTERS.EMPTY_DATA),
                                        },
                                        {
                                            value: 'not_null',
                                            label: t(i18nKeys.VIEWS.FILTERS.FILL_DATA),
                                        },
                                    ] })));
                        } })))))))) : null;
}
