import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from 'shared/io';
import styleIdentifiers from './ExternalMailAction.scss';
var styles = classNames.bind(styleIdentifiers);
export var ExternalMailAction = function (_a) {
    var _b, _c;
    var onSubmit = _a.onSubmit, externalMail = _a.externalMail, children = _a.children;
    var t = useTranslation().t;
    var rest = __rest(externalMail.attributes, []);
    var form = useForm({
        shouldUnregister: true,
        defaultValues: __assign(__assign({}, rest), { debtor_id: (_b = rest.debtor) === null || _b === void 0 ? void 0 : _b.id, user_id: (_c = externalMail.attributes.account_manager) === null || _c === void 0 ? void 0 : _c.id }),
    });
    var handleSubmit = form.handleSubmit, isDirty = form.formState.isDirty;
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('external-email-action-form'), onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", { className: styles('content') }, children),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { disabled: !isDirty, type: "submit", label: t(i18nKeys.SAVE), noShadow: true, noMargin: true })))));
};
