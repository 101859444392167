import { __awaiter, __generator } from "tslib";
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { useLegacyAxiosInstance } from './utils';
export function deleteExternalMailboxFn(axiosInstance, variables) {
    return __awaiter(this, void 0, Promise, function () {
        var id, instance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = variables.id;
                    return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [2 /*return*/, instance.delete("external_mailbox_configurations/".concat(id))];
            }
        });
    });
}
export function useDeleteExternalMailbox() {
    var client = useQueryClient();
    var axiosInstance = useLegacyAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (variables) { return deleteExternalMailboxFn(axiosInstance, variables); },
        onSuccess: function (response) {
            notifications.show({
                message: response.data.message,
                color: 'green',
            });
            client.invalidateQueries({ queryKey: ['externalMailboxes'] });
        },
        onError: function (error) {
            var _a, _b, _c, _d;
            notifications.show({
                color: 'red',
                message: (_d = (_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error_message) !== null && _b !== void 0 ? _b : (_c = error.response) === null || _c === void 0 ? void 0 : _c.data.errors[0]) !== null && _d !== void 0 ? _d : error.message,
            });
        },
    });
    return addActionNameToMutationResult('deleteExternalMailbox', mutation);
}
