import { __assign, __makeTemplateObject, __rest } from "tslib";
import React, { forwardRef } from 'react';
import { useProfile } from 'shared/hooks';
import { useGetCompany } from 'shared/utils/selectors';
import { css } from '@emotion/css';
import { Avatar, Button, Space, Text } from '@mantine/core';
var styles = {
    profileButtonRoot: function (isOpen) { return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: ", " !important;\n    border-color: ", ";\n    color: '#1A1919';\n    transition: background-color 100ms ease;\n\n    &:hover {\n      background-color: #f2f5f7 !important;\n    }\n  "], ["\n    background-color: ", " !important;\n    border-color: ", ";\n    color: '#1A1919';\n    transition: background-color 100ms ease;\n\n    &:hover {\n      background-color: #f2f5f7 !important;\n    }\n  "])), isOpen ? '#F2F5F7' : 'transparent', isOpen ? '#CCD7DE' : 'transparent'); },
};
export var ProfileButton = forwardRef(function (_a, ref) {
    var isOpened = _a.isOpened, others = __rest(_a, ["isOpened"]);
    var company = useGetCompany();
    var profile = useProfile();
    return (React.createElement(Button, __assign({ pl: 4, justify: "space-between", w: "100%", ref: ref, radius: 8, classNames: { root: styles.profileButtonRoot(isOpened) }, rightSection: React.createElement(Avatar, { size: "sm", radius: 6, src: company === null || company === void 0 ? void 0 : company.invoice_customization.logo_url, name: company === null || company === void 0 ? void 0 : company.name }) }, others),
        React.createElement(Avatar, { size: "sm", src: profile.profilePicture, name: profile.name.full }),
        React.createElement(Space, { w: 12 }),
        React.createElement(Text, { c: "#1A1919" }, profile.name.full)));
});
var templateObject_1;
