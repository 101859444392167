import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { motion } from 'framer-motion';
import { Icon, IconName } from 'shared/components/Icon';
import { Checkbox } from 'shared/io';
import styleIdentifiers from './FormSection.scss';
var styles = classNames.bind(styleIdentifiers);
/**
 *
 * @deprecated Do not use this component. If possible, replace it with a Mantine accordion
 */
export default function FormSection(_a) {
    var children = _a.children, startClosed = _a.startClosed, isOpen = _a.isOpen, title = _a.title, onAdd = _a.onAdd, onToggle = _a.onToggle, className = _a.className, noLine = _a.noLine, checkbox = _a.checkbox;
    var _b = __read(useState(!startClosed), 2), isOpenInternalState = _b[0], setIsOpenInternalState = _b[1];
    useEffect(function () {
        if (isOpen !== undefined) {
            setIsOpenInternalState(isOpen);
        }
    }, [isOpen]);
    var toggle = function (forceOpen) {
        onToggle === null || onToggle === void 0 ? void 0 : onToggle(forceOpen || !isOpenInternalState);
        setIsOpenInternalState(forceOpen || !isOpenInternalState);
    };
    return (React.createElement("div", { className: styles(className, 'form-section') },
        React.createElement("div", { className: styles('title') },
            React.createElement("div", { className: styles('name'), onClick: function () { return toggle(); } }, title),
            !noLine && React.createElement("div", { className: styles('line'), onClick: function () { return toggle(); } }),
            React.createElement("div", { className: styles('actions') },
                onAdd && (React.createElement(Icon, { name: IconName.PLUS, onClick: function () {
                        toggle(true);
                        onAdd();
                    }, className: styles('action') })),
                checkbox ? (React.createElement(Checkbox, { checked: isOpenInternalState, onChange: toggle, noMargin: true })) : (React.createElement(Icon, { name: IconName.ARROW_BOTTOM_ROUNDED, onClick: function () { return toggle(); }, className: styles('icon', isOpenInternalState && 'open') })))),
        React.createElement(motion.div, { initial: { opacity: 0, height: 0 }, animate: {
                opacity: isOpenInternalState ? 1 : 0,
                height: isOpenInternalState ? 'auto' : 0,
            }, exit: { opacity: 0, height: 0 }, transition: { duration: 0.5, ease: 'easeInOut' }, style: { overflow: isOpenInternalState ? 'unset' : 'hidden' } }, children)));
}
