import { __assign, __generator } from "tslib";
import { all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'store/apis';
import { appActions, appConstants as events } from 'store/app/app.actions';
import { userInit } from '../account/account.sagas';
import { sendApi } from '../sagas';
function reloadCompanyConfiguration() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(userInit)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function AppWatchers() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeLatest(events.reloadCompanyConfiguration.request, reloadCompanyConfiguration)];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeLatest(events.customUrl.request, sendApi(api.custom))];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getFiltersName.request, sendApi(api.tables.getFiltersName))];
            case 3:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getViews.request, sendApi(api.tables.getViews, appActions.getViewsRes))];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getView.request, sendApi(api.tables.getView))];
            case 5:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.updateViews.request, sendApi(api.tables.updateViews))];
            case 6:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getColumns.request, sendApi(api.tables.getColumnsAvailable))];
            case 7:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getFilters.request, sendApi(api.tables.getFiltersAvailable))];
            case 8:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.export.request, sendApi(api.tables.export))];
            case 9:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.fetchAddress.request, sendApi(api.settings.fetchAddress, appActions.fetchAddressRes, function (payload) { return (__assign(__assign({}, payload), { actionFailure: payload.callback })); }))];
            case 10: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 11:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export default function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(AppWatchers)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
