import { __assign, __rest } from "tslib";
import React, { useEffect } from 'react';
import { TaskFormDebtor } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales/';
import { isNil, omit, omitBy } from 'lodash-es';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { AccountManagerSelector } from 'shared/components';
import { CustomSelect, DateSelector, Input } from 'shared/io';
import { EXTERNAL_MAIL_STATUSES } from '../ExternalMail.constants';
import { ExternalMailBoxForm } from './ExternalMailBoxForm';
import styleIdentifiers from './ExternalMailList.scss';
var styles = classNames.bind(styleIdentifiers);
export var ExternalMailListFilters = function (_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues, filterObject = _a.filterObject;
    var t = useTranslation().t;
    var form = useForm({
        shouldUnregister: true,
        defaultValues: omit(initialValues, ['page', 'page_limit', 'sort_by', 'sort_order']),
    });
    var setValue = form.setValue, register = form.register, handleSubmit = form.handleSubmit, watch = form.watch, getValues = form.getValues;
    var values = watch();
    useEffect(function () {
        filterObject.reset = function () {
            Object.keys(getValues()).forEach(function (key) {
                return form.setValue(key, '');
            });
        };
    }, [filterObject, form, getValues, setValue]);
    useEffect(function () {
        onSubmit(omitBy(values, function (v) { return isNil(v) || (Array.isArray(v) && !v.length) || v === ''; }));
    }, [values, onSubmit]);
    var getIsoDate = function (date) { return dayjs(date).format('YYYY-MM-DD'); };
    var externalMailStatuses = EXTERNAL_MAIL_STATUSES.map(function (_a) {
        var description = _a.description, rest = __rest(_a, ["description"]);
        return (__assign({ description: t(description) }, rest));
    });
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { onSubmit: handleSubmit(onSubmit), className: styles('external-mail-filter-form') },
            React.createElement("div", { className: styles('container-fields') },
                React.createElement("div", { className: styles('fields') },
                    React.createElement(ExternalMailBoxForm, null),
                    React.createElement("div", { className: styles('large') },
                        React.createElement(Input, { register: register('subject'), type: "text", label: t(i18nKeys.SUBJECT), withBorder: true, timeout: 500 })),
                    React.createElement("div", { className: styles('large') },
                        React.createElement(Input, { register: register('body'), type: "text", label: t(i18nKeys.BODY), withBorder: true, timeout: 500 })),
                    React.createElement("div", { className: styles('large') },
                        React.createElement(Input, { type: "text", label: t(i18nKeys.FROM), register: register('from_mail'), withBorder: true, timeout: 500 })),
                    React.createElement(Controller, { name: "status", render: function () { return (React.createElement(CustomSelect, { name: "status", keyText: "description", keyValue: "value", removeAll: t(i18nKeys.CLIENT.FILTER.ALL), items: externalMailStatuses, label: t(i18nKeys.TASK.ATTRIBUTES.STATUS), withBorder: true, multiple: true })); } }),
                    React.createElement(AccountManagerSelector, { name: "account_manager_id", showMargin: true }),
                    React.createElement(TaskFormDebtor, { extraOptions: [{ id: 'unassigned', full_name: t(i18nKeys.VIEW_UNASSIGNED_EMAILS) }] }),
                    React.createElement(DateSelector, { name: "date_after", endName: "date_before", label: t(i18nKeys.DATE), className: styles('input', 'date-selector'), handleChange: function (value) {
                            setValue('date_after', getIsoDate(value));
                        }, handleEndChange: function (value) {
                            setValue('date_before', getIsoDate(value));
                        }, placeholder: t(i18nKeys.OF), endPlaceholder: t(i18nKeys.FORM.TO), withBorder: true, noMinDate: true, forcePlacement: true }))))));
};
