import { __read } from "tslib";
import React, { useState } from 'react';
import { useTranslation } from 'locales';
import { Redirect, useHistory } from 'react-router';
import { useAcceptRecovrTos, useProfile } from 'shared/hooks';
import { config } from 'store/constants';
import { Anchor, Button, Checkbox, Modal, Text } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
export var AcceptTermsOfService = function (_a) {
    var currentLang = useTranslation().currentLang;
    var history = useHistory();
    var queryClient = useQueryClient();
    var profile = useProfile();
    var _b = useAcceptRecovrTos(), acceptRecovrTos = _b.acceptRecovrTos, isAcceptRecovrTosLoading = _b.isAcceptRecovrTosLoading;
    var _c = __read(useState(false), 2), areTosAccepted = _c[0], setAreTosAccepted = _c[1];
    if (profile.hasAcceptedGc)
        return React.createElement(Redirect, { to: "/dashboard" });
    var link = config.terms[currentLang];
    var onClickProceed = function () {
        acceptRecovrTos({}, {
            onSuccess: function () {
                history.push('/dashboard');
                queryClient.invalidateQueries({ queryKey: ['profile'] });
            },
        });
    };
    return (React.createElement(Modal, { title: "Accept terms of service", withCloseButton: false, centered: true, opened: true, onClose: function () { } },
        React.createElement(Text, { pb: "lg", size: "sm" },
            "In order to proceed to your platform, please review and accept our",
            ' ',
            React.createElement(Anchor, { href: link, target: "_blank" }, "Terms of Service")),
        React.createElement(Checkbox, { size: "sm", label: "I accept the terms of service", checked: areTosAccepted, onChange: function (event) { return setAreTosAccepted(event.currentTarget.checked); } }),
        React.createElement(Button, { w: "100%", mt: "lg", disabled: !areTosAccepted, loading: isAcceptRecovrTosLoading, onClick: onClickProceed }, "Proceed")));
};
