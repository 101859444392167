import { __generator } from "tslib";
import { fork } from 'redux-saga/effects';
import account from './account/account.sagas';
import app from './app/app.sagas';
import client from './client/client.sagas';
import colleague from './colleague/colleague.sagas';
import creditNote from './creditNote/creditnote.sagas';
import invoice from './invoice/invoice.sagas';
import onBoarding from './onBoarding/onBoarding.sagas';
import product from './product/product.sagas';
import settings from './settings/settings.sagas';
import thirdpartyCase from './thirdpartyCase/thirdpartycase.sagas';
import transaction from './transaction/transaction.sagas';
import view from './view/view.sagas';
export default function root() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fork(app)];
            case 1:
                _a.sent();
                return [4 /*yield*/, fork(account)];
            case 2:
                _a.sent();
                return [4 /*yield*/, fork(client)];
            case 3:
                _a.sent();
                return [4 /*yield*/, fork(colleague)];
            case 4:
                _a.sent();
                return [4 /*yield*/, fork(creditNote)];
            case 5:
                _a.sent();
                return [4 /*yield*/, fork(invoice)];
            case 6:
                _a.sent();
                return [4 /*yield*/, fork(settings)];
            case 7:
                _a.sent();
                return [4 /*yield*/, fork(product)];
            case 8:
                _a.sent();
                return [4 /*yield*/, fork(transaction)];
            case 9:
                _a.sent();
                return [4 /*yield*/, fork(onBoarding)];
            case 10:
                _a.sent();
                return [4 /*yield*/, fork(view)];
            case 11:
                _a.sent();
                return [4 /*yield*/, fork(thirdpartyCase)];
            case 12:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
