import { __awaiter, __generator } from "tslib";
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { useLegacyAxiosInstance } from './utils';
export function manuallySyncExternalEmails(axiosInstance) {
    return __awaiter(this, void 0, void 0, function () {
        var instance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [2 /*return*/, instance.post('fetch_external_mails')];
            }
        });
    });
}
export function useManuallySyncExternalMails() {
    var client = useQueryClient();
    var axiosInstance = useLegacyAxiosInstance();
    var mutation = useMutation({
        mutationFn: function () { return manuallySyncExternalEmails(axiosInstance); },
        onSuccess: function (response) {
            notifications.show({
                message: response.data.message,
                color: 'green',
            });
            client.invalidateQueries({ queryKey: ['externalMails'] });
        },
        onError: function (error) {
            notifications.show({
                message: error.toString(),
                color: 'red',
            });
        },
    });
    return addActionNameToMutationResult('manuallySyncExternalEmails', mutation);
}
