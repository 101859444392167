import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useGetCompany } from 'shared/utils/selectors';
import { accountActions } from 'store/account/account.actions';
import { Integration, INTEGRATIONS_ROUTE } from '../../Integrations.constants';
import { INTEGRATION_UPDATE_CALLBACK, IntegrationFormFieldsName } from '../Integration.constants';
import { getIntegrationRights } from '../Integration.utils';
import { IntegrationHeader } from '../IntegrationHeader';
import { ExactForm, ExactFormFieldsName } from './ExactForm';
import styleIdentifiers from '../Integration.scss';
var styles = classNames.bind(styleIdentifiers);
export var Exact = function (_a) {
    var _b;
    var _c = _a.history, goBack = _c.goBack, replace = _c.replace;
    var t = useTranslation().t;
    var company = useGetCompany();
    var _d = getIntegrationRights(Integration.EXACT, company), isActive = _d.isActive, canActivate = _d.canActivate, isConnected = _d.isConnected;
    useEffect(function () {
        if (!canActivate && !isActive) {
            replace(INTEGRATIONS_ROUTE);
        }
    }, [canActivate, isActive, replace]);
    if (!(company === null || company === void 0 ? void 0 : company.tp_accounting)) {
        return (React.createElement("div", { className: styles('loading') },
            React.createElement("div", null, t(i18nKeys.LOADING))));
    }
    var handleSubmit = function (data) {
        accountActions.updateExactSettings({
            data: data,
            callback: INTEGRATION_UPDATE_CALLBACK,
        });
    };
    var _e = company.tp_accounting, use_import = _e.use_import, use_payment_matching = _e.use_payment_matching, additional_parameters = _e.additional_parameters, issue_date_limit = _e.issue_date_limit, debtor_update = _e.debtor_update, automatic_sync = _e.automatic_sync;
    return (React.createElement("article", { className: styles('exact') },
        React.createElement(IntegrationHeader, { name: Integration.EXACT, goBack: goBack, isActive: isActive, isConnected: isConnected }),
        React.createElement(ExactForm, { onSubmit: handleSubmit, initialValues: (_b = {},
                _b[ExactFormFieldsName.IMPORT_INTEGRATION] = use_import,
                _b[ExactFormFieldsName.REFERENCE_FIELD] = (additional_parameters === null || additional_parameters === void 0 ? void 0 : additional_parameters.reference_field) || '',
                _b[ExactFormFieldsName.PAYMENT_MATCHING] = use_payment_matching,
                _b[IntegrationFormFieldsName.ISSUE_DATE_LIMIT] = issue_date_limit,
                _b[IntegrationFormFieldsName.DEBTOR_UPDATE] = debtor_update,
                _b[IntegrationFormFieldsName.AUTOMATIC_SYNC] = automatic_sync,
                _b) })));
};
