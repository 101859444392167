import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { CreateReportSchema } from './use-create-report';
import { useAxiosInstance } from './utils';
var UpdateReportSchema = z.object({
    id: z.string(),
    name: CreateReportSchema.shape.name.optional(),
    emails: CreateReportSchema.shape.emails.optional(),
    periodicity: CreateReportSchema.shape.periodicity.optional(),
});
export function updateReportFn(axiosInstance, data) {
    return __awaiter(this, void 0, void 0, function () {
        var instance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [2 /*return*/, instance.put("/reports/export_tasks/".concat(data.id), data)];
            }
        });
    });
}
export var useUpdateReport = function () {
    var client = useQueryClient();
    var axiosInstance = useAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (variables) { return updateReportFn(axiosInstance, variables); },
        onSuccess: function (response, _a) {
            var id = _a.id;
            notifications.show({
                message: response.data.message,
                color: 'green',
            });
            client.invalidateQueries({
                queryKey: ['reports'],
            });
            client.invalidateQueries({
                queryKey: ['report', id],
            });
        },
    });
    return addActionNameToMutationResult('updateReport', mutation);
};
