import React from 'react';
import { useProfile } from 'shared/hooks';
import apiService from 'shared/service/api.service';
import { Avatar, Center, Menu, Text } from '@mantine/core';
export var TenantSwitcher = function () {
    var profile = useProfile();
    return (React.createElement(React.Fragment, null, profile.accessibleTenants.map(function (tenant) {
        var isSelected = tenant.subdomain === apiService.tenant;
        return (React.createElement(Menu.Item, { key: tenant.id, onClick: function () { return window.location.assign(tenant.url); }, leftSection: React.createElement(Avatar, { radius: 6, size: 24, src: tenant.logo, name: tenant.companyName }), styles: isSelected ? { item: { backgroundColor: '#F2F5F7' } } : undefined }, isSelected ? (React.createElement(Center, { inline: true },
            React.createElement(Text, null, tenant.companyName),
            React.createElement(Text, { ml: 5, c: "#67869B" }, "\u00B7 Connect\u00E9"))) : (React.createElement(Text, null, tenant.companyName))));
    })));
};
