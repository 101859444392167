import { __read } from "tslib";
import React, { useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { useIsMobile } from 'shared/hooks/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { Menu, Text } from '@mantine/core';
import { IconLogout, IconPencilMinus } from '@tabler/icons-react';
import { ProfileButton } from './ProfileButton';
import { TenantSwitcher } from './TenantSwitcher';
var dropdownStyles = {
    dropdown: {
        borderRadius: 8,
        borderColor: '#EDEDED',
        borderWidth: 1,
        marginLeft: 20,
        boxShadow: '4px 8px 12px -2px rgba(0, 0, 0, 0.12)',
    },
};
export default function UserMenuButton(_a) {
    var t = useTranslation().t;
    var history = useHistory();
    var isMobile = useIsMobile();
    var logout = useAuth0().logout;
    var _b = __read(useState(false), 2), isOpened = _b[0], setIsOpened = _b[1];
    return (React.createElement(Menu, { shadow: "md", position: isMobile ? 'top' : 'right-end', opened: isOpened, onChange: setIsOpened },
        React.createElement(Menu.Target, null,
            React.createElement(ProfileButton, { isOpened: isOpened })),
        React.createElement(Menu.Dropdown, { w: 300, styles: dropdownStyles },
            React.createElement(Menu.Label, null, t(i18nKeys.HEADER.USER_ACTIONS.SPACES)),
            React.createElement(TenantSwitcher, null),
            React.createElement(Menu.Divider, null),
            React.createElement(Menu.Item, { onClick: function () { return history.push('/settings/user'); }, leftSection: React.createElement(IconPencilMinus, { stroke: "1.5", size: 16, color: "#666666" }) },
                React.createElement(Text, null, t(i18nKeys.HEADER.USER_ACTIONS.EDIT_PROFILE))),
            React.createElement(Menu.Item, { onClick: function () { return logout({ logoutParams: { returnTo: "".concat(window.location.origin, "/login") } }); }, leftSection: React.createElement(IconLogout, { stroke: "1.5", size: 16 }), color: "#EC5962" },
                React.createElement(Text, null, t(i18nKeys.HEADER.USER_ACTIONS.LOGOUT))))));
}
