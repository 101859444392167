import { __assign, __read, __spreadArray } from "tslib";
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import countries from 'i18n-iso-countries';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { AVAILABLE_CURRENCIES } from 'shared';
import AYSModal from 'shared/components/AYSModal';
import NavigationPrompt from 'shared/components/BlockNavigation';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, ButtonColor, CustomSelect, Input } from 'shared/io';
import { email } from 'shared/utils/validation';
import { accountActions } from 'store/account/account.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { AVAILABLE_LANGUAGES } from 'types';
import styleIdentifiers from './CompanySettingsForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function CompanySettingsForm(_a) {
    var _b, _c, _d;
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues;
    var _e = useTranslation(), t = _e.t, currentLang = _e.currentLang;
    useEffect(function () {
        reset(initialValues, { keepValues: true });
    }, [initialValues]);
    var form = useForm({
        defaultValues: __assign(__assign({}, initialValues), { dirty: 0 }),
    });
    var handleSubmit = form.handleSubmit, register = form.register, _f = form.formState, errors = _f.errors, dirtyFields = _f.dirtyFields, reset = form.reset, watch = form.watch, getValues = form.getValues, setValue = form.setValue;
    var isDirty = !!Object.keys(dirtyFields).length;
    var submit = function (callback) {
        handleSubmit(onSubmit(callback))();
    };
    var submitReset = function (value) {
        onSubmit()(value);
    };
    var resetSecretId = function () {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.SETTINGS.COMPANY.RESET_SECRET_ID),
            children: (React.createElement(AYSModal, { text: t(i18nKeys.SETTINGS.COMPANY.AYS_RESET_SECRET_ID), onConfirm: function () {
                    return accountActions.resetSecretId({
                        callback: function (_a) {
                            var secret = _a.secret;
                            showDialog({
                                id: DialogShowId.CUSTOM,
                                size: DialogShowSize.SMALL,
                                title: t(i18nKeys.SETTINGS.COMPANY.RESET_SECRET_ID),
                                children: (React.createElement("div", { className: styles('new-secret-id') },
                                    React.createElement("div", { className: styles('message') },
                                        React.createElement(Icon, { name: IconName.ALERT_CIRCLE }),
                                        ' ',
                                        t(i18nKeys.SETTINGS.COMPANY.NOT_SHOW_SECRET_ID_ANYMORE)),
                                    React.createElement("div", null,
                                        t(i18nKeys.SETTINGS.COMPANY.SECRET_ID),
                                        " : ",
                                        secret))),
                            });
                        },
                    });
                } })),
        });
    };
    var _g = watch(), bank_accounts_attributes = _g.bank_accounts_attributes, company_bank_accounts = _g.company_bank_accounts, default_bank_account_id = _g.default_bank_account_id, dirty = _g.dirty;
    var setFormDirty = function () {
        setTimeout(function () {
            setValue('dirty', dirty ? dirty + 1 : 1, { shouldDirty: true });
        });
    };
    var addBankAccount = function () {
        var values = getValues();
        reset(__assign(__assign({}, values), { company_bank_accounts: __spreadArray(__spreadArray([], __read((values.company_bank_accounts || [])), false), [
                { iban: '', bic: '' },
            ], false) }));
        setFormDirty();
    };
    var deleteBankAccount = function (index) { return function () {
        var bank_accounts = company_bank_accounts.slice();
        bank_accounts.splice(index, 1);
        reset(__assign(__assign({}, getValues()), { company_bank_accounts: bank_accounts }), { keepTouched: true, keepDirty: true });
        setFormDirty();
    }; };
    var changeDefaultBankAccount = function (bankAccount) { return function () {
        if (default_bank_account_id != bankAccount.id) {
            setValue('default_bank_account_id', bankAccount.id, {
                shouldDirty: true,
            });
        }
    }; };
    register('default_bank_account_id');
    register('dirty');
    var descriptionText = t(i18nKeys.SETTINGS.COMPANY.BANK_ACCOUNT_DESCRIPTION);
    var countriesForSelect = Object.entries(countries.getNames(currentLang)).map(function (_a) {
        var _b = __read(_a, 2), value = _b[0], description = _b[1];
        return ({ value: value, description: description });
    });
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement(NavigationPrompt, { when: isDirty, onSaveAndQuit: submit }),
        React.createElement("form", { className: styles('company-settings-form'), onSubmit: handleSubmit(submitReset) },
            React.createElement("div", { className: styles('wrapper') },
                React.createElement("div", { className: styles('content') },
                    React.createElement("div", { className: styles('grid-row', 'data-inputs') },
                        React.createElement("div", { className: styles('col-6') },
                            React.createElement(Input, { register: register('name', { required: true }), errorMessage: errors.name, label: t(i18nKeys.FORM.COMPANY.LEGAL_NAME), className: styles('input'), noMargin: true })),
                        React.createElement("div", { className: styles('col-6') },
                            React.createElement(Input, { register: register('company_email', {
                                    required: true,
                                    validate: { email: email },
                                }), errorMessage: errors.company_email, className: styles('input'), type: "email", label: t(i18nKeys.EMAIL), noMargin: true })),
                        React.createElement("div", { className: styles('col-6') },
                            React.createElement(Input, { register: register('address_attributes.street_name', {
                                    required: true,
                                }), errorMessage: (_b = errors.address_attributes) === null || _b === void 0 ? void 0 : _b.street_name, type: "text", label: t(i18nKeys.FORM.ADDRESS.STREET), className: styles('input') })),
                        React.createElement("div", { className: styles('col-1_5') },
                            React.createElement(Input, { register: register('address_attributes.street_number', {
                                    required: true,
                                }), errorMessage: (_c = errors.address_attributes) === null || _c === void 0 ? void 0 : _c.street_number, label: t(i18nKeys.NUMBER), noErrorText: true, type: "text", className: styles('input') })),
                        React.createElement("div", { className: styles('col-1_5') },
                            React.createElement(Input, { register: register('address_attributes.street_box'), label: t(i18nKeys.FORM.ADDRESS.BOX), className: styles('input'), type: "text" })),
                        React.createElement("div", { className: styles('col-3') },
                            React.createElement(Input, { register: register('address_attributes.zip_code'), className: styles('input'), type: "text", label: t(i18nKeys.FORM.ADDRESS.POSTAL_CODE) })),
                        React.createElement("div", { className: styles('col-6', 'col-city') },
                            React.createElement(Input, { register: register('address_attributes.city', {
                                    required: true,
                                }), errorMessage: (_d = errors.address_attributes) === null || _d === void 0 ? void 0 : _d.city, label: t(i18nKeys.FORM.ADDRESS.CITY), className: styles('input'), type: "text" })),
                        React.createElement("div", { className: styles('col-6') },
                            React.createElement(Controller, { name: "address_attributes.country_code", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: "address_attributes.country_code", keyText: "description", keyValue: "value", filter: true, items: countriesForSelect, label: t(i18nKeys.FORM.ADDRESS.COUNTRY) })); } })),
                        React.createElement("div", { className: styles('col-6') },
                            React.createElement(Input, { register: register('phones_attributes.0.number'), type: "text", label: t(i18nKeys.PHONES) })),
                        React.createElement("div", { className: styles('col-6') },
                            React.createElement(Controller, { rules: { required: true }, name: "locale", render: function () { return (React.createElement(CustomSelect, { name: "locale", items: AVAILABLE_LANGUAGES, keyText: "description", keyValue: "value", label: t(i18nKeys.FORM.COMPANY.LANG) })); } })),
                        React.createElement("div", { className: styles('col-6') },
                            React.createElement(Controller, { rules: { required: true }, name: "currency", render: function () { return (React.createElement(CustomSelect, { name: "currency", items: AVAILABLE_CURRENCIES, keyText: "description", keyValue: "value", label: t(i18nKeys.FORM.COMPANY.CURRENCY) })); } })),
                        React.createElement("div", { className: styles('col-6') },
                            React.createElement(Controller, { rules: { required: true }, name: "currency_conversion_date", render: function () { return (React.createElement(CustomSelect, { name: "currency_conversion_date", items: [
                                        {
                                            description: "Date d'émission",
                                            value: 'ISSUE_DATE',
                                        },
                                        {
                                            description: 'Date du jour',
                                            value: 'CURRENT_DATE',
                                        },
                                    ], keyText: "description", keyValue: "value", label: t(i18nKeys.FORM.COMPANY.CURRENCY_CONVERSION_DATE) })); } })),
                        React.createElement("div", { className: styles('col-6') },
                            React.createElement(Input, { register: register('vat_number', { required: true }), errorMessage: errors.vat_number, label: t(i18nKeys.VAT_NUMBER), disabled: initialValues.vat_number !== null, type: "text", className: styles('input') })),
                        React.createElement("div", { className: styles('subtitle'), style: { paddingLeft: '10px' } },
                            React.createElement("h3", null, t(i18nKeys.BANK_ACCOUNTS)),
                            React.createElement("div", null,
                                React.createElement(Icon, { name: IconName.PLUS, size: "14px", onClick: addBankAccount }))),
                        React.createElement("div", { className: styles('info'), style: { paddingLeft: '10px' } },
                            descriptionText.substring(0, descriptionText.indexOf('$')),
                            React.createElement(Icon, { name: IconName.STAR_FULL }),
                            descriptionText.substring(descriptionText.indexOf('}') + 1)),
                        React.createElement("div", { className: styles('col-12', 'bank-accounts') }, bank_accounts_attributes === null || bank_accounts_attributes === void 0 ? void 0 : bank_accounts_attributes.map(function (bankAccount, index) { return (React.createElement("div", { key: bankAccount.id, className: styles('bank-account') },
                            React.createElement(Input, { label: "IBAN", disabled: true, register: register("bank_accounts_attributes.".concat(index, ".iban")) }),
                            ' ',
                            React.createElement(Input, { label: "BIC", disabled: true, register: register("bank_accounts_attributes.".concat(index, ".bic")) }),
                            React.createElement("input", __assign({}, register("bank_accounts_attributes.".concat(index, ".id")), { className: styles('invisible-input'), disabled: true })),
                            React.createElement("div", { className: styles('bank-account-actions') }, default_bank_account_id == bankAccount.id ? (React.createElement(Icon, { name: IconName.STAR_FULL })) : (React.createElement(Icon, { name: IconName.STAR_EMPTY, onClick: changeDefaultBankAccount(bankAccount) }))))); })),
                        React.createElement("div", { className: styles('col-12', 'bank-accounts') }, company_bank_accounts === null || company_bank_accounts === void 0 ? void 0 : company_bank_accounts.map(function (bankAccount, index) {
                            var _a, _b;
                            return (React.createElement("div", { key: index, className: styles('bank-account') },
                                React.createElement("input", __assign({}, register("company_bank_accounts.".concat(index, ".id")), { className: styles('invisible-input'), readOnly: true })),
                                React.createElement(Input, { label: "IBAN", errorMessage: (_b = (_a = errors === null || errors === void 0 ? void 0 : errors.company_bank_accounts) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.iban, register: register("company_bank_accounts.".concat(index, ".iban"), {
                                        required: true,
                                    }) }),
                                React.createElement(Input, { label: "BIC", register: register("company_bank_accounts.".concat(index, ".bic")) }),
                                React.createElement("div", { className: styles('bank-account-actions') }, initialValues.banking_method === 'no_bank' ? (bankAccount.id == default_bank_account_id ? (React.createElement(Icon, { name: IconName.STAR_FULL })) : (React.createElement(React.Fragment, null,
                                    company_bank_accounts[index].id && (React.createElement(Icon, { name: IconName.STAR_EMPTY, onClick: changeDefaultBankAccount(bankAccount) })),
                                    React.createElement(Icon, { name: IconName.TRASH_SIMPLE, onClick: deleteBankAccount(index) })))) : (React.createElement(Icon, { name: IconName.TRASH_SIMPLE, onClick: deleteBankAccount(index) })))));
                        })))),
                initialValues.api.client_id && (React.createElement("div", { className: styles('content', 'api') },
                    React.createElement("div", { className: styles('grid-row', 'data-inputs') },
                        React.createElement("h3", { style: { paddingLeft: '10px' } }, "API"),
                        React.createElement("div", { className: styles('col-6') },
                            React.createElement("div", { className: styles('label') },
                                React.createElement(Input, { register: undefined, disabled: true, label: t(i18nKeys.SETTINGS.CLIENT_ID), className: styles('input'), value: initialValues.api.client_id }))),
                        React.createElement("div", { className: styles('col-6') },
                            React.createElement("div", { className: styles('label') },
                                React.createElement(Input, { register: undefined, disabled: true, label: t(i18nKeys.SETTINGS.COMPANY.SECRET_ID), className: styles('input'), value: "*************" }),
                                React.createElement(Button, { small: true, color: ButtonColor.GREY, noShadow: true, noMargin: true, label: t(i18nKeys.RESET), onClick: resetSecretId })))))),
                React.createElement("div", { className: styles('button-wrapper') },
                    React.createElement(Button, { disabled: !isDirty, label: t(i18nKeys.SAVE), type: "submit" }))))));
}
