import { __makeTemplateObject } from "tslib";
import React from 'react';
import { Icon } from 'shared/components/Icon';
import { css, cx } from '@emotion/css';
import { Box, Group, Stack, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { IconCircleCheckFilled } from '@tabler/icons-react';
var styles = {
    card: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    cursor: pointer;\n    flex-wrap: nowrap;\n    position: relative;\n    border-radius: var(--mantine-radius-md);\n    border: 1px solid var(--mantine-color-gray-3);\n    opacity: 1;\n    transition: box-shadow 100ms ease, transform 100ms ease;\n\n    :hover {\n      box-shadow: var(--mantine-shadow-sm);\n      transform: scale(1.01);\n    }\n  "], ["\n    cursor: pointer;\n    flex-wrap: nowrap;\n    position: relative;\n    border-radius: var(--mantine-radius-md);\n    border: 1px solid var(--mantine-color-gray-3);\n    opacity: 1;\n    transition: box-shadow 100ms ease, transform 100ms ease;\n\n    :hover {\n      box-shadow: var(--mantine-shadow-sm);\n      transform: scale(1.01);\n    }\n  "]))),
    cardDisabled: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    cursor: not-allowed;\n    opacity: 0.5;\n    pointer-events: none;\n  "], ["\n    cursor: not-allowed;\n    opacity: 0.5;\n    pointer-events: none;\n  "]))),
};
export var IntegrationsCard = function (_a) {
    var integration = _a.integration, onClick = _a.onClick;
    var theme = useMantineTheme();
    var title = integration.title, icon = integration.icon, description = integration.description, canActivate = integration.canActivate, isActive = integration.isActive;
    var iconElement = typeof icon === 'string' ? React.createElement(Icon, { name: icon, size: "50px" }) : icon;
    var canReallyActivate = canActivate || isActive;
    return (React.createElement(UnstyledButton, { disabled: !canReallyActivate, p: "md", className: cx(styles.card, !canReallyActivate && styles.cardDisabled), onClick: function () {
            if (canReallyActivate)
                onClick(integration);
        } },
        React.createElement(Group, { style: { flexWrap: 'nowrap' } },
            React.createElement(Box, { w: 50, mx: "md" }, iconElement),
            React.createElement(Stack, null,
                React.createElement(Text, { size: "lg", fw: "bold" }, title),
                React.createElement(Text, { c: "dimmed" }, description))),
        isActive && (React.createElement("div", { style: { position: 'absolute', top: '10px', right: '10px' } },
            React.createElement(IconCircleCheckFilled, { color: theme.colors.blue[5], stroke: 1.5 })))));
};
var templateObject_1, templateObject_2;
