import { __assign } from "tslib";
import React, { useEffect } from 'react';
import { isNullish } from 'remeda';
import { Box, Checkbox } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { i18nKeys, useTranslation } from '../../../../locales';
import { UpdateTaskFormSchema, useUpdateTaskSettings, } from '../../../../shared/hooks';
import { useGetCompany } from '../../../../shared/utils/selectors';
import { MantineSettingsCard } from '../MantineSettingsCard';
export function TaskSettings() {
    var t = useTranslation().t;
    var company = useGetCompany();
    var updateTaskSetting = useUpdateTaskSettings().updateTaskSetting;
    var form = useForm({
        validate: zodResolver(UpdateTaskFormSchema),
        validateInputOnBlur: true,
    });
    useEffect(function () {
        if (isNullish(company))
            return;
        form.setInitialValues({ autoclose_tasks: company.autoclose_tasks });
        form.setValues({ autoclose_tasks: company.autoclose_tasks });
    }, [company === null || company === void 0 ? void 0 : company.autoclose_tasks]);
    var handleSave = function () {
        updateTaskSetting(form.getValues());
    };
    return (React.createElement(MantineSettingsCard, { onSave: form.isDirty() ? handleSave : undefined, title: t(i18nKeys.FORM.PREFERENCES.TASKS.TITLE) },
        React.createElement(Box, { mx: "sm" },
            React.createElement(Checkbox, __assign({ my: "xs", label: t(i18nKeys.FORM.PREFERENCES.TASKS.AUTO_UPDATE_LABEL) }, form.getInputProps('autoclose_tasks', { type: 'checkbox' }))))));
}
