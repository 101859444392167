import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import InvoiceReferences from 'shared/components/InvoiceReferences/InvoiceReferences';
import { formattedDate } from 'shared/utils/view';
import { StepsCalculationWorkflow } from '../PaymentPlanForm/StepsCalculation/StepsCalculationWorkflow';
import styleIdentifiers from '../PaymentPlanForm/PaymentPlanForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var PaymentPlanProperties = function (_a) {
    var _b;
    var paymentPlan = _a.paymentPlan, addedInvoices = _a.addedInvoices, newBalance = _a.newBalance;
    var _c = useTranslation(), t = _c.t, currentLang = _c.currentLang;
    var _d = paymentPlan.attributes, debtor = _d.debtor, invoices = _d.invoices, workflowName = _d.workflow.attributes.name, remaining_balance = _d.remaining_balance, created_at = _d.created_at, with_fees = _d.with_fees, currency = _d.currency, total_amount = _d.total_amount;
    var invoiceIds = (_b = invoices.details) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
        var id = _a.id;
        return id;
    });
    var full_name = debtor.attributes.full_name;
    var canEditWorkflow = paymentPlan.attributes.payment_steps_attributes.every(function (entry) { return entry.attributes.can_edit; });
    return (React.createElement(Card, { title: t(i18nKeys.DETAILS) },
        React.createElement("div", { className: styles('details', addedInvoices && 'additional-info') },
            React.createElement("div", { className: "left" },
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.COMMON.CLIENT)),
                    React.createElement("span", { className: styles('value') }, full_name)),
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.INVOICES)),
                    React.createElement("span", { className: styles('value') },
                        React.createElement(InvoiceReferences, { ids: invoiceIds }))),
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.WITH_FEES)),
                    React.createElement("span", { className: styles('value') }, t(i18nKeys[with_fees ? 'YES' : 'NO'])))),
            React.createElement("div", { className: "right" },
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.CREATION_DATE)),
                    React.createElement("span", { className: styles('value') }, formattedDate(created_at, currentLang))),
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') },
                        t(i18nKeys.INVOICE.UNMATCH_PAYMENT.RECONCILIATION_AMOUNT),
                        ' / ',
                        addedInvoices
                            ? t(i18nKeys.FORM.PAYMENT_PLAN.PROPERTIES.OLD_BALANCE)
                            : t(i18nKeys.TOTAL)),
                    React.createElement("span", { className: styles('value') },
                        React.createElement(Amount, { value: total_amount - remaining_balance, suffix: currency }),
                        React.createElement("span", { style: { margin: '0 5px' } }, "/"),
                        React.createElement(Amount, { value: total_amount, suffix: currency }))),
                canEditWorkflow ? (React.createElement("div", { style: { margin: '4px 0px 8px 0px' } },
                    React.createElement(StepsCalculationWorkflow, { canUnselect: false, setCurrentWorkflow: function () { } }))) : (React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.PLAN.NAME)),
                    React.createElement("span", { className: styles('value') }, workflowName))))),
        addedInvoices && (React.createElement("div", { className: styles('details') },
            React.createElement("div", { className: "left" },
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.FORM.PAYMENT_PLAN.PROPERTIES.ADDED_INVOICES)),
                    React.createElement("span", { className: styles('value') },
                        React.createElement(InvoiceReferences, { ids: addedInvoices })))),
            React.createElement("div", { className: "right" },
                React.createElement("div", { className: styles('subtitle-value') },
                    React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.FORM.PAYMENT_PLAN.PROPERTIES.NEW_BALANCE)),
                    React.createElement("span", { className: styles('value') },
                        React.createElement(Amount, { value: newBalance, suffix: currency }))))))));
};
