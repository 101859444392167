import { __assign, __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { QuickActionMenu } from 'app/Private/QuickActionMenu';
import UserMenuButton from 'modules/Navigation/components/UserMenuButton';
import { useHistory, useLocation } from 'react-router';
import { useScrollShadow } from 'shared/hooks/ui/use-scroll-shadow';
import { useIsMobile } from 'shared/hooks/utils';
import { ActionIcon, Group, ScrollArea, Space, Stack } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import Logo from '../../shared/components/Logo';
import { MobileMenuButton } from './components/MobileMenuButton';
import { NavBackButton } from './components/NavBackButton';
import { NavMenu } from './components/NavMenu';
import { NavMenuItem } from './components/NavMenuItem';
import { Spotlight } from './components/Spotlight';
import { useBuildMenuItems } from './hooks/use-build-menu-items';
import { findMenuForLocation, isMenu } from './utils';
export var NavBar = function (_a) {
    var closeNavbarOnMobile = _a.closeNavbarOnMobile, onClickOpenNavbar = _a.onClickOpenNavbar;
    var history = useHistory();
    var location = useLocation();
    var isMobile = useIsMobile();
    var rootMenu = useBuildMenuItems();
    var _b = __read(useState(findMenuForLocation(rootMenu, location.pathname) || rootMenu), 2), currentMenu = _b[0], setCurrentMenu = _b[1];
    var _c = useScrollShadow(currentMenu.label), topShadow = _c.topShadow, bottomShadow = _c.bottomShadow, scrollableRef = _c.scrollableRef;
    useEffect(function () {
        var _a;
        setCurrentMenu((_a = findMenuForLocation(rootMenu, location.pathname)) !== null && _a !== void 0 ? _a : rootMenu);
    }, [location.pathname, rootMenu]);
    var parentMenu = currentMenu.parent;
    return (React.createElement(React.Fragment, null,
        React.createElement(Spotlight, null),
        React.createElement(Stack, { py: 24, px: 12, h: "100%", gap: 0, pos: "relative" },
            React.createElement(MobileMenuButton, { onClickOpenNavbar: onClickOpenNavbar }),
            React.createElement(Group, { mb: 30, ml: 4, mt: 6 },
                React.createElement(Group, { flex: "1", onClick: function () {
                        history.push('/');
                        closeNavbarOnMobile();
                    }, style: { cursor: 'pointer', userSelect: 'none' } },
                    React.createElement(Logo, { height: 42, type: "blue" })),
                React.createElement(ActionIcon, { hiddenFrom: "mobile", color: "gray.6", variant: "transparent", onClick: closeNavbarOnMobile },
                    React.createElement(IconX, null))),
            parentMenu && (React.createElement(React.Fragment, null,
                React.createElement(NavBackButton, { onClick: function () { return setCurrentMenu(parentMenu); }, label: currentMenu.label }),
                React.createElement(Space, { h: 14 }))),
            React.createElement(ScrollArea, { flex: 1, viewportRef: scrollableRef },
                topShadow,
                bottomShadow,
                currentMenu.items.map(function (item) {
                    return isMenu(item) ? (React.createElement(NavMenu, __assign({ key: item.label }, item, { menu: item, setMenu: setCurrentMenu }))) : (React.createElement(NavMenuItem, __assign({ key: item.label }, item, { onNavigate: isMobile ? closeNavbarOnMobile : function () { } })));
                })),
            React.createElement("div", { id: "variants-portal" }),
            React.createElement(Space, { h: 18 }),
            React.createElement(QuickActionMenu, null),
            React.createElement(Space, { h: 24 }),
            React.createElement(UserMenuButton, null))));
};
