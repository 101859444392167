import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import Swiper from 'react-swipe';
import { useIsMobile } from 'shared/hooks/utils';
import { useIsFirstRender } from '@mantine/hooks';
import { Icon, IconName } from '../Icon';
import styleIdentifiers from './Tabs.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Tabs(_a) {
    var items = _a.items, children = _a.children, className = _a.className, tabIndex = _a.tabIndex, onChange = _a.onChange, disableScroll = _a.disableScroll, activeClassName = _a.activeClassName, noRounded = _a.noRounded, _b = _a.noBody, noBody = _b === void 0 ? false : _b;
    var isMobile = useIsMobile();
    var _c = __read(useState(tabIndex || 0), 2), currentIndex = _c[0], setCurrentIndex = _c[1];
    var isFirstRender = useIsFirstRender();
    var reactSwipeEl;
    useEffect(function () {
        if (tabIndex && !isFirstRender)
            setActive(tabIndex)();
    }, [tabIndex]);
    var setActive = function (index) { return function () {
        if (!noBody)
            reactSwipeEl.slide(index);
        onChange === null || onChange === void 0 ? void 0 : onChange(index, currentIndex);
        setCurrentIndex(index);
    }; };
    var nextSlide = function (e) {
        e.stopPropagation();
        onChange === null || onChange === void 0 ? void 0 : onChange(currentIndex + 1, currentIndex);
        setCurrentIndex(currentIndex + 1);
        if (!noBody)
            reactSwipeEl.next();
    };
    var previousSlide = function (e) {
        e.stopPropagation();
        onChange === null || onChange === void 0 ? void 0 : onChange(currentIndex - 1, currentIndex);
        setCurrentIndex(currentIndex - 1);
        if (!noBody) {
            reactSwipeEl.prev();
        }
    };
    return (React.createElement("div", { className: styles('tabs', noRounded && 'no-rounded', noBody && 'noBody', className) },
        React.createElement("div", { className: styles('header'), style: {
                transform: isMobile ? "translate( -".concat(currentIndex, "00%)") : '',
            } }, items.map(function (item, index) { return (React.createElement("div", { className: styles('header-item', currentIndex === index && 'active', currentIndex === index && activeClassName), key: index, onClick: setActive(index) },
            index !== 0 && (React.createElement(Icon, { name: IconName.MINIMAL_LEFT, onClick: previousSlide, className: styles('prev') })),
            React.createElement("div", { className: styles('children') }, item),
            items.length - 1 !== currentIndex && (React.createElement(Icon, { name: IconName.MINIMAL_RIGHT, onClick: nextSlide, className: styles('next') })),
            React.createElement("div", { className: styles('line') }))); })),
        !noBody && (React.createElement("div", { className: styles('body') },
            React.createElement(Swiper, { className: styles('swiper'), swipeOptions: { continuous: false, disableScroll: disableScroll, speed: 400 }, ref: function (el) { return (reactSwipeEl = el); } }, children)))));
}
