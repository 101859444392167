import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';
var WarningsSchema = z.array(z.object({
    year: z.number().nullable(),
    descriptions: z.array(z.string()),
}));
var CreditLimitSchema = z.object({
    limit: z.coerce.number(),
    status: z.string().nullable(),
});
var ScoreSchema = z.object({
    value: z.number(),
    image: z.string(),
});
var MonitoringSchema = z
    .object({
    warnings: WarningsSchema,
    score: ScoreSchema,
    warningsUrl: z.string().url().nullable(),
    reportUrl: z.string().url(),
    isMonitoringActive: z.boolean(),
    updatedAt: z.string().nullable(),
    creditLimit: CreditLimitSchema,
})
    .nullable();
export function loadMonitoringDataFn(axiosInstance_1, _a) {
    return __awaiter(this, arguments, void 0, function (axiosInstance, _b) {
        var instance, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _c.sent();
                    return [4 /*yield*/, instance.get("/debtors/".concat(queryKey[1], "/monitoring"))];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, MonitoringSchema.parse(data)];
            }
        });
    });
}
export var useLoadMonitoringData = function (debtorId) {
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['monitoring', debtorId],
        queryFn: function (context) { return loadMonitoringDataFn(axiosInstance, context); },
    });
    return addResourceNameToQueryResult('monitoring', queryResult);
};
