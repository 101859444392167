import { __assign, __read, __spreadArray, __values } from "tslib";
import React, { useEffect, useReducer, useState } from 'react';
import AnimateNumber from 'animated-number-react';
import { useRequestPaymentPlan } from 'api';
import classNames from 'classnames/bind';
import { jotaiStore } from 'init';
import { currentLanguageAtom, i18nKeys, useTranslation } from 'locales/';
import qs from 'query-string';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Icon, IconName } from 'shared/components/Icon';
import LanguagePicker from 'shared/components/LanguagePicker/LanguagePicker';
import Logo from 'shared/components/Logo';
import Tabs from 'shared/components/Tabs';
import { Button, ButtonColor } from 'shared/io';
import apiService from 'shared/service/api.service';
import { isProduction } from 'shared/utils/environment';
import { useLocalizedCurrencyFormatter } from 'shared/utils/normalization';
import { setQueryParams } from 'shared/utils/url';
import { reducerState, removeAttributes } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { dialogHide, DialogShowId, DialogShowSize, error, showDialog, success, } from 'store/view/view.actions';
import { AVAILABLE_LANGUAGES } from 'types';
import RequestPaymentPlanModal from './RequestPaymentPlanModal/RequestPaymentPlanModal';
import DebtorReactionCardHeader from './DebtorReactionCardHeader';
import DebtorReactionInvoicesList from './DebtorReactionInvoicesList';
import DebtorReactionPaymentPlansList from './DebtorReactionPaymentPlansList';
import styleIdentifiers from './debtorReaction.scss';
var styles = classNames.bind(styleIdentifiers);
export default function DebtorReaction() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var _l = useTranslation(), t = _l.t, currentLang = _l.currentLang;
    var _m = __read(useState(''), 2), logoUrl = _m[0], setLogoUrl = _m[1];
    var location = useLocation();
    var params = useRouteMatch().params;
    var _o = __read(useState(qs.parse(location.search)), 1), queryStrings = _o[0];
    var _p = __read(useReducer(reducerState, null), 2), data = _p[0], setData = _p[1];
    var _q = __read(useState([]), 2), selectedInvoices = _q[0], setSelectedInvoices = _q[1];
    var _r = __read(useState(false), 2), isAllSelected = _r[0], setSelectAll = _r[1];
    var _s = __read(useState(false), 2), isInvoicesTabActive = _s[0], setIsInvoicesTabActive = _s[1];
    var form = useForm();
    var formatter = useLocalizedCurrencyFormatter(false, (_b = (_a = data === null || data === void 0 ? void 0 : data.debtor_data) === null || _a === void 0 ? void 0 : _a.currency) !== null && _b !== void 0 ? _b : 'EUR');
    var requestPaymentPlan = useRequestPaymentPlan().mutate;
    useEffect(function () {
        if (queryStrings.paymentError) {
            error({
                text: i18nKeys.DEBTOR.REACTION.PAYMENT_FAILED,
            });
            setQueryParams('paymentError', undefined);
        }
        if (queryStrings.paymentSucceeded) {
            success({
                text: i18nKeys.DEBTOR.REACTION.PAYMENT_SUCCEEDED,
            });
            setQueryParams('paymentSucceeded', undefined);
        }
        invoiceActions.debtorReaction({
            id: params.id,
            data: {
                token: queryStrings.document_token,
            },
            callback: function (debtorReactionData) {
                debtorReactionData.invoices.data = debtorReactionData.invoices.data.map(function (invoice) {
                    return removeAttributes(invoice);
                });
                debtorReactionData.credit_notes = debtorReactionData.credit_notes.map(function (cn) {
                    return removeAttributes(cn);
                });
                debtorReactionData.payments_sum = -debtorReactionData.payments_sum;
                if (debtorReactionData.company_data.has_logo) {
                    invoiceActions.document({
                        id: params.id,
                        public: true,
                        noFeedBack: true,
                        noLoading: true,
                        fileUpload: true,
                        document_type: 'company_logo',
                        data: {
                            token: queryStrings.document_token,
                        },
                        callback: function (logo) {
                            if (logo)
                                setLogoUrl(URL.createObjectURL(logo));
                        },
                    });
                }
                setData(__assign(__assign({}, debtorReactionData), { totalRemainingBalance: debtorReactionData.invoices.data.reduce(function (acc, invoice) {
                        return acc + Number(invoice.localized_money_object.remaining_balance_with_fees);
                    }, 0) +
                        debtorReactionData.credit_notes.reduce(function (acc, cn) { return acc + Number(cn.localized_money_object.remaining_balance); }, 0) +
                        debtorReactionData.payments_sum }));
            },
        });
        return function () {
            if (logoUrl)
                URL.revokeObjectURL(logoUrl);
        };
    }, []);
    if (data == null || data.company_data == null)
        return null;
    var company_data = data.company_data;
    // Here they've added a debtor data into a company object : company_data.can_request_payment_plans
    var shouldDisplayPaymentPlanTab = company_data.can_request_payment_plans && company_data.can_use_payment_plans;
    var isFrenchClient = company_data.country_code === 'FR';
    var hasPaymentOptions = isFrenchClient ? company_data.use_digiteal_integration : true;
    var invoices = isInvoicesTabActive
        ? ((_c = data === null || data === void 0 ? void 0 : data.paidInvoices) === null || _c === void 0 ? void 0 : _c.data) || []
        : ((_d = data === null || data === void 0 ? void 0 : data.invoices) === null || _d === void 0 ? void 0 : _d.data) || [];
    var downloadCgv = function () {
        invoiceActions.document({
            id: params.id,
            public: true,
            document_type: 'payment_terms_pdf',
            data: {
                token: queryStrings.document_token,
            },
        });
    };
    var handleSelectAll = function (active) {
        var e_1, _a, e_2, _b;
        var newFormValues = {
            payment: active,
        };
        setSelectedInvoices(active
            ? __spreadArray(__spreadArray(__spreadArray([], __read((invoices || []).slice()), false), __read((data.credit_notes || []).slice()), false), [
                {
                    id: 'payment',
                    remaining_balance_with_fees: data.payments_sum,
                },
            ], false) : []);
        try {
            for (var _c = __values(invoices || []), _d = _c.next(); !_d.done; _d = _c.next()) {
                var invoice = _d.value;
                newFormValues[invoice.id] = active;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        try {
            for (var _e = __values((data === null || data === void 0 ? void 0 : data.credit_notes) || []), _f = _e.next(); !_f.done; _f = _e.next()) {
                var cn = _f.value;
                newFormValues[cn.id] = active;
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
            }
            finally { if (e_2) throw e_2.error; }
        }
        form.reset(newFormValues);
        setSelectAll(active);
    };
    var onCheckboxChange = function (invoice) { return function (checked) {
        var newSelectedInvoices = selectedInvoices.slice();
        if (checked) {
            newSelectedInvoices.push(invoice);
        }
        else {
            newSelectedInvoices.splice(newSelectedInvoices.findIndex(function (selectedInvoice) { return selectedInvoice.id === invoice.id; }), 1);
        }
        if (newSelectedInvoices.length ===
            invoices.length + data.credit_notes.length + (data.payments_sum !== 0 ? 1 : 0)) {
            !isAllSelected && setSelectAll(true);
        }
        else {
            isAllSelected && setSelectAll(false);
        }
        setSelectedInvoices(newSelectedInvoices);
    }; };
    var amountToPay = selectedInvoices.reduce(function (acc, invoice) {
        return acc +
            (invoice.id === 'payment'
                ? data.payments_sum
                : invoice.type === 'debtor_reaction_credit_note'
                    ? +invoice.localized_money_object.remaining_balance
                    : +invoice.localized_money_object.remaining_balance_with_fees);
    }, 0);
    var paymentLink = function (structured_communication, amount) {
        var fullPayment = Math.round(amount * 100);
        // todo: Is this used in demo? Does it need tailored values for that env?
        window.location.href = "https://".concat(isProduction() ? 'api' : 'test', ".digiteal.eu/api/v1/payment-request/pay-button/execute?requesterVAT=").concat(isProduction() ? company_data.vat_number : 'BE0206607129', "&creditorReference=").concat(structured_communication, "&amountInCents=").concat(fullPayment, "&iban=").concat(isProduction() ? company_data.iban : 'BE95330012367958', "&language=").concat(currentLang.toUpperCase(), "&confirmationURL=").concat(encodeURIComponent("".concat(window.location.href, "&paymentSucceeded=true")), "&errorURL=").concat(encodeURIComponent("".concat(window.location.href, "&paymentError=true")), "&cancelURL=").concat(encodeURIComponent(window.location.href));
    };
    var showQrCode = function (invoicesSelected) {
        invoiceActions.debtorReactionQrCode({
            id: params.id,
            data: {
                invoice_ids: invoicesSelected.map(function (invoice) { return invoice.id; }),
                token: queryStrings.document_token,
            },
            callback: function (_a) {
                var response = _a.data;
                showDialog({
                    id: DialogShowId.CUSTOM,
                    size: DialogShowSize.MEDIUM,
                    title: t(i18nKeys.DEBTOR.REACTION.PAYMENT_QR_CODE),
                    children: (React.createElement("div", { className: styles('qr-code-modal') },
                        React.createElement("div", null, t(i18nKeys.DEBTOR.REACTION.QR_CODE_DESCRIPTION)),
                        React.createElement("img", { src: response, alt: "qr code" }))),
                });
            },
        });
    };
    var pay = function (invoicesToPay) {
        if (!company_data.use_digiteal_integration) {
            showQrCode(invoicesToPay);
        }
        else if (isAllSelected ? data.invoices.data.length === 1 : invoicesToPay.length === 1) {
            paymentLink(invoicesToPay[0].structured_communication, amountToPay);
        }
        else {
            invoiceActions.getGroupedInvoicePaymentInfo({
                id: params.id,
                data: {
                    invoice_ids: invoicesToPay.map(function (invoice) { return invoice.id; }),
                    token: queryStrings.document_token,
                },
                callback: function (_a) {
                    var response = _a.data;
                    paymentLink(response.attributes.structured_communication, amountToPay);
                },
            });
        }
    };
    var onChangeSelectAll = function () {
        handleSelectAll(!isAllSelected);
    };
    var onChangeTab = function (value, oldTabIndex) {
        if (oldTabIndex === 0) {
            handleSelectAll(false);
        }
        if (value === 0) {
            setIsInvoicesTabActive(false);
        }
        else if (value === 1) {
            setIsInvoicesTabActive(true);
            if (!(data === null || data === void 0 ? void 0 : data.paidInvoices)) {
                invoiceActions.debtorReactionInvoices({
                    id: params.id,
                    data: {
                        page: 1,
                        token: queryStrings.document_token,
                    },
                    callback: function (paidInvoices) {
                        paidInvoices.data = paidInvoices.data.map(function (elements) { return removeAttributes(elements); });
                        setData(__assign(__assign({}, data), { paidInvoices: paidInvoices }));
                    },
                });
            }
        }
        else if (value === 2) {
            setIsInvoicesTabActive(false);
            if (!(data === null || data === void 0 ? void 0 : data.creditNotes)) {
                invoiceActions.debtorReactionCreditNotes({
                    id: params.id,
                    data: {
                        page: 1,
                        token: queryStrings.document_token,
                    },
                    callback: function (creditNotes) {
                        creditNotes.data = creditNotes.data.map(function (elements) { return removeAttributes(elements); });
                        setData(__assign(__assign({}, data), { creditNotes: creditNotes }));
                    },
                });
            }
        }
        else if (value === 3) {
            setIsInvoicesTabActive(false);
            if (!(data === null || data === void 0 ? void 0 : data.payment_plans)) {
                setData(__assign({}, data));
            }
        }
    };
    var handleRequestPaymentPlan = function (planInvoices, amount) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.DEBTOR.REACTION.REQUEST_PAYMENT_PLAN),
            children: (React.createElement(RequestPaymentPlanModal, { invoices: planInvoices, invoiceId: params.id, token: queryStrings.document_token, amount: amount, currency: data.debtor_data.currency, onSubmit: function (values) {
                    requestPaymentPlan({
                        data: values,
                    }, {
                        onSuccess: function () {
                            dialogHide(DialogShowId.CUSTOM);
                        },
                    });
                } })),
        });
    };
    var isAllEligibleForPaymentPlan = selectedInvoices.every(function (invoice) { return invoice.eligible_for_payment_plan; });
    return (React.createElement("div", { className: styles('DebtorReaction') },
        React.createElement("div", { className: styles('layout-container') },
            React.createElement("div", { className: styles('head') },
                React.createElement("div", null,
                    logoUrl && React.createElement("img", { src: logoUrl, alt: "" }),
                    React.createElement("div", null,
                        (data === null || data === void 0 ? void 0 : data.debtor_data) && (React.createElement("h1", null, t(i18nKeys.DEBTOR.REACTION.CLIENT_PORTAL_OF, {
                            text: data.debtor_data.full_name,
                        }))),
                        React.createElement("div", { className: styles('debtor-fullname') },
                            t(i18nKeys.INVOICE.YOUR_INVOICE_FOR),
                            " ",
                            company_data.company_name))),
                React.createElement("div", { className: styles('lang-container') },
                    React.createElement(LanguagePicker, { currentLang: currentLang, availableLang: AVAILABLE_LANGUAGES, changeLang: function (val) { return jotaiStore.set(currentLanguageAtom, val); } }),
                    company_data.has_cgv && (React.createElement("div", { className: styles('cgv'), onClick: downloadCgv },
                        React.createElement(Icon, { name: IconName.DOWNLOAD }),
                        " ",
                        t(i18nKeys.DEBTOR.REACTION.GTC))))),
            React.createElement(FormProvider, __assign({}, form),
                React.createElement(Tabs, { className: styles('tabs'), items: __spreadArray([
                        t(i18nKeys.STATEMENT),
                        t(i18nKeys.INVOICES),
                        t(i18nKeys.NAV.CREDIT_NOTES)
                    ], __read((shouldDisplayPaymentPlanTab ? [t(i18nKeys.NAV.PAYMENT_PLAN)] : [])), false), onChange: onChangeTab },
                    React.createElement("div", { className: styles('card-content') },
                        company_data.can_use_online_payment && (React.createElement(DebtorReactionCardHeader, { data: data, hasPaymentOptions: hasPaymentOptions, selectAll: isAllSelected, onChangeSelectAll: onChangeSelectAll })),
                        React.createElement(DebtorReactionInvoicesList, { billingStatement: true, data: data, items: invoices, metadata: (_e = data === null || data === void 0 ? void 0 : data.invoices) === null || _e === void 0 ? void 0 : _e.metadata, isInvoicesTabActive: isInvoicesTabActive, onCheckboxChange: onCheckboxChange, setData: setData, paymentLink: pay, queryStrings: queryStrings, canUseOnlinePayment: company_data.can_use_online_payment, canUsePaymentPlan: company_data.can_use_payment_plans, canRequestPaymentPlan: company_data.can_request_payment_plans, hasPaymentOptions: hasPaymentOptions, isFrenchClient: isFrenchClient, canUseDigiteal: company_data.use_digiteal_integration }),
                        React.createElement("div", { className: styles('pay-button-container') }, !isInvoicesTabActive && amountToPay > 0 && (React.createElement(React.Fragment, null,
                            company_data.can_use_payment_plans &&
                                company_data.can_request_payment_plans && (React.createElement(Button, { label: t(i18nKeys.PAYMENT_PLAN.TITLE.ONE), onClick: function () { return handleRequestPaymentPlan(selectedInvoices, amountToPay); }, color: ButtonColor.BLUE, iconLeft: IconName.PAYMENT_BOOK, iconSize: "20px", disabled: !isAllEligibleForPaymentPlan })),
                            company_data.can_use_online_payment &&
                                (isFrenchClient ? company_data.use_digiteal_integration : true) && (React.createElement(Button, { onClick: function () { return pay(selectedInvoices); }, color: ButtonColor.MAIN, iconLeft: company_data.use_digiteal_integration
                                    ? IconName.CREDIT_CARD
                                    : IconName.QR_CODE, iconSize: "20px" },
                                React.createElement("span", null,
                                    t(i18nKeys.DEBTOR.REACTION.PAY),
                                    ' : ',
                                    React.createElement(AnimateNumber, { duration: 400, value: Math.max(0, amountToPay), formatValue: function (value) { return formatter.format(value); } })))))))),
                    React.createElement("div", { className: styles('card-content') },
                        React.createElement(DebtorReactionInvoicesList, { data: data, items: ((_f = data === null || data === void 0 ? void 0 : data.paidInvoices) === null || _f === void 0 ? void 0 : _f.data) || [], isInvoicesTabActive: isInvoicesTabActive, onCheckboxChange: onCheckboxChange, metadata: (_g = data === null || data === void 0 ? void 0 : data.paidInvoices) === null || _g === void 0 ? void 0 : _g.metadata, setData: setData, paymentLink: pay, queryStrings: queryStrings, canUseOnlinePayment: company_data.can_use_online_payment, isFrenchClient: isFrenchClient, canUseDigiteal: company_data.use_digiteal_integration })),
                    React.createElement("div", { className: styles('card-content') },
                        React.createElement(DebtorReactionInvoicesList, { data: data, credit: true, items: ((_h = data === null || data === void 0 ? void 0 : data.creditNotes) === null || _h === void 0 ? void 0 : _h.data) || [], metadata: (_j = data === null || data === void 0 ? void 0 : data.creditNotes) === null || _j === void 0 ? void 0 : _j.metadata, isInvoicesTabActive: isInvoicesTabActive, onCheckboxChange: onCheckboxChange, setData: setData, paymentLink: pay, queryStrings: queryStrings, canUseOnlinePayment: company_data.can_use_online_payment })),
                    shouldDisplayPaymentPlanTab && (React.createElement("div", { className: styles('card-content') },
                        React.createElement(DebtorReactionPaymentPlansList, { data: data, items: (((_k = data === null || data === void 0 ? void 0 : data.payment_plans) === null || _k === void 0 ? void 0 : _k.data) || []) }))))),
            company_data.has_trademark && (React.createElement("div", { className: styles('logo') },
                React.createElement("span", null, "Powered by"),
                React.createElement("a", { href: "https://www.recovr.eu/?utm_source=client_referrer&utm_medium=email&utm_campaign=debtor_reaction&utm_content=".concat(apiService.tenant), target: "_blank", rel: "noreferrer" },
                    React.createElement(Logo, { type: "blue", width: "130px" })))))));
}
