import { __assign, __read, __spreadArray } from "tslib";
import React, { useCallback, useEffect, useMemo, useReducer, useState, } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { anyPass, clone, isEmpty, isNullish, omitBy } from 'remeda';
import { Icon, IconName } from 'shared/components/Icon';
import FiltersSideMenu from 'shared/forms/FiltersSideMenu';
import { useProfile } from 'shared/hooks';
import { useIsMobile } from 'shared/hooks/utils';
import { Button, ButtonColor, Input } from 'shared/io';
import { treatTableViews } from 'shared/serializer';
import { getEnvIcon } from 'shared/utils/environment';
import { useGetCompany, useGetConstants, useGetViewsByResource } from 'shared/utils/selectors';
import { formattedDate, queryParamParser, reducerState, removeAttributes } from 'shared/utils/view';
import { appActions } from 'store/app/app.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, sideMenuShow, } from 'store/view/view.actions';
import { Skeleton } from '@mantine/core';
import ChooseColumnModal from '../ChooseColumnModal';
import Tooltip from '../Tooltip';
import { TableFilters } from './TableFilters/TableFilters';
import AddNewView from './AddNewView';
import { quickSearchKey } from './CustomTable.utils';
import CustomTableTitle from './CustomTableTitle';
import TableFooter from './TableFooter';
import TableItem from './TableItem';
import styleIdentifiers from './customTable.scss';
var styles = classNames.bind(styleIdentifiers);
var initState = function (filters) {
    if (filters === void 0) { filters = {}; }
    return ({
        filters: __assign(__assign({}, filters), { sort_by: '' }),
        availableFilters: [],
        filtersName: {},
    });
};
export default function CustomTableViews(_a) {
    var _b, _c, _d;
    var actions = _a.actions, actionsCol = _a.actionsCol, callbackAction = _a.callbackAction, className = _a.className, currentPage = _a.currentPage, handleFilters = _a.handleFilters, isLoading = _a.isLoading, items = _a.items, _e = _a.loadDataGetView, loadDataGetView = _e === void 0 ? true : _e, loadMore = _a.loadMore, maxHeight = _a.maxHeight, noCheckbox = _a.noCheckbox, noFooter = _a.noFooter, noMargin = _a.noMargin, noResultMessage = _a.noResultMessage, noShadow = _a.noShadow, onChangeSelectionCallback = _a.onChangeSelectionCallback, onChangeSelectItemsAcrossAllPagesCallback = _a.onChangeSelectItemsAcrossAllPagesCallback, onClickRow = _a.onClickRow, pagination = _a.pagination, tableName = _a.tableName, title = _a.title;
    var history = useHistory();
    var location = useLocation();
    var _f = useTranslation(), t = _f.t, currentLang = _f.currentLang;
    var profile = useProfile();
    var companyName = (_b = useGetCompany()) === null || _b === void 0 ? void 0 : _b.name;
    var constants = useGetConstants();
    var viewsByResource = useGetViewsByResource();
    var isMobile = useIsMobile();
    var _g = __read(useReducer(reducerState, initState(qs.parse(location.search, { arrayFormat: 'comma' }))), 2), _h = _g[0], filters = _h.filters, availableFilters = _h.availableFilters, filtersName = _h.filtersName, setState = _g[1];
    var _j = __read(useState(), 2), timeoutDebounce = _j[0], setTimeoutDebounce = _j[1];
    var _k = __read(useState(false), 2), areAllItemsAcrossPagesSelected = _k[0], setAreAllItemsAcrossPagesSelected = _k[1];
    var _l = __read(useState([]), 2), selectedIds = _l[0], setSelectedIds = _l[1];
    var currentView = useMemo(function () { var _a; return (_a = viewsByResource[tableName]) === null || _a === void 0 ? void 0 : _a.find(function (view) { return view.id === location.hash.slice(1); }); }, [viewsByResource, location, tableName]);
    var isRemindersView = (currentView === null || currentView === void 0 ? void 0 : currentView.view_type) === 'postponable_invoices';
    var isFetching = isLoading && !isEmpty(items);
    var selectedItems = useMemo(function () { return items.filter(function (item) { return selectedIds.includes(item.id); }); }, [selectedIds]);
    var onChangeSelectionCallbackMemo = useCallback(function (val) { return onChangeSelectionCallback === null || onChangeSelectionCallback === void 0 ? void 0 : onChangeSelectionCallback(val); }, []);
    var onChangeSelectItemsAcrossAllPagesCallbackMemo = useCallback(function (val) { return onChangeSelectItemsAcrossAllPagesCallback === null || onChangeSelectItemsAcrossAllPagesCallback === void 0 ? void 0 : onChangeSelectItemsAcrossAllPagesCallback(val); }, []);
    // =============== EFFECTS ======================
    useEffect(function () {
        if (!isEmpty(selectedIds))
            setSelectedIds([]);
    }, [items]);
    useEffect(function () { return onChangeSelectionCallbackMemo === null || onChangeSelectionCallbackMemo === void 0 ? void 0 : onChangeSelectionCallbackMemo(selectedItems); }, [selectedItems, onChangeSelectionCallbackMemo]);
    useEffect(function () {
        onChangeSelectItemsAcrossAllPagesCallbackMemo === null || onChangeSelectItemsAcrossAllPagesCallbackMemo === void 0 ? void 0 : onChangeSelectItemsAcrossAllPagesCallbackMemo(areAllItemsAcrossPagesSelected);
    }, [areAllItemsAcrossPagesSelected, onChangeSelectItemsAcrossAllPagesCallbackMemo]);
    useEffect(function () {
        document.title = "".concat(getEnvIcon()).concat(companyName, " - ").concat(title);
        return function cleanup() {
            document.title = "".concat(getEnvIcon()).concat(companyName !== null && companyName !== void 0 ? companyName : 'Recovr');
        };
    }, [title, companyName]);
    // Array.every() returns true on empty arrays
    var areAllItemsOnThisPageSelected = !isEmpty(items) && items.every(function (item) { return selectedIds.includes(item.id); });
    var filtersToUrl = function (view) {
        var newFilters = {};
        view.filter_fields.forEach(function (filter) {
            if (filter.applied_filter.operator === 'null' ||
                filter.applied_filter.operator === 'not_null') {
                newFilters["".concat(filter.name, "_handler")] = filter.applied_filter.operator;
            }
            else {
                if (filter.filter_type === 'date') {
                    newFilters["".concat(filter.name, "_start")] = filter.applied_filter.value[0];
                    newFilters["".concat(filter.name, "_end")] = filter.applied_filter.value[1];
                }
                else if (filter.filter_type === 'number') {
                    newFilters["min_".concat(filter.name)] = filter.applied_filter.value[0];
                    newFilters["max_".concat(filter.name)] = filter.applied_filter.value[1];
                    delete newFilters[filter.name];
                }
                else {
                    newFilters[filter.name] = filter.applied_filter.value;
                }
                if (filter.applied_filter.operator) {
                    newFilters["".concat(filter.name, "_operator")] = filter.applied_filter.operator;
                }
            }
        });
        return newFilters;
    };
    useEffect(function () {
        if (timeoutDebounce) {
            clearTimeout(timeoutDebounce);
        }
        if (currentView) {
            var timeout = setTimeout(function () {
                loadData();
            }, 500);
            setTimeoutDebounce(timeout);
        }
    }, [location]);
    // This function executes on initial load to dispatch the page towards the right view
    // and upon closing the view settings modal to ensure that any changes are reflected
    var loadOrRefreshViews = function () {
        appActions.getViews({
            data: {
                view_type: tableName,
            },
            callback: function (_a) {
                var _b;
                var data = _a.data;
                var views = treatTableViews(data);
                var defaultViewId = views.find(function (view) { return view.is_default; }).id;
                var favoriteViewId = (_b = views.find(function (view) { return view.is_favorite; })) === null || _b === void 0 ? void 0 : _b.id;
                var currentViewId = location.hash.replace('#', '');
                var allViewIds = views.map(function (table) { return table.id; });
                var viewToNavigateToId = (function () {
                    // we might have deleted the view we were on in the settings
                    if (currentViewId !== '' && allViewIds.includes(currentViewId))
                        return currentViewId;
                    if (favoriteViewId)
                        return favoriteViewId;
                    return defaultViewId;
                })();
                var view = views.find(function (_view) { return _view.id === viewToNavigateToId; });
                var urlFilters = Object.entries(__assign(__assign({}, filtersToUrl(view)), Object.fromEntries(new URLSearchParams(location.search))));
                history.push("".concat(location.pathname).concat(urlFilters.reduce(function (acc, _a) {
                    var _b = __read(_a, 2), key = _b[0], value = _b[1];
                    return "".concat(acc).concat(key, "=").concat(value, "&");
                }, '?')).concat("#".concat(viewToNavigateToId)));
            },
        });
        appActions.getFilters({
            data: {
                view: tableName,
            },
            callback: function (_a) {
                var data = _a.data;
                var newFilters = data.map(function (item) { return removeAttributes(item); });
                setState({
                    availableFilters: newFilters,
                });
            },
        });
    };
    useEffect(loadOrRefreshViews, [location.hash]);
    useEffect(function () {
        if (!currentView)
            return;
        var newFilters = __assign(__assign({}, filtersToUrl(currentView)), Object.fromEntries(new URLSearchParams(location.search)));
        setState({
            filters: newFilters,
        });
        appActions.getFiltersName({
            data: newFilters,
            callback: function (newFiltersName) {
                setState({
                    filtersName: newFiltersName,
                });
            },
        });
    }, [currentView, history, tableName, viewsByResource]);
    // Meaning: these carry an id instead of the value that should
    // be displayed in the UI
    // Memoized because it is used in another hook's dependency array
    var apiFilters = [
        'user_id',
        'lawyer_company_id',
        'recovery_plan_id',
        'recovery_step_id',
        'default_invoice_template_id',
        'default_credit_note_template_id',
        'billing_log_id',
        'task_type_id',
        // probably missing: template_id
    ];
    var setPageLimit = function (page_limit) { return function () {
        applyFilters(__assign(__assign({}, filters), { page_limit: page_limit }));
    }; };
    var filtersToShow = useMemo(function () {
        if (availableFilters.length === 0)
            return [];
        // Some keys belong in pairs and are displayed together, this object keeps track of them so
        // they are not displayed twice when the second key of the pair is iterated over
        var double = {};
        var tempFiltersToShow = [];
        Object.keys(filters).forEach(function (key) {
            if (filters[key] == null || ['sort_order', 'sort_by', 'page'].includes(key))
                return;
            if (key.includes('_start') || key.includes('_end')) {
                var fieldName_1 = key.substring(0, key.lastIndexOf('_'));
                if (double[fieldName_1])
                    return;
                double[fieldName_1] = true;
                tempFiltersToShow.push({
                    key: key,
                    pairedKey: key.includes('_start') ? "".concat(fieldName_1, "_end") : "".concat(fieldName_1, "_start"),
                    name: availableFilters.find(function (filter) { return filter.name === fieldName_1; }).name_translated,
                    text: "".concat(filters["".concat(fieldName_1, "_start")] && filters["".concat(fieldName_1, "_start")] !== 'null' // <- if this is true, change it on the backend
                        ? formattedDate(filters["".concat(fieldName_1, "_start")], currentLang)
                        : '∞', " - ").concat(filters["".concat(fieldName_1, "_end")] && filters["".concat(fieldName_1, "_end")] !== 'null'
                        ? formattedDate(filters["".concat(fieldName_1, "_end")], currentLang)
                        : '∞'),
                });
            }
            else if (key.includes('_handler')) {
                if (filters[key] === 'null' || filters[key] === 'not_null') {
                    var fieldName_2 = key.substring(0, key.lastIndexOf('_'));
                    var filter = availableFilters.find(function (_filter) { return _filter.name === fieldName_2; });
                    tempFiltersToShow.push({
                        key: key,
                        name: filter.name_translated,
                        text: "-".concat(t(filters[key] === 'null' ? 'EMPTY' : 'FILL')),
                    });
                }
            }
            else if (key.includes('min_') || key.includes('max_')) {
                var fieldName_3 = key.substring(4);
                if (double[fieldName_3])
                    return;
                double[fieldName_3] = true;
                tempFiltersToShow.push({
                    key: key,
                    pairedKey: key.includes('min_') ? "max_".concat(fieldName_3) : "min_".concat(fieldName_3),
                    name: availableFilters.find(function (filter) { return filter.name === fieldName_3; }).name_translated,
                    text: "".concat(filters["min_".concat(fieldName_3)] && filters["min_".concat(fieldName_3)] !== 'null'
                        ? filters["min_".concat(fieldName_3)]
                        : '∞', " - ").concat(filters["max_".concat(fieldName_3)] && filters["max_".concat(fieldName_3)] !== 'null'
                        ? filters["max_".concat(fieldName_3)]
                        : '∞'),
                });
            }
            else if (key === 'page_limit') {
                setPageLimit(filters[key]);
            }
            else if (key === 'quick_search') {
                tempFiltersToShow.push({
                    key: key,
                    name: t(i18nKeys.FORM.QUICK_SEARCH.PLACEHOLDER),
                    text: filters[key],
                });
            }
            else if (!key.includes('_operator')) {
                var filter = availableFilters.find(function (_filter) { return _filter.name === key; });
                if (isNullish(filter))
                    return;
                if (filter.filter_type === 'boolean') {
                    tempFiltersToShow.push({
                        key: key,
                        name: filter.name_translated,
                        text: t(filters[key] === 'true' ? i18nKeys.YES : i18nKeys.NO),
                    });
                }
                else if (filter.filter_type === 'number' && filters.key != null) {
                    tempFiltersToShow.push({
                        key: key,
                        name: filter.name_translated,
                        text: "".concat(filters["".concat(key, "_operator")], " ").concat(filters[key]),
                    });
                }
                else if (filter.filter_type === 'select' || filter.filter_type === 'string') {
                    var text = '';
                    if (key === 'status') {
                        text = (Array.isArray(filters[key]) ? filters[key] : filters[key].split(','))
                            .map(function (status) { var _a; return (_a = constants.statuses.find(function (el) { return el.value === status; })) === null || _a === void 0 ? void 0 : _a.description; })
                            .join(', ');
                    }
                    else {
                        text =
                            apiFilters.includes(key) && !Array.isArray(filters[key])
                                ? filters[key].split(',')
                                : filters[key];
                    }
                    tempFiltersToShow.push({ key: key, name: filter.name_translated, text: text });
                }
            }
        });
        return tempFiltersToShow;
    }, [filters, apiFilters, availableFilters, constants, currentLang, setPageLimit, t]);
    if (currentView == null)
        return null;
    // ================= HANDLERS ====================
    var handleClickCheckbox = function (e, itemId) {
        e.stopPropagation();
        if (selectedIds.includes(itemId)) {
            // @ts-ignore
            setSelectedIds(function (ids) { return ids.filter(function (id) { return id !== itemId; }); });
            // @ts-ignore
        }
        else
            setSelectedIds(function (ids) { return __spreadArray(__spreadArray([], __read(ids), false), [itemId], false); });
    };
    var setColumn = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.LARGE,
            title: t(i18nKeys.CONFIRMATION),
            children: (React.createElement(ChooseColumnModal, { currentView: currentView, tableName: tableName, callbackAction: callbackAction })),
        });
    };
    var setFiltersName = function (key, values) {
        var _a;
        setState({
            filtersName: __assign(__assign({}, filtersName), (_a = {}, _a[key] = values, _a)),
        });
    };
    var emptyQuickSearchField = function () {
        var input = document.querySelector("[data-quick-search='1']");
        if (input) {
            input.value = '';
        }
    };
    var resetFilters = function () {
        emptyQuickSearchField();
        filterObject.reset();
        applyFilters({});
    };
    var updateUrl = function (_filters) {
        history.push({
            pathname: location.pathname,
            search: qs.stringify(_filters, { arrayFormat: 'comma' }),
            hash: location.hash,
        });
    };
    var loadData = function () {
        if (loadDataGetView) {
            appActions.getView({
                // @ts-ignore redux saga action incorrect type
                id: currentView.id,
                data: queryParamParser(qs.parse(location.search)),
                callback: function (data) {
                    callbackAction(data, 'fulfilled');
                },
            });
        }
    };
    var applyFilters = function (values) {
        var newFilters = handleFilters ? handleFilters(values, filters) : values;
        if (!newFilters)
            return;
        newFilters = omitBy(newFilters, anyPass([isNullish, isEmpty]));
        newFilters.page = '1';
        newFilters.page_limit = values.page_limit || filters.page_limit;
        updateUrl(newFilters);
        setState({
            filters: newFilters,
        });
    };
    var setPage = function (page) {
        updateUrl(__assign(__assign({}, filters), { page: Number(page.selected) + 1 }));
    };
    var onClickSelectAllOnPage = function () {
        setAreAllItemsAcrossPagesSelected(false);
        var newSelection = areAllItemsOnThisPageSelected ? [] : items.map(function (item) { return item.id; });
        setSelectedIds(newSelection);
    };
    var setOrder = function (field) { return function () {
        if (field === 'actions')
            return;
        if (filters.sort_by !== field) {
            applyFilters(__assign(__assign({}, filters), { sort_by: field, sort_order: 'asc' }));
        }
        else {
            applyFilters(__assign(__assign({}, filters), { sort_order: filters.sort_order === 'asc' ? 'desc' : 'asc' }));
        }
    }; };
    var filtersNumber = 0;
    // eslint-disable-next-line
    for (var key in filters) {
        if (key !== 'page' &&
            key !== 'page_limit' &&
            !key.includes('sort_') &&
            !key.includes('_operator') &&
            !key.includes('_end') &&
            !key.includes('_start') &&
            filters[key])
            filtersNumber++;
    }
    // Egregious piece of software: This object is passed down to the child component responsible for the filters state
    // Upon receiving it, that component populates the empty reset method below with a callback to its own state,
    // which this component can then call.
    // TODO: refactor, extract filter state somewhere sane.
    var filterObject = {
        reset: function () { },
    };
    var openFilters = function () {
        sideMenuShow({
            unmount: true,
            content: (React.createElement(FiltersSideMenu, { onRemoveFilter: resetFilters },
                React.createElement(TableFilters, { filtersAvailable: availableFilters, filterObject: filterObject, onSubmit: applyFilters, initialValues: filters, tableName: tableName, initialFiltersName: filtersName, setFiltersName: setFiltersName }))),
        });
    };
    var saveFilters = function () {
        if (viewsByResource[tableName][0].id === currentView.id) {
            showDialog({
                id: DialogShowId.CUSTOM,
                size: DialogShowSize.SMALL,
                title: t(i18nKeys.ADD_VIEW),
                children: (React.createElement(AddNewView, { onSubmit: function (_a) {
                        var name = _a.name;
                        appActions.updateViews({
                            data: {
                                views_attributes: [
                                    {
                                        name: name,
                                        view_type: tableName,
                                        filter_fields_attributes: setFiltersView(),
                                    },
                                ],
                            },
                            callback: function () {
                                dialogHide(DialogShowId.CUSTOM);
                                appActions.getViews({
                                    data: {
                                        view_type: tableName,
                                    },
                                });
                            },
                        });
                    } })),
            });
        }
        else {
            appActions.updateViews({
                data: {
                    views_attributes: [
                        {
                            id: currentView.id,
                            filter_fields_attributes: setFiltersView(),
                        },
                    ],
                },
                callback: function () {
                    appActions.getViews({
                        data: {
                            view_type: tableName,
                        },
                    });
                },
            });
        }
    };
    var setFiltersView = function () {
        var currentFilters = Object.entries(filters);
        var operators = {};
        var indicesToRemove = [];
        currentFilters.forEach(function (_a, i) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var index = key.lastIndexOf('_');
            if (key.includes('_operator')) {
                operators[key.substring(0, index)] = value;
                indicesToRemove.push(i);
            }
            else if (key.includes('_handler')) {
                var fieldName = key.substring(0, index);
                currentFilters.push([fieldName, '']);
                operators[fieldName] = value;
                indicesToRemove.push(i);
            }
            else if (key.includes('_start')) {
                var fieldName_4 = key.substring(0, index);
                currentFilters.push([
                    fieldName_4,
                    [filters["".concat(fieldName_4, "_start")] || 'null', filters["".concat(fieldName_4, "_end")] || 'null'],
                ]);
                indicesToRemove.push(i);
                var endIndex = currentFilters.findIndex(function (_a) {
                    var _b = __read(_a, 1), name = _b[0];
                    return name === "".concat(fieldName_4, "_end");
                });
                if (endIndex !== -1)
                    indicesToRemove.push(endIndex);
            }
            else if (key.includes('_end') &&
                !filters["".concat(key.substring(0, key.indexOf('_end')), "_start")]) {
                var fieldName_5 = key.substring(0, index);
                currentFilters.push([
                    fieldName_5,
                    [filters["".concat(fieldName_5, "_start")] || 'null', filters["".concat(fieldName_5, "_end")] || 'null'],
                ]);
                indicesToRemove.push(i);
                var pairedFieldIndex = currentFilters.findIndex(function (_a) {
                    var _b = __read(_a, 1), name = _b[0];
                    return name === fieldName_5;
                });
                if (pairedFieldIndex !== -1)
                    indicesToRemove.push(pairedFieldIndex);
            }
            else if (key.indexOf('min_') + key.indexOf('max_') !== -2) {
                var fieldName = key.substring(4);
                currentFilters.push([
                    fieldName,
                    [filters["min_".concat(fieldName)] || 'null', filters["max_".concat(fieldName)] || 'null'],
                ]);
                indicesToRemove.push(i);
            }
        });
        currentFilters = currentFilters.filter(function (i, index) { return !indicesToRemove.includes(index); });
        var filterFieldAttributes = currentFilters
            .filter(function (_a) {
            var _b = __read(_a, 1), name = _b[0];
            return !['sort_order', 'sort_by', 'page', 'page_limit'].includes(name) &&
                name.indexOf('_operator') === -1;
        })
            .map(function (_a) {
            var _b = __read(_a, 2), name = _b[0], value = _b[1];
            return ({
                name: name,
                applied_filter: { operator: operators[name], value: value },
            });
        });
        currentView.filter_fields
            .filter(function (filter_field) {
            return !filterFieldAttributes.some(function (newFilterField) { return newFilterField.name === filter_field.name; });
        })
            .forEach(function (filterField) {
            filterFieldAttributes.push(__assign(__assign({}, filterField), { _destroy: true }));
        });
        return filterFieldAttributes;
    };
    var removeFilter = function (key, extraKey) { return function (e) {
        if (key === 'quick_search') {
            emptyQuickSearchField();
        }
        e.stopPropagation();
        var newFilters = clone(filters);
        newFilters[key] = undefined;
        if (extraKey)
            newFilters[extraKey] = undefined;
        applyFilters(newFilters);
    }; };
    var mediumColumns = ['total_tvac', 'remaining_balance'];
    var largeColumns = [
        'status',
        'reference',
        'full_name',
        'debtors__full_name',
        'recovery_step_id',
    ];
    var sizeColumn = function (column_name) {
        if (largeColumns.includes(column_name)) {
            return 400;
        }
        if (mediumColumns.includes(column_name)) {
            return 100;
        }
        return undefined;
    };
    var submit = function (quick_search) {
        var newFilters = __assign(__assign({}, filters), { quick_search: quick_search });
        updateUrl(newFilters);
        setState({
            filters: newFilters,
        });
    };
    return (React.createElement("div", { className: styles('CustomTable', 'views', noShadow && 'no-shadow', noMargin && 'no-margin', className) },
        title && (React.createElement(CustomTableTitle, { title: title, actions: actions, tableName: tableName, submit: submit, invalidateViews: loadOrRefreshViews })),
        React.createElement("div", { className: styles('filters') },
            !isMobile && (React.createElement("div", { className: styles('quick-search') },
                React.createElement(Input, { noMargin: true, quickSearch: true, type: "text", placeholder: t(i18nKeys.FORM.QUICK_SEARCH.PLACEHOLDER), onValueChanged: submit }),
                React.createElement(Tooltip, { icon: IconName.INFO, size: "14px", text: t(i18nKeys.FORM.QUICK_SEARCH.TOOLTIP[quickSearchKey(tableName)]) }))),
            React.createElement("div", { className: styles('filters-actions') },
                React.createElement(Button, { small: true, noMargin: true, noShadow: true, label: t(i18nKeys.SAVE_FILTERS), color: ButtonColor.GREY, onClick: saveFilters }),
                filtersNumber > 0 && (React.createElement(Button, { className: styles('remove-filter', 'filter'), noMargin: true, small: true, noShadow: true, iconSize: "16px", color: ButtonColor.WHITE, onClick: resetFilters },
                    React.createElement(Icon, { name: IconName.FILTER }),
                    React.createElement(Icon, { name: IconName.SMALL_REMOVE }))),
                React.createElement(Button, { noMargin: true, small: true, iconSize: "16px", noShadow: true, color: ButtonColor.WHITE, iconLeft: IconName.FILTER, onClick: openFilters },
                    filtersNumber > 0 && (React.createElement("div", { className: styles('text-circle', 'absolute', 'border', 'badge') }, filtersNumber)),
                    t(i18nKeys.FILTER)))),
        React.createElement("div", { className: styles('filters-container') }, filtersToShow.map(function (_a) {
            var name = _a.name, text = _a.text, key = _a.key, pairedKey = _a.pairedKey;
            return (React.createElement("div", { key: key, className: styles('filter-item'), onClick: openFilters },
                React.createElement("div", { className: styles('filter-name') },
                    name,
                    ":"),
                React.createElement("div", null, Array.isArray(text)
                    ? text.map(function (el) { return (filtersName[key] ? filtersName[key][el] : el); }).join(', ')
                    : text),
                React.createElement(Icon, { name: IconName.SMALL_REMOVE, onClick: removeFilter(key, pairedKey) })));
        })),
        React.createElement("div", { className: styles('listing-wrapper') },
            React.createElement("div", { className: styles('card-style', 'card') },
                React.createElement("div", { className: styles('listing', noShadow && 'no-shadow'), style: { maxHeight: maxHeight } }, currentView && (React.createElement("table", null,
                    React.createElement("thead", { style: { position: 'sticky', zIndex: 1 } },
                        React.createElement("tr", null,
                            React.createElement("th", { className: styles('box-wrapper', noCheckbox && 'small') },
                                React.createElement("div", { className: styles('sortable-head', 'small') }, !noCheckbox && (React.createElement("div", { onClick: onClickSelectAllOnPage, className: styles('box', 'checkbox', areAllItemsOnThisPageSelected && 'checked') }))),
                                React.createElement("div", { className: styles('custom-border') })),
                            currentView.table_columns.map(function (item, index) { return (React.createElement("th", { key: item.title || index, className: styles(index === currentView.table_columns.length - 1 && 'last', index === 0 && 'first') },
                                React.createElement("div", { style: { width: sizeColumn(item.name) }, className: styles("".concat(item.name === 'actions' ? 'not-' : '', "sortable-head")), onClick: setOrder(item.name), title: item.name_translated },
                                    item.name_translated,
                                    filters.sort_by === item.name && (React.createElement(Icon, { name: IconName.TAILDOWN, size: "15px", className: styles('arrow', filters.sort_order === 'asc' && 'inverted') }))),
                                React.createElement("div", { className: styles('custom-border') }))); }),
                            React.createElement("th", { key: "edit_columns", style: { width: 40 }, className: styles('last') },
                                React.createElement("div", { className: styles('not-sortable-head') },
                                    React.createElement(Icon, { name: IconName.SETTINGS_GEAR, onClick: setColumn, className: "edit-column" })),
                                React.createElement("div", { className: styles('custom-border') })))),
                    React.createElement("tbody", null,
                        areAllItemsOnThisPageSelected &&
                            ((_c = pagination === null || pagination === void 0 ? void 0 : pagination.total_objects) !== null && _c !== void 0 ? _c : 0) >
                                ((_d = filters.page_limit) !== null && _d !== void 0 ? _d : profile.preferences.itemsPerPage) && (React.createElement("tr", null,
                            React.createElement("td", { className: styles('select-all'), colSpan: 100 },
                                React.createElement("div", null,
                                    React.createElement("div", null,
                                        t((areAllItemsAcrossPagesSelected
                                            ? pagination.total_objects
                                            : items.length) === 1
                                            ? i18nKeys.SELECTED_ELEMENT
                                            : i18nKeys.SELECTED_ELEMENTS, {
                                            count: areAllItemsAcrossPagesSelected
                                                ? pagination.total_objects
                                                : items.length,
                                        }),
                                        React.createElement("span", { onClick: function () { return setAreAllItemsAcrossPagesSelected(function (value) { return !value; }); } }, t((areAllItemsAcrossPagesSelected
                                            ? items.length
                                            : pagination.total_objects) === 1
                                            ? i18nKeys.SELECT_ELEMENT
                                            : i18nKeys.SELECT_ELEMENTS, {
                                            count: areAllItemsAcrossPagesSelected
                                                ? items.length
                                                : pagination.total_objects,
                                        }))))))),
                        (function () {
                            if (isLoading || isFetching) {
                                return Array(currentView.table_columns.length)
                                    .fill(0)
                                    .map(function (_, rowIndex) {
                                    return (React.createElement("tr", { key: rowIndex },
                                        React.createElement("td", null),
                                        Array(currentView.table_columns.length)
                                            .fill(0)
                                            .map(function (__, colIndex) { return (React.createElement("td", { key: colIndex },
                                            React.createElement(Skeleton, { h: "25px", my: 5, radius: "md" }))); })));
                                });
                            }
                            if (isEmpty(items)) {
                                return (React.createElement("tr", { style: { position: 'relative', height: '65px' } },
                                    React.createElement("div", { className: styles('no-result'), style: { position: 'absolute', width: '100%' } }, filtersNumber > 0
                                        ? t(i18nKeys.NO_RESULT_FOR_THIS_SEARCH)
                                        : noResultMessage !== null && noResultMessage !== void 0 ? noResultMessage : t(i18nKeys.NO_RESULT))));
                            }
                            return items.map(function (item, key) {
                                var _a;
                                return (React.createElement(TableItem, { key: key, onClickRow: onClickRow(item), actionsCol: actionsCol, item: item, columns: currentView.table_columns, noCheckbox: noCheckbox, groupedInvoices: (_a = item === null || item === void 0 ? void 0 : item.attributes) === null || _a === void 0 ? void 0 : _a.invoices_references, isRemindersView: isRemindersView, isSelected: selectedIds.includes(item.id), onClickCheckbox: function (e) {
                                        handleClickCheckbox(e, item.id);
                                    } }));
                            });
                        })())))),
                !noFooter && (React.createElement(TableFooter, { currentPage: currentPage, pagination: pagination, currentPageLimit: filters.page_limit, setPage: setPage, itemsLength: items.length, setPageLimit: setPageLimit })))),
        loadMore && pagination && pagination.current_page < pagination.last_page && (React.createElement("div", { className: styles('buttons') },
            React.createElement(Button, { noMargin: true, color: ButtonColor.BLUE, label: t(i18nKeys.SHARED.CUSTOM_TABLE.LOAD_MORE), onClick: function () { return loadMore(pagination); } })))));
}
