import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { useLegacyAxiosInstance } from './utils';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["assign"] = "assign";
    ActionTypes["process"] = "process";
    ActionTypes["unprocess"] = "unprocess";
    ActionTypes["read"] = "read";
    ActionTypes["unread"] = "unread";
    ActionTypes["delete"] = "delete";
})(ActionTypes || (ActionTypes = {}));
var BatchUpdateExternalMailResponseSchema = z.object({
    id: z.number(),
    key: z.string(),
    message: z.string(),
    error_message: z.string().optional(),
});
var BatchUpdateExternalMailSchema = z.object({
    action_type: z.nativeEnum(ActionTypes),
    external_mail_ids: z.array(z.string()),
    debtor_id: z.string().optional(),
});
export function batchUpdateExternalMails(axiosInstance, data) {
    return __awaiter(this, void 0, void 0, function () {
        var instance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [2 /*return*/, instance.post('external_mails/batch', BatchUpdateExternalMailSchema.parse(data))];
            }
        });
    });
}
export function useBatchUpdateExternalMails() {
    var client = useQueryClient();
    var axiosInstance = useLegacyAxiosInstance();
    var mutation = useMutation({
        mutationFn: function (data) { return batchUpdateExternalMails(axiosInstance, data); },
        onSuccess: function () {
            client.invalidateQueries({ queryKey: ['externalMails'] });
        },
    });
    return addActionNameToMutationResult('batchUpdateExternalMails', mutation);
}
