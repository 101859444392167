import { __read } from "tslib";
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { clone } from 'remeda';
import CustomTableViews from 'shared/components/CustomTable/CustomTableViews';
import ExportInvoices from 'shared/components/ExportInvoices';
import { Button, ButtonColor } from 'shared/io';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import BatchActions from './BatchActions';
import styleIdentifiers from './InvoicesList.scss';
var styles = classNames.bind(styleIdentifiers);
export default function InvoicesList() {
    var _a;
    var t = useTranslation().t;
    var history = useHistory();
    var company = useSelector(function (state) { return state.account.company.data; });
    var invoice = useSelector(function (state) { return state.invoice; });
    var invoicesData = invoice.list;
    var _b = __read(useState([]), 2), selectedInvoices = _b[0], setSelectedInvoices = _b[1];
    var _c = __read(useState(false), 2), areAllItemsAcrossPagesSelected = _c[0], setAreAllItemsAcrossPagesSelected = _c[1];
    var handleFilters = function (filters) {
        var newFilters = clone(filters);
        // need to be linked
        if ((newFilters.total_tvac_operator && !newFilters.total_tvac) ||
            (!newFilters.total_tvac_operator && newFilters.total_tvac))
            return false;
        // need to be linked
        if ((newFilters.remaining_balance_operator && !newFilters.remaining_balance) ||
            (!newFilters.remaining_balance_operator && newFilters.remaining_balance))
            return false;
        return newFilters;
    };
    var exportInvoices = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.EXPORT),
            children: React.createElement(ExportInvoices, null),
        });
    };
    var showInvoice = function (item) { return function () {
        history.push("/invoices/".concat(item.id));
    }; };
    var openBatchActionsModal = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.FORM.ACTIONS),
            children: (React.createElement(BatchActions, { selectedInvoices: selectedInvoices, isSelectedAll: areAllItemsAcrossPagesSelected })),
        });
    };
    var newInvoice = function () {
        invoiceActions.detailReset();
        history.push('/invoices/create');
    };
    var renderButton = function () {
        if (selectedInvoices.length > 0) {
            return (React.createElement(React.Fragment, null,
                React.createElement(Button, { noMargin: true, label: t(i18nKeys.FORM.ACTIONS), onClick: openBatchActionsModal })));
        }
        return (React.createElement(React.Fragment, null,
            company.unvalidated_invoices_count > 0 && (React.createElement(Button, { noMargin: true, onClick: function () { return history.push('/invoices/to-confirm'); } },
                t(i18nKeys.IMPORT_TO_CONFIRM),
                React.createElement("div", { className: styles('text-circle', 'absolute', 'border', 'blue') }, company.unvalidated_invoices_count))),
            React.createElement(Button, { noMargin: true, label: t(i18nKeys.EXPORT), onClick: exportInvoices }),
            React.createElement(Button, { noMargin: true, label: t(i18nKeys.NEW_INVOICE), color: ButtonColor.BLUE, onClick: newInvoice })));
    };
    return (React.createElement(CustomTableViews, { title: t(i18nKeys.INVOICING.INVOICES_BOOK), callbackAction: invoiceActions.listPageRes, onClickRow: showInvoice, actions: renderButton(), isLoading: !invoicesData.loaded, pagination: (_a = invoicesData.metadata) === null || _a === void 0 ? void 0 : _a.pagination, handleFilters: handleFilters, items: invoicesData.pages || [], onChangeSelectionCallback: function (values) { return setSelectedInvoices(values); }, onChangeSelectItemsAcrossAllPagesCallback: setAreAllItemsAcrossPagesSelected, tableName: "invoices" }));
}
