import { useMemo } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useLoadNotificationsCount } from 'shared/hooks/use-load-notifications-count';
import { useGetCompany } from 'shared/utils/selectors';
import { IconAlarm, IconBell, IconChartAreaLine, IconDashboard, IconReport, IconUser, } from '@tabler/icons-react';
import { isMenu } from '../utils';
import { useBuildBankAccountMenu } from './use-build-bank-account-menu';
import { useBuildFollowupMenu } from './use-build-followup-menu';
import { useBuildInvoiceMenu } from './use-build-invoice-menu';
import { useBuildSettingMenu } from './use-build-setting-menu';
export var populateParent = function (menu, parent) {
    menu.parent = parent;
    menu.items.map(function (item) {
        if (!isMenu(item))
            return null;
        return populateParent(item, menu);
    });
    return menu;
};
export function useBuildMenuItems() {
    var t = useTranslation().t;
    var company = useGetCompany();
    var notificationsCount = useLoadNotificationsCount().notificationsCount;
    var invoiceMenu = useBuildInvoiceMenu();
    var followUpMenu = useBuildFollowupMenu();
    var bankAccountMenu = useBuildBankAccountMenu();
    var settingMenu = useBuildSettingMenu();
    return useMemo(function () {
        var _a, _b;
        var items = [
            {
                path: '/dashboard',
                label: t(i18nKeys.COMMON.DASHBOARD),
                Icon: IconDashboard,
            },
            followUpMenu,
            invoiceMenu,
            {
                path: '/analytics',
                label: 'Analytics',
                Icon: IconChartAreaLine,
                isHidden: !((_a = company === null || company === void 0 ? void 0 : company.package) === null || _a === void 0 ? void 0 : _a.can_use_analytics),
            },
            bankAccountMenu,
            {
                path: '/clients',
                label: t(i18nKeys.CLIENTS),
                Icon: IconUser,
            },
            {
                path: '/reports',
                label: t(i18nKeys.REPORTS.INDEX.TITLE),
                Icon: IconReport,
                isHidden: !((_b = company === null || company === void 0 ? void 0 : company.package) === null || _b === void 0 ? void 0 : _b.can_use_reports),
            },
            {
                path: '/activities',
                label: t(i18nKeys.NAV.ACTIVITY_BOOK),
                Icon: IconBell,
                unreadCount: notificationsCount,
            },
            settingMenu,
        ];
        var menu = {
            parent: null,
            label: 'root',
            Icon: IconAlarm,
            items: items,
        };
        return populateParent(menu, null);
    }, [company, followUpMenu, bankAccountMenu, invoiceMenu, notificationsCount, settingMenu, t]);
}
