import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';
var CeiSchema = z.record(z.coerce.string().datetime({ offset: true }), z.number().nullable());
export function loadCeiFn(axiosInstance) {
    return __awaiter(this, void 0, void 0, function () {
        var instance, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance];
                case 1:
                    instance = _a.sent();
                    return [4 /*yield*/, instance.get('/analytics/ceis')];
                case 2:
                    data = (_a.sent()).data;
                    return [2 /*return*/, CeiSchema.parse(data)];
            }
        });
    });
}
export function useLoadCei() {
    var axiosInstance = useAxiosInstance();
    var queryResult = useQuery({
        queryKey: ['cei'],
        queryFn: function () { return loadCeiFn(axiosInstance); },
    });
    return addResourceNameToQueryResult('cei', queryResult);
}
